<template>
  <div class="user-short-preview" :class="link && 'link'" @click="onClick">
    <UserPhoto :user="user" class="user-photo" />
    <h5 class="user-name">{{ user.name }}</h5>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { User } from '@/data/types';
import UserPhoto from './UserPhoto.vue';

export default defineComponent({
  components: { UserPhoto },

  props: {
    user: Object as PropType<User>,
    link: {
      type: [Boolean, String],
      default: 'creator',
    },
  },

  methods: {
    onClick() {
      if (this.link === 'creator' && this.user) {
        this.$router.push({
          name: 'creator',
          params: { creatorID: this.user.id },
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.user-short-preview {
  display: flex;
  align-items: center;
  transition: opacity .3s ease;

  &.link {
    cursor: pointer;

    &:hover {
      opacity: .75;
    }
  }

  .user-photo {
    width: 54rem;
    height: 54rem;
    margin-right: 30rem;
  }

  .user-name {
    display: inline;
    margin: 0;
  }
}
</style>
