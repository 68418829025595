import { useAPI, useAuth } from '@/modules';
import { User, UserData } from '@/data/types';

const { user } = useAuth();

export interface SearchParams {
  limit?: number;
  name?: string;
}

export const useCreatorsList = () => {
  const {
    loading,
    finished,
    data: creators,
    execute: sendLoadCreatorsRequest,
  } = useAPI<Array<User>>('/users/creators/', {
    parseResponse: (items: Array<UserData>) => items.map((data) => new User(data, user?.value as User)),
  }, false);

  const load = (search?: SearchParams) => sendLoadCreatorsRequest({ params: search })
    .then((result?: Array<User>) => {
      if (result) {
        creators.value = result;
      }
      return creators.value;
    });

  const search = (termKey: string, limit: number) => ({ term }: { term: string }) => {
    load({
      [termKey]: term,
      limit,
    });
  };

  return {
    creators,
    loading,
    finished,
    load,
    search,
  };
};
