<template>
  <div class="terms-page">
    <p class="p-mt-3">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
      labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
      laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur.
      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
    <p class="p-mt-3">
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
      aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
      Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
      eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
      consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam
      quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam,
      nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse
      quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?

      At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque
      corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
      culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est
      et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus
      id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem
      quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et
      molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus
      maiores alias consequatur aut perferendis doloribus asperiores repellat.
    </p>
    <p class="p-mt-3">
      Sed ullamcorper aliquet maximus. Curabitur cursus ornare purus ut vehicula. Class aptent taciti sociosqu ad
      litora torquent per conubia nostra, per inceptos himenaeos. Nullam pulvinar pretium fringilla. Sed a tellus
      lacinia, commodo lorem nec, luctus ipsum. Integer scelerisque efficitur diam quis pellentesque. Nunc in neque
      gravida, vehicula sapien vitae, dignissim velit. Nam ac libero condimentum, pharetra nulla in, semper justo.
      Suspendisse vitae arcu aliquet, efficitur metus quis, auctor odio. Vestibulum elit felis, eleifend eu lorem a,
      efficitur facilisis felis. Nullam non placerat felis. Ut eu gravida nisi.
    </p>
    <p class="p-mt-3">
      Fusce molestie nisl accumsan urna tempus condimentum. Praesent convallis dolor at ante egestas, at vehicula elit
      ornare. Fusce blandit massa ac dui vestibulum congue. Suspendisse tincidunt viverra facilisis. Suspendisse
      nec volutpat massa. Curabitur sit amet nulla a dui dapibus hendrerit eget nec augue. Suspendisse sed porta
      nunc, eget vestibulum tortor. Ut ac dolor eu nibh euismod tempus eu a tellus. Mauris ex orci, consectetur quis
      vestibulum eu, luctus a felis. Phasellus semper neque at nunc lacinia laoreet. Vestibulum in lectus varius justo
      interdum tempus.
    </p>
    <p class="p-mt-3">
      Nam posuere, neque a dapibus porta, enim quam posuere turpis, nec tincidunt nisl risus nec nisl. Mauris malesuada
      dictum libero eu varius. Maecenas ligula erat, varius at erat vitae, condimentum lobortis ante. Cras in ipsum
      turpis. Nullam molestie ultricies enim vitae auctor. Curabitur porttitor, sapien at finibus varius, erat odio
      euismod sem, in tincidunt nisi mauris id nisi. Nam ut odio dictum, sagittis nunc nec, porta urna.
    </p>
    <p class="p-mt-3">
      Curabitur sit amet neque diam. Integer sed ipsum quis augue eleifend commodo. Duis facilisis sit amet massa porta
      laoreet. Cras quis nunc quis sem convallis ultrices. Vestibulum dictum eu felis id imperdiet. Pellentesque
      habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam dapibus hendrerit velit
      ac varius.
    </p>
  </div>
</template>
