<template>
  <div class="layout-topbar">
    <button class="p-link layout-menu-button" @click="onMenuToggle">
      <span class="pi pi-bars"></span>
    </button>
    <div class="layout-topbar-icons">
      <!-- <span class="layout-topbar-search">
        <InputText type="text" placeholder="Search" />
        <span class="layout-topbar-search-icon pi pi-search"></span>
      </span> -->
      <button class="p-link">
        <span class="layout-topbar-item-text">Events</span>
        <span class="layout-topbar-icon pi pi-globe"></span>
        <span class="layout-topbar-badge" v-if="!loadingNotifications && notifications?.length">{{ notifications.length }}</span>
      </button>

      <button @click="toggleUserMenu" class="p-link" aria-haspopup="true" aria-controls="menu">
        <span class="layout-topbar-item-text">Events</span>
        <span class="layout-topbar-icon pi pi-user"></span>
      </button>

<!--      TODO: check OverlayPanel for notifications!-->
<!--      TODO: check how to use badge directive - might be very helpful here!-->

      <Menu id="menu" ref="menuRef" :model="userMenuItems" :popup="true" :appendTo="'body'" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useNotifications } from '@/modules/notifications-module';
import Menu from 'primevue/menu';
import { useAuth } from '@/modules/auth-module';

export default defineComponent({
  components: { Menu },
  setup() {
    const { user } = useAuth();
    const { loading: loadingNotifications, notifications } = useNotifications();

    const menuRef = ref();
    const toggleUserMenu = (event: Event) => {
      if (menuRef.value) {
        // eslint-disable-next-line no-unused-expressions
        menuRef.value?.toggle(event);
      }
    };

    const userIsBrand = user?.value?.isBrandOwner();

    const userMenuItems = [
      {
        label: 'My Profile',
        icon: 'pi pi-fw pi-user',
        to: { name: 'my-profile' },
      },
      {
        label: 'Billing',
        icon: 'pi pi-fw pi-dollar',
        visible: userIsBrand,
      },
      {
        label: 'Payment Details',
        icon: 'pi pi-fw pi-money-bill',
        visible: userIsBrand,
      },
      // add logout at the end
      { separator: true },
      {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        to: { name: 'logout' },
      },
    ];

    return {
      loadingNotifications,
      notifications,
      toggleUserMenu,
      menuRef,
      userMenuItems,
    };
  },
});
</script>

<style lang="scss" scoped>
.layout-topbar {
  position: fixed;
  height: 50px;
  padding: 1em 2em 0 2em;
  color: #ffffff;
  z-index: 999;
  right: 0;
  @include clearfix();
  // @include linear-gradient-left($topbarLeftBgColor, $topbarRightBgColor);
  // @include linear-gradient($menuBgColorFirst, $menuBgColorLast);
  // background-color: #C4C4C4;
  //background-color: $menuBgColorLast;
  // @include linear-gradient($menuBgColorFirst, $menuBgColorLast);
  transition: left $transitionDuration;

  .layout-topbar-icons {
    float: right;
    display: block;
    animation-duration: 0.5s;

    button {
      position: relative;
      color: $topbarItemColor;
      margin-left: 20px;
      display: inline-block;
      text-decoration: none;
      transition: color $transitionDuration;

      &:hover {
        color: $topbarItemHoverColor;
      }

      &:focus {
        @include focused();
      }

      span {
        &.layout-topbar-icon {
          font-size: 1.5em;
        }

        &.layout-topbar-item-text {
          font-size: 20px;
          display: none;
        }

        &.layout-topbar-badge {
          position: absolute;
          font-size: 10px;
          right: -5px;
          top: -5px;
          width: 16px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          color: $topbarItemBadgeColor;
          background-color: $topbarItemBadgeBgColor;
          border-radius: 50%;
        }
      }
    }

    .layout-topbar-search {
      padding: 0;
      position: relative;
      display: inline-block;
      top: -4px;

      input {
        display: inline-block;
        border: 0 none;
        font-size: $fontSize;
        background: transparent;
        border-bottom: 2px solid $topbarSearchInputBorderBottomColor;
        outline: 0 none;
        box-shadow: none;
        color: $topbarSearchInputColor;
        width: 100px;
        padding: 1px 20px 1px 1px;
        margin: 0;
        border-radius: 2px;

        &::-webkit-input-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          transition: opacity $transitionDuration;
        }
        &:-moz-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          transition: opacity $transitionDuration;
        }
        &::-moz-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          transition: opacity $transitionDuration;
        }
        &:-ms-input-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          transition: opacity $transitionDuration;
        }
      }

      .layout-topbar-search-icon {
        font-size: 18px;
        position: absolute;
        top: -1px;
        right: 0;
      }

      &:hover {
        input {
          border-bottom-color: $topbarItemHoverColor;
          &::-webkit-input-placeholder {
            opacity: 1;
          }
          &:-moz-placeholder {
            opacity: 1;
          }
          &::-moz-placeholder {
            opacity: 1;
          }
          &:-ms-input-placeholder {
            opacity: 1;
          }
        }

        .layout-topbar-search-icon {
          color: $topbarItemHoverColor;
        }
      }
    }
  }

  .layout-menu-button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: $topbarItemColor;
    transition: color $transitionDuration;

    span {
      font-size: 1.5em;
    }

    &:hover {
      color: $topbarItemHoverColor;
    }
  }

  button {
    cursor: pointer;
  }
}
</style>
