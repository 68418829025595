
import { defineComponent, PropType } from 'vue';
import { ContentItem, Perspective } from '@/data/types';
import EmptyList from '@/pages/commons/list/EmptyList.vue';
import LicenseRequestContentItem from './LicenseRequestContentItem.vue';

export default defineComponent({
  components: { LicenseRequestContentItem, EmptyList },

  props: {
    items: {
      type: Array as PropType<Array<ContentItem>>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
  },
});
