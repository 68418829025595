
import { computed, defineComponent, PropType, ref } from 'vue';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import { CampaignData } from '@/data/types';
import { CampaignStep, getStep, useCampaignStep } from '@/pages/campaigns/create/modules/campaign-step-module';
import Info from '@/components/Info.vue';

export default defineComponent({
  name: 'CampaignBriefingStep',

  components: {
    Info,
    Button,
    Dropdown,
    Textarea,
    InputText,
    InputNumber,
  },

  props: {
    campaignID: String,
    campaign: Object as PropType<CampaignData>,
    edit: Boolean, // TODO: enum
  },

  setup(props) {
    const campaign = computed<CampaignData | undefined>(() => props.campaign);

    const brief = ref(campaign?.value?.brief || '');
    const contentType = ref(campaign?.value?.contentType || '');
    const reworks = ref(campaign?.value?.reworksCount || 0);
    const doModel = ref('');
    const dontModel = ref('');
    const dos = ref<Array<string>>(campaign?.value?.dos || []);
    const donts = ref<Array<string>>(campaign?.value?.donts || []);

    const contentTypeOptions = [
      { name: 'Video', value: 'video' },
      { name: 'Still', value: 'still' },
      { name: 'Video + Still', value: 'video+still' },
    ];

    const addDo = () => {
      if (doModel.value) {
        dos.value.push(doModel.value); // @ts
        doModel.value = '';
      }
    };
    const removeDo = (item: string) => {
      dos.value.splice(dos.value.indexOf(item), 1);
    };

    const addDont = () => {
      if (dontModel.value) {
        donts.value.push(dontModel.value);
        dontModel.value = '';
      }
    };
    const removeDont = (item: string) => {
      donts.value.splice(donts.value.indexOf(item), 1);
    };

    const step = getStep('edit-campaign--briefing', props.campaignID) as CampaignStep;
    const controller = useCampaignStep(
      step,
      {
        brief,
        contentType,
      },
      props.campaignID,
    );
    controller.setSerializer(() => ({
      contentType: contentType.value,
      brief: brief.value,
      reworksCount: reworks.value,
      dos: dos.value,
      donts: donts.value,
    }));

    return {
      brief,
      contentType,
      contentTypeOptions,
      reworks,

      dos,
      doModel,
      addDo,
      removeDo,

      donts,
      dontModel,
      addDont,
      removeDont,

      // step control
      step,
      controller,
      ...controller.validation,
    };
  },
});
