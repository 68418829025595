<template>
  <div class="campaign-short-preview" @click="$router.push({ name: 'campaign', params: { campaignID: campaign.id } })">
    <CampaignPhoto :campaign="campaign" class="campaign-photo" />
    <h5 class="campaign-name">{{ campaign.name }}</h5>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Campaign, CampaignData } from '@/data/types';
import CampaignPhoto from '../view/CampaignPhoto.vue'; // TODO: move to commons

export default defineComponent({
  components: { CampaignPhoto },

  props: {
    campaign: Object as PropType<Campaign | CampaignData>,
  },
});
</script>

<style lang="scss" scoped>
.campaign-short-preview {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  transition: opacity .3s ease;

  .campaign-photo {
    width: 54rem;
    height: 54rem;
    margin-right: 30rem;
    flex-shrink: 0;
  }

  .campaign-name {
    display: inline;
    margin: 0;
  }

  &:hover {
    opacity: .75;
  }
}
</style>
