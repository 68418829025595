import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized } from 'vue-router';
import { useAuth } from '@/modules';
import { UserType } from '@/data/types';

import Dashboard from '../pages/Dashboard.vue';
import Empty from '../pages/Empty.vue';
import PageLayout from '../pages/layout/PageLayout.vue';
import LandingPage from '../pages/LandingPage.vue';
import WrapperPage from '../pages/utility/WrapperPage.vue';
import { AppRouteProps, Perspective } from '../data/types/page-types';

const getRouteProps = (staticProps: Record<string, unknown> = {}) => (route: RouteLocationNormalized): AppRouteProps => {
  const { user } = useAuth();
  const viewPerspective = (user?.value?.type === UserType.BrandOwner) ? Perspective.BRAND : Perspective.CREATOR;

  return {
    ...route.params,
    ...staticProps,
    viewPerspective,
  };
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: LandingPage,
    name: 'landing',
  },
  {
    path: '/app',
    name: 'app',
    component: PageLayout,
    props: getRouteProps(),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        props: getRouteProps(),
      },

      /// CONTENT
      {
        path: 'my-content-items/',
        component: WrapperPage,
        children: [
          {
            path: '',
            name: 'my-content-items',
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/content/ContentItems.vue'),
            props: getRouteProps({ private: true }),
          },
          {
            path: 'license-requests',
            name: 'license-requests',
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/content/license-requests/LicenseRequests.vue'),
            props: getRouteProps({ private: true }),
          },
          {
            path: 'licensed-items',
            name: 'licensed-items',
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/content/licensed-items/LicensedItems.vue'),
            props: getRouteProps({ private: true }),
          },
          {
            path: ':itemID',
            name: 'content-item',
            props: true,
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/content/item/ContentItem.vue'),
          },
        ],
      },
      {
        path: 'content-items/',
        component: WrapperPage,
        children: [
          {
            path: '',
            name: 'content-items',
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/content/ContentItems.vue'),
            props: getRouteProps(),
          },
          {
            path: ':itemID',
            name: 'content-item',
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/content/item/ContentItem.vue'),
            props: getRouteProps(),
          },
        ],
      },

      /// CREATORS
      {
        path: 'creators/',
        component: WrapperPage,
        children: [
          {
            path: '',
            name: 'creators',
            component: () => import(/* webpackChunkName: "creators" */ '../pages/creators/Creators.vue'),
            props: getRouteProps(),
          },
          {
            path: ':creatorID',
            name: 'creator',
            component: () => import(/* webpackChunkName: "creators" */ '../pages/creators/profile/Creator.vue'),
            props: getRouteProps(),
            children: [
              {
                path: '',
                name: 'creator-profile',
                component: () => import(/* webpackChunkName: "creators" */ '../pages/creators/profile/tabs/CreatorInformationTab.vue'),
                props: getRouteProps(),
              },
              {
                path: 'content-items',
                name: 'creator-content-items',
                component: () => import(/* webpackChunkName: "creators" */ '../pages/creators/profile/tabs/CreatorContentItemsTab.vue'),
                props: getRouteProps(),
              },
            ],
          },
        ],
      },

      /// BRANDS
      {
        path: 'brands/',
        component: WrapperPage,
        children: [
          {
            path: '',
            name: 'brands',
            component: () => import(/* webpackChunkName: "brands" */ '../pages/brands/Brands.vue'),
          },
          {
            path: '',
            name: 'my-brands',
            component: () => import(/* webpackChunkName: "brands" */ '../pages/brands/Brands.vue'),
            props: {
              brandOwnerView: true,
              showSearch: false,
            },
          },
          {
            path: 'create',
            name: 'create-brand',
            component: () => import(/* webpackChunkName: "brands" */ '../pages/brands/create/CreateBrand.vue'),
          },
          {
            path: ':brandID',
            name: 'brand',
            props: getRouteProps(),
            component: () => import(/* webpackChunkName: "brands" */ '../pages/brands/profile/Brand.vue'),
            // children: [
            //   {
            //     path: '',
            //     name: 'brand-information',
            //     props: true,
            //     component: () => import(/* webpackChunkName: "brands" */ '../pages/brands/profile/BrandInformationTab.vue'),
            //   },
            //   {
            //     path: 'campaigns',
            //     name: 'brand-campaigns',
            //     props: true,
            //     component: () => import(/* webpackChunkName: "brands" */ '../pages/brands/profile/BrandCampaigns.vue'),
            //   },
            // ],
          },
        ],
      },

      /// CAMPAIGNS
      {
        path: 'campaigns',
        component: WrapperPage,
        children: [
          {
            path: '',
            name: 'campaigns',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/Campaigns.vue'),
            props: getRouteProps(),
          },
          {
            path: 'create',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/create/CreateCampaign.vue'),
            children: [
              {
                path: 'details',
                name: 'create-campaign',
                component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/create/CampaignDetailsStep.vue'),
                props: getRouteProps(),
              },
            ],
          },
          {
            path: ':campaignID/edit',
            name: 'edit-campaign',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/create/CreateCampaign.vue'),
            props: getRouteProps({ edit: true }),
            children: [
              {
                path: 'details',
                name: 'edit-campaign--details',
                component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/create/CampaignDetailsStep.vue'),
                props: getRouteProps({ edit: true }),
              },
              {
                path: 'creator',
                name: 'edit-campaign--creator',
                component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/create/CampaignCreatorStep.vue'),
                props: getRouteProps({ edit: true }),
              },
              {
                path: 'briefing',
                name: 'edit-campaign--briefing',
                component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/create/CampaignBriefingStep.vue'),
                props: getRouteProps({ edit: true }),
              },
              {
                path: 'budget-rights',
                name: 'edit-campaign--budget-rights',
                component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/create/CampaignBudgetRightsStep.vue'),
                props: getRouteProps({ edit: true }),
              },
              {
                path: 'cover-image',
                name: 'edit-campaign--cover-image',
                component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/create/CampaignImageStep.vue'),
                props: getRouteProps({ edit: true }),
              },
            ],
          },
          {
            path: ':campaignID',
            name: 'campaign',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/view/Campaign.vue'),
            props: getRouteProps(),
          },
        ],
      },
      {
        path: 'my-campaigns',
        component: WrapperPage,
        props: getRouteProps({ private: true }),
        children: [
          {
            path: '',
            name: 'my-campaigns',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/Campaigns.vue'),
          },
          {
            path: 'invitations',
            name: 'campaign-invitations',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/invitations/Invitations.vue'),
            props: getRouteProps(),
          },
          {
            path: 'applications',
            name: 'campaign-applications',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/applications/Applications.vue'),
            props: getRouteProps(),
          },
          {
            path: 'submissions',
            component: WrapperPage,
            children: [
              {
                path: '',
                name: 'campaign-submissions',
                component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/contracts/Contracts.vue'),
                props: getRouteProps(),
              },
              {
                path: ':campaignID/:contractID',
                name: 'campaign-contract',
                component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/contracts/Contract.vue'),
                props: getRouteProps(),
              },
            ],
          },
        ],
      },

      /// PERSONAL
      // {
      //   path: 'profile',
      //   name: 'my-profile',
      //   component: () => import(/* webpackChunkName: "profile" */ '../pages/me/MyProfile.vue'),
      // },
      {
        path: 'settings',
        name: 'settings',
        component: Empty,
      },
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../pages/About.vue'),
      },

      /// ADMIN
      {
        path: 'admin',
        component: WrapperPage,
        children: [
          {
            path: '',
            name: 'admin',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/Admin.vue'),
          },
          {
            path: 'invitations',
            name: 'admin-campaign-invitations',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/campaigns/invitations/Invitations.vue'),
            props: {
              viewPerspective: Perspective.ADMIN,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/verify',
    name: 'verify-user',
    component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/verify/Verify.vue'),
    props: getRouteProps(),
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/Auth.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/LoginForm.vue'),
      },
      {
        path: '/logout',
        name: 'logout',
        redirect: () => {
          const { logout } = useAuth();
          logout();
          return { name: 'landing' };
        },
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/RegistrationForm.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line consistent-return,@typescript-eslint/no-unused-vars
router.beforeEach(async (to, from, next) => {
  const { user: authUser, authenticationDone, loadUser } = useAuth();

  if (!authenticationDone.value) {
    await loadUser();
  }

  // eslint-disable-next-line no-shadow
  const user = authUser?.value;
  const { requiresAuth } = to.meta;

  if (user) {
    if (user.verified) {
      if (to.name === 'verify-user' || to.name === 'login') {
        next({ name: 'dashboard' });
      } else {
        next();
      }
    } else { // not verified
      if (to.name !== 'verify-user') { // eslint-disable-line no-lonely-if
        next({ name: 'verify-user' });
      } else {
        next();
      }
    }
  } else {
    if (requiresAuth) { // eslint-disable-line no-lonely-if
      next({ name: 'login' });
    } else if (to.name === 'verify-user') {
      next({ name: 'login' });
    } else {
      window.scrollTo(0, 0);
      next();
    }
  }
});

export default router;
