<template>
  <div class="campaign-creation-step">
    <h4>Budget and Usage Rights</h4>
    <p>
      What is your estimated Budget? If you don’t have a set budget yet, select open budget and close the
      applications when you have enough creators onboard. Full freedom and command!
    </p>
    <p>
      Usage rights define the contractual agreement between a creator and your brand.
    </p>

    <h6 class="p-mt-6">Budget</h6>
    <div class="p-d-flex p-align-center">
      <Dropdown
        v-model="fixedBudget"
        :options="fixedBudgetOptions"
        optionLabel="name"
        optionValue="value"
        placeholder="Fixed budget"
        class="p-mr-4"
      />
      <div class="p-inputgroup p-d-inline-flex" style="width: auto;" v-if="fixedBudget === 'fixed'">
        <span class="p-inputgroup-addon"><span class="material-icons">sell</span></span>
        <InputNumber v-model="budget" placeholder="Fill in campaign budget" />
        <span class="p-inputgroup-addon">$</span>
      </div>
    </div>

    <h6 class="p-mt-6">Usage Rights</h6>
    <div class="p-grid">
      <div class="p-col-3">
        <Dropdown
          v-model="platforms"
          :options="platformsOptions"
          optionLabel="name"
          optionValue="value"
          placeholder="Select Platforms"
          class="w-100"
          :class="invalidClass('platforms')"
        />
      </div>
      <div class="p-col-3">
        <Dropdown
          v-model="duration"
          :options="durationOptions"
          optionLabel="name"
          optionValue="value"
          placeholder="Select Duration"
          class="w-100"
          :class="invalidClass('duration')"
        />
      </div>
      <div class="p-col-6">
        <MultiSelect
          v-model="regions"
          :options="regionsOptions"
          optionLabel="name"
          optionValue="value"
          placeholder="Select Regions"
          class="w-100"
          :class="invalidClass('regions')"
        />
      </div>
    </div>

    <div class="next p-d-flex p-justify-between p-mt-4">
      <Button
        label="Previous step"
        class="p-button-text p-button-secondary gi-button p-text-uppercase p-mr-6"
        icon="pi pi-angle-left"
        @click="$router.push({ name: 'edit-campaign--briefing', params: { campaignID }})"
      />
      <Button
        label="Save and continue"
        :loading="controller.campaign.manager.updatingInProgress.value"
        class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
        icon="pi pi-angle-double-right"
        iconPos="right"
        @click="controller.next"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import MultiSelect from 'primevue/multiselect';
import { CampaignData } from '@/data/types';
import { CampaignStep, getStep, useCampaignStep } from '@/pages/campaigns/create/modules/campaign-step-module';

export default defineComponent({
  name: 'CampaignBudgetRightsStep',

  components: {
    Button,
    Dropdown,
    InputNumber,
    MultiSelect,
  },

  props: {
    campaignID: String,
    campaign: Object as PropType<CampaignData>,
    edit: Boolean, // TODO: enum
  },

  setup(props) {
    const campaign = computed<CampaignData | undefined>(() => props.campaign);

    const budget = ref(campaign?.value?.budget || undefined);
    const fixedBudget = ref(campaign?.value?.budget === 0 ? 'open' : 'fixed');
    const platforms = ref(campaign?.value?.rights.platforms || '');
    const duration = ref(campaign?.value?.rights.duration || '');
    const regions = ref(campaign?.value?.rights.regions || '');

    const fixedBudgetOptions = [
      { name: 'Fixed Budget', value: 'fixed' },
      { name: 'Open Budget', value: 'open' },
    ];
    const platformsOptions = [
      { name: 'Social Media', value: 'social-media' },
      { name: 'Linear TV', value: 'linear-tv' },
      { name: 'Social Media + Linear TV', value: 'social-media+linear-tv' },
    ];
    const durationOptions = [
      { name: '1 Month', value: '1m' },
      { name: '3 Months', value: '3m' },
      { name: '6 Months', value: '6m' },
      { name: '12 Months', value: '12m' },
      { name: 'Indefinite', value: 'indefinite' },
    ];
    const regionsOptions = [
      { name: 'EMEA', value: 'emea' },
      { name: 'APAC', value: 'apac' },
      { name: 'North America', value: 'north-america' },
      { name: 'Latin America', value: 'latin-america' },
    ];

    const step = getStep('edit-campaign--budget-rights', props.campaignID) as CampaignStep;
    const controller = useCampaignStep(
      step,
      {
        platforms,
        duration,
        regions,
      },
      props.campaignID,
    );
    controller.setSerializer(() => ({
      budget: fixedBudget.value === 'open' ? 0 : budget.value,
      rights: {
        platforms: platforms.value,
        duration: duration.value,
        regions: regions.value,
      },
    }));

    return {
      budget,
      fixedBudget,
      fixedBudgetOptions,
      platforms,
      platformsOptions,
      duration,
      durationOptions,
      regions,
      regionsOptions,

      // step control
      step,
      controller,
      ...controller.validation,
    };
  },
});
</script>

<style lang="scss" scoped>
.campaign-creation-step {
  .material-icons {
    font-size: 18rem;
  }
}
</style>
