
import { defineComponent } from 'vue';

import { usePage, useCampaigns } from '@/modules';
import { AppPageConfig, AppPageProps } from '@/data/types';
import AppCampaignSubpage from '../../commons/AppCampaignSubpage.vue';
import ContractsList from './ContractsList.vue';

export default defineComponent({
  components: { ContractsList, AppCampaignSubpage },

  props: AppPageProps,

  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Submissions',
      ...props,
    });

    const page = useCampaigns(config.value.viewPerspective);
    page.contracts.loadContracts();

    return {
      config,
      loading: page.contracts.loading,
      campaigns: page.contracts.contracts,
      associatedCampaigns: page.contracts.associatedCampaigns,
      filterByCampaignID: page.contracts.filterByCampaignID,
    };
  },
});
