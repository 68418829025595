<template>
  <div class="campaign-list-item">
    <CampaignStatusChip :campaign="item" />

    <div class="box">
      <div class="header">
        <CampaignPhoto :campaign="item" />
        <h5 class="name">
          {{ item.name }}
        </h5>
      </div>

      <div class="info">
        <div class="timeframe">
          <div class="icon"><span class="material-icons">today</span></div>
          <div class="dates">
            {{ formatDate(item.dates?.startDate) }}
            -
            {{ formatDate(item.dates?.endDate) }}
          </div>
        </div>

        <div class="budget">
          <div class="icon"><span class="material-icons">sell</span></div>
          <div class="amount">{{ item.budget ? item.budget : 'open budget' }}</div>
        </div>
      </div>

      <div v-if="item.goal" class="description">
        <div class="icon"><span class="material-icons">subject</span></div>
        <div class="text">
          {{ item.goal }}
        </div>
      </div>

      <div class="brand">
        <BrandPhoto :brand="item.brand" />
        <div class="name">{{ item.brand.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandPhoto from '@/pages/brands/profile/BrandPhoto.vue';
import CampaignPhoto from './view/CampaignPhoto.vue';
import CampaignStatusChip from './view/CampaignStatusChip.vue';
import { formatDate } from '../../utils/globals/date-utils';

export default {
  name: 'CampaignListItem',

  components: { CampaignStatusChip, CampaignPhoto, BrandPhoto },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  methods: {
    formatDate,
  },
};
</script>

<style lang="scss" scoped>
.campaign-list-item {
  margin-bottom: 10rem;
  cursor: pointer;
  @include font-montserrat();

  &:hover {
    .box {
      border-color: #EEE;
    }
  }

  .box {
    margin-top: 14rem;
    padding: 20rem;

    background: #f9f9f9;
    border: 1px solid transparent;
    border-radius: 15px;
    transition: border-color .3s ease;
  }

  .header {
    display: flex;

    .campaign-photo {
      width: 85rem;
      height: 66rem;
      margin-right: 14rem;
    }
    .name {
      margin: 0;
      flex: 1 0 0;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28rem;

    .timeframe, .budget {
      display: flex;
      font-weight: 600;
      font-size: 12rem;
      line-height: 15rem;
      color: $giDarkGrey;

      .icon .material-icons {
        margin-right: 8rem;
        font-size: 18rem;
      }
    }
  }

  .description {
    display: flex;
    .icon {
      margin-right: 8rem;
      color: #181818;
    }
    .text {
      --lh: 15rem;
      --max-lines: 3;

      font-size: 12rem;
      font-weight: 600;
      line-height: var(--lh);
      color: #BABABA;

      height: calc(var(--lh) * var(--max-lines));
      overflow: hidden;

      //position: relative;
      //max-height: calc(var(--lh) * var(--max-lines));
      //padding-right: 20rem; /* space for ellipsis */
      //
      //&::before {
      //  position: absolute;
      //  content: "(...)";
      //  inset-block-end: 0; /* "bottom" */
      //  inset-inline-end: 0; /* "right" */
      //}
      //&::after {
      //  content: "";
      //  position: absolute;
      //  inset-inline-end: 0; /* "right" */
      //  width: 1rem;
      //  height: 1rem;
      //  background: white;
      //}
    }
  }

  .brand {
    display: flex;
    align-items: center;
    margin-top: 22rem;

    &-photo {
      width: 26rem;
      height: 26rem;
      margin-right: 8rem;
      color: #181818;
      ::v-deep(.default) { // TODO: set icon font size to base, and just update Photo font-size
        font-size: 24rem;
      }
    }
    .name {
      font-weight: 600;
      font-size: 12rem;
      color: #181818;
    }
  }
}
</style>
