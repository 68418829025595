<template>
  <div class="license-requests-list">
    <LicenseRequestContentItem
      v-for="item in items"
      :key="item.id"
      :viewPerspective="viewPerspective"
      :item="item"
      v-bind="$attrs"
      class="license-requests-list-item"
    />

    <EmptyList
      v-if="!items.length"
      class="p-mt-6"
      title="No License Requests"
      text="Have you applied any filter?"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ContentItem, Perspective } from '@/data/types';
import EmptyList from '@/pages/commons/list/EmptyList.vue';
import LicenseRequestContentItem from './LicenseRequestContentItem.vue';

export default defineComponent({
  components: { LicenseRequestContentItem, EmptyList },

  props: {
    items: {
      type: Array as PropType<Array<ContentItem>>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
  },
});
</script>

<style lang="scss" scoped>
.license-requests-list {
  &-item {
    &:not(:last-child) {
      margin-bottom: 70rem;
    }
  }
}
</style>
