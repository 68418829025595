
import { computed, defineComponent, PropType, ref } from 'vue';
import { CampaignData, Perspective } from '@/data/types';

export default defineComponent({

  props: {
    campaign: {
      type: Object as PropType<CampaignData>,
      default: [],
    },
    campaignBoxClass: {
      type: Object,
      required: true,
    },
    campaignBoxType: { // application, contract
      type: String,
      required: true,
    },
    viewPerspective: String as PropType<Perspective>,
  },

  setup(props) {
    const list = computed(() => {
      if (props.campaignBoxType === 'application') {
        return props.campaign.applications;
      }
      if (props.campaignBoxType === 'contract') {
        return props.campaign.contracts;
      }
      return [];
    });

    // application in campaign.applications
    return {
      list,
    };
  },
});
