
import { defineComponent, PropType } from 'vue';
import { Campaign, CampaignData } from '@/data/types';
import CampaignPhoto from '../view/CampaignPhoto.vue'; // TODO: move to commons

export default defineComponent({
  components: { CampaignPhoto },

  props: {
    campaign: Object as PropType<Campaign | CampaignData>,
  },
});
