<template>
  <app-list-page
    title="Content Item"
    class="content-item"
  >
    <transition name="fade">
      <div v-if="!loading" class="content-item-info p-grid">
        <LicenseRequestModal
          v-model:show="showRequestLicenseModal"
          :working="content.requests.requestingInProgress.value"
          @request="sendLicenseRequest"
        />

        <div class="left p-col-9">
          <div class="video">
            <iframe
              width="100%"
              height="460px"
              :src="`https://www.youtube.com/embed/${item.vid}`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div class="p-d-flex p-justify-between p-mt-4">
            <h2>{{ item.title }}</h2>
            <div class="actions p-pl-4">
              <Button
                v-if="viewPerspective === 'brand'"
                label="Request a license"
                class="p-button-danger gi-button p-text-uppercase"
                @click="showRequestLicenseModal = true"
              />

              <Button
                v-if="viewPerspective === 'creator' && itemIsMine"
                class="p-button-link"
                :title="`Toggle visibility ${item.public ? 'OFF' : 'ON'}`"
                @click="toggleItemVisibility"
              >
                <span v-if="item.public" class="material-icons p-button-icon p-button-icon-left">visibility</span>
                <span v-else class="material-icons p-button-icon p-button-icon-left">visibility_off</span>
              </Button>
            </div>
          </div>
          <div class="dates">
            {{ item.publishedAt }}
          </div>

          <div class="stats">
<!--            stats-->
          </div>
        </div> <!-- // left -->

        <div class="right p-col-3">
          <div class="creator">
            <CreatorListItem :user="item.owner" />
          </div>
        </div>
      </div>
    </transition>
  </app-list-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import Button from 'primevue/button';

import { AppPageProps, LicenseRequestRequest } from '@/data/types';
import { useAuth, useContentItems } from '@/modules';
import LicenseRequestModal from '@/pages/commons/modals/LicenseRequestModal.vue';
import AppListPage from '../../commons/AppListPage.vue';
import CreatorListItem from '../../creators/CreatorListItem.vue';

export default defineComponent({
  name: 'ContentItem',

  components: {
    Button,
    LicenseRequestModal,
    AppListPage,
    // eslint-disable-next-line vue/no-unused-components
    CreatorListItem,
  },

  props: {
    itemID: String,
    ...AppPageProps,
  },

  setup(props) {
    const { user } = useAuth();
    const content = useContentItems(props.viewPerspective);

    content.manager.loadSingle(props.itemID as string);

    // requesting a license
    const showRequestLicenseModal = ref(false);
    const sendLicenseRequest = (payload: LicenseRequestRequest) => {
      content.requests
        .request(props.itemID as string, payload)
        .then((success?: boolean) => {
          if (success) {
            showRequestLicenseModal.value = false;
          }
        });
    };

    const toggleItemVisibility = () => {
      if (props.itemID) {
        content.manager.update(props.itemID, { public: !content.manager.singleItem.value?.public });
      }
    };

    const itemIsMine = computed(() => user?.value?.id === content.manager.singleItem.value?.owner.id);

    return {
      content,
      loading: content.manager.loadingSingle,
      item: content.manager.singleItem,
      itemIsMine,
      // licensing
      showRequestLicenseModal,
      sendLicenseRequest,
      // visibility
      toggleItemVisibility,
    };
  },
});
</script>

<style lang="scss" scoped>
.content-item {
  &-info {
    margin: 60rem 140rem 80rem 140rem;

    display: flex;
  }
}
</style>
