
import { defineComponent, PropType } from 'vue';
import moment from 'moment';
import Button from 'primevue/button';
import { useConfirm } from 'primevue/useconfirm';
import {
  CampaignContractData,
  CampaignData,
  Perspective,
} from '@/data/types';
import UserShortPreview from '@/pages/commons/user/UserShortPreview.vue';
import StatusChip from '@/pages/commons/status/StatusChip.vue';
import CampaignShortPreview from '@/pages/campaigns/parts/CampaignShortPreview.vue';
import BrandShortPreview from '@/pages/commons/brand/BrandShortPreview.vue';

export default defineComponent({
  components: {
    BrandShortPreview,
    Button,
    StatusChip,
    UserShortPreview,
    CampaignShortPreview,
  },

  props: {
    campaign: {
      type: Object as PropType<CampaignData>,
      default: [],
    },
    contract: {
      type: Object as PropType<CampaignContractData>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
  },

  emits: ['respondToApplication', 'withdrawApplication'],

  setup(props, { emit }) {
    const confirm = useConfirm();

    // const statusText = computed(() => {
    //   if (props.viewPerspective === 'creator' && applicationAction.value === 'brand-respond') {
    //     return 'Waiting for brand';
    //   }
    //   if (props.viewPerspective === 'brand' && applicationAction.value === 'creator-respond') {
    //     return 'Waiting for creator';
    //   }
    //   return '';
    // });

    // const confirmWithdrawApplication = () => {
    //   let message = '';
    //   if (props.viewPerspective === 'brand') {
    //     message = 'Are you sure you want to withdraw your offer?';
    //   } else {
    //     message = 'Are you sure you want to withdraw your application?';
    //   }
    //
    //   confirm.require({
    //     message,
    //     header: 'Withdraw?',
    //     icon: 'pi pi-exclamation-triangle',
    //     accept: () => {
    //       emit('withdrawApplication', {
    //         perspective: props.viewPerspective,
    //         campaign: props.campaign,
    //         application: props.application,
    //       });
    //     },
    //   });
    // };

    const formatDate = (date: string) => moment(date).format('D MMM YYYY');

    return {
      // statusText,
      // confirmWithdrawApplication,
    };
  },
});
