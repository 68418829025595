<template>
  <div class="content-items-list p-grid">
    <ContentItemsListItem
      v-for="item in items"
      :key="item.id"
      class="p-col-12 p-md-6 p-lg-6 p-xl-4 p-xxl-3"
      :item="item"
    />

    <EmptyList v-if="!items.length" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ContentItem } from '@/data/types';
import ContentItemsListItem from './ContentItemsListItem.vue';
import EmptyList from '../commons/list/EmptyList.vue';

export default defineComponent({
  name: 'ContentItemsList',

  // eslint-disable-next-line vue/no-unused-components
  components: { ContentItemsListItem, EmptyList },

  props: {
    items: {
      type: Array as PropType<Array<ContentItem>>,
      default: [],
    },
  },
});
</script>

<style lang="scss" scoped>
</style>
