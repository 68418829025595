<template>
  <Chip
    :label="campaign.status || 'active'"
    class="campaign-status-chip chip"
    :data-status="campaign.status || 'active'"
  />
</template>

<script lang="ts">
import Chip from 'primevue/chip';

import { defineComponent, PropType } from 'vue';
import { Campaign } from '../../../data/types';

// FIXME: use StatusChip instead of this one!

export default defineComponent({
  components: { Chip },
  props: {
    campaign: Object as PropType<Campaign>,
  },
});
</script>

<style lang="scss" scoped>
.campaign-status-chip {
  @include font-montserrat();
  font-weight: 600;
  font-size: 12rem;
  text-transform: uppercase;
  padding: 0 14rem;

  ::v-deep(.p-chip-text) {
    line-height: 18rem;
    margin: 0;
    color: #fff;
  }

  &[data-status="active"] {
    background-color: #4EC461;
  }
  &[data-status="pending"] {
    background-color: #756C93;
  }
  &[data-status="draft"], &[data-status="rejected"] {
    background-color: #F5B74E;
  }
  &[data-status="completed"] {
    background-color: #F54E5D;
  }
}
</style>
