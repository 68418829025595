
import { computed, defineComponent, PropType } from 'vue';
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { useConfirm } from 'primevue/useconfirm';
import { formatDate } from '@/utils/globals/date-utils';
import { CampaignApplicationData, CampaignData, NegotiationOffer, Perspective } from '@/data/types';
import UserShortPreview from '@/pages/commons/user/UserShortPreview.vue';
import StatusChip from '@/pages/commons/status/StatusChip.vue';
import CampaignShortPreview from '@/pages/campaigns/parts/CampaignShortPreview.vue';
import BrandShortPreview from '@/pages/commons/brand/BrandShortPreview.vue';

export default defineComponent({
  components: {
    BrandShortPreview,
    Button,
    StatusChip,
    UserShortPreview,
    CampaignShortPreview,
    Accordion,
    AccordionTab,
  },

  props: {
    campaign: {
      type: Object as PropType<CampaignData>,
      default: [],
    },
    application: {
      type: Object as PropType<CampaignApplicationData>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
  },

  emits: ['respondToApplication', 'withdrawApplication'],

  setup(props, { emit }) {
    const confirm = useConfirm();

    const lastOffer = computed(() => props.application.offers[props.application.offers.length - 1]);
    const applicationAction = computed(() => {
      if (lastOffer.value.status === 'pending') {
        if (props.viewPerspective === 'brand') {
          if (lastOffer.value.from === 'creator') {
            return 'brand-respond';
          }
          if (lastOffer.value.from === 'brand') {
            return 'creator-respond';
          }
        }
        if (props.viewPerspective === 'creator') {
          if (lastOffer.value.from === 'creator') {
            return 'brand-respond';
          }
          if (lastOffer.value.from === 'brand') {
            return 'creator-respond';
          }
        }
      }
      return '';
    });
    const statusText = computed(() => {
      if (props.viewPerspective === 'creator' && applicationAction.value === 'brand-respond') {
        return 'Waiting for brand';
      }
      if (props.viewPerspective === 'brand' && applicationAction.value === 'creator-respond') {
        return 'Waiting for creator';
      }
      return '';
    });

    const confirmWithdrawApplication = () => {
      let message = '';
      if (props.viewPerspective === 'brand') {
        message = 'Are you sure you want to withdraw your offer?';
      } else {
        message = 'Are you sure you want to withdraw your application?';
      }

      confirm.require({
        message,
        header: 'Withdraw?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          emit('withdrawApplication', {
            perspective: props.viewPerspective,
            campaign: props.campaign,
            application: props.application,
          });
        },
      });
    };

    const printHistoryItem = (offer: NegotiationOffer, index: number, accepted = false) => {
      let result = '';
      const isCreator = props.viewPerspective === 'creator';

      if (offer.from === 'creator') {
        if (isCreator) {
          result += 'You ';
        } else {
          result += 'Creator ';
        }

        if (index === 0) {
          result += `applied for the campaign on ${formatDate(offer.createdAt)}. `;
          if (!props.campaign.budget) { // open budget
            if (offer.final) {
              result += `${isCreator ? 'Your' : 'His/her'} initial offer was $${offer.price} and is final.`;
            } else {
              result += `${isCreator ? 'Your' : 'His/her'} initial offer was $${offer.price} and is open for negotiations.`;
            }
          } else { // fixed budget
            result += `${isCreator ? 'Your' : 'He/she'} agreed to the fixed budget of $${offer.price}`;
          }
        } else {
          result += `made a counter offer of $${offer.price} on ${formatDate(offer.createdAt)} `;
          if (offer.final) {
            result += 'and it\'s final.';
          } else {
            result += 'and is open for negotiations.';
          }
        }
      } else { // from brand
        result += `Brand made a counter offer of $${offer.price} on ${formatDate(offer.createdAt)} `;
        if (offer.final) {
          result += 'and it\'s final.';
        } else {
          result += 'and is open for negotiations.';
        }
      }

      if (accepted) {
        if (offer.from === 'creator') {
          result = `Brand accepted the offer on ${formatDate(offer.updatedAt)}.`;
        } else {
          result = `Creator accepted the offer on ${formatDate(offer.updatedAt)}.`;
        }
      }

      return result;
    };

    const history = computed(() => {
      const offers: Array<any> = props.application.offers.map((offer: any, index: number) => printHistoryItem(offer, index));

      if (lastOffer.value.status === 'accepted') {
        offers.push(printHistoryItem(lastOffer.value, props.application.offers.length, true));
      }
      return offers;
    });

    return {
      lastOffer,
      statusText,
      applicationAction,
      confirmWithdrawApplication,
      history,
    };
  },
});
