<template>
  <app-page :title="title" :padded="true" :loading="loading" class="create-campaign">
    <template #router>
      <Steps :model="steps" class="steps" />
      <router-view
        v-if="!editMode || !loading"
        class="container"
        :campaign="campaign"
      />
    </template>
  </app-page>
</template>

<script lang="ts">
import { defineComponent, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import Steps from 'primevue/steps';
import AppPage from '@/pages/commons/AppPage.vue';
import { useCampaigns } from '@/modules';
import { Perspective } from '@/data/types';
import { getSteps } from './modules/campaign-step-module';

export default defineComponent({
  components: { AppPage, Steps },

  props: {
    campaignID: String,
    edit: Boolean, // TODO: enum
  },

  setup(props) {
    const router = useRouter();
    const editMode = computed(() => props.edit);
    const title = computed(() => (editMode.value ? 'Edit Campaign' : 'Create Campaign'));

    const { manager } = useCampaigns(Perspective.BRAND);

    const loadCampaign = () => {
      if (editMode.value) {
        manager.loadSingle(props.campaignID as string);
      }
    };

    loadCampaign();

    // FIXME: currently this is a workaround for updating previous step's fields
    // The issue is that when we update the campaign, we switch steps, and then if we go back
    // the new step's data is outdated. It's so because it's connected to manager.singleCampaign,
    // which does not change. We should update THAT value upon successful response,
    // so we don't do another request
    watch(router.currentRoute, () => {
      loadCampaign();
    });

    const steps = computed(() => getSteps(props.campaignID as string));

    return {
      title,
      steps,
      editMode,
      campaign: manager.singleCampaign,
      loading: manager.loadingSingle,
    };
  },
});
</script>

<style scoped lang="scss">
.create-campaign {
  .steps {
    margin-bottom: 60rem;
  }
}
</style>
