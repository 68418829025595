import { useAPI, useAuth } from '@/modules';
import { AppPagePerspective, CampaignData, Perspective } from '@/data/types';

const { user } = useAuth();

export const useCampaignsList = (perspective: AppPagePerspective) => {
  const { loading, finished, data: campaigns, execute } = useAPI<Array<CampaignData>>('/campaigns', false);

  const loadCampaigns = (loadOnlyMine = false, limit = 0) => {
    const creatorID = user?.value?.id;
    const brandID = user?.value?.brand?.id;
    const targetID = perspective === Perspective.BRAND ? brandID : creatorID;

    if (perspective === Perspective.BRAND && !brandID) {
      throw new Error('Could not load campaigns for a brand, because brandID is missing!');
    }

    const params = { perspective, targetID };

    if (perspective === Perspective.CREATOR && !loadOnlyMine) {
      delete params.targetID;
    }

    return execute({ params })
      .then((result?: Array<CampaignData>) => {
        if (result) {
          if (limit) {
            campaigns.value = result.slice(0, limit);
          } else {
            campaigns.value = result;
          }
        }
        return campaigns.value;
      });
  };

  return {
    campaigns,
    loading,
    finished,
    loadCampaigns,
  };
};
