import moment from 'moment';

export const formatDate = (date?: string | Date, format = 'D MMM YYYY') => {
  if (!date) {
    return '';
  }
  if (format === 'long') {
    format = 'D MMM YYYY, H:m A'; // eslint-disable-line no-param-reassign
  }
  return moment(date).format(format);
};

export const timeAgo = (date?: string | Date) => {
  if (!date) {
    return '';
  }
  return moment(date).fromNow();
};
