<template>
  <div class="campaign-creation-step">
    <h4>Creator Details</h4>
    <p>
      This section defines the Creator criteria according to the business need by a brand.
      This will help the creators understand if they fit the criteria or not before applying for a campaign.
    </p>

    <div class="p-grid p-mt-6">
      <div class="p-col-3">
        <h6>Gender</h6>
        <Dropdown
          v-model="gender"
          :options="genderOptions"
          optionLabel="name"
          optionValue="value"
          placeholder="Select Gender"
          class="w-100"
          :class="invalidClass('gender')"
        />
      </div>
      <div class="p-col-5">
        <h6>Target Audience</h6>
        <div class="p-grid">
          <div class="p-col-6">
            <InputNumber
              v-model="minAge"
              placeholder="Minimum age" showButtons
              incrementButtonClass="gi-input-subbutton" decrementButtonClass="gi-input-subbutton"
              :min="10" :max="100"
              class="w-100" :class="invalidClass('minAge')"
            />
          </div>
          <div class="p-col-6">
            <InputNumber
              v-model="maxAge"
              placeholder="Maximum age" showButtons
              incrementButtonClass="gi-input-subbutton" decrementButtonClass="gi-input-subbutton"
              :min="10" :max="100"
              class="w-100" :class="invalidClass('maxAge')"
            />
          </div>
        </div>
      </div>
      <div class="p-col-4">
        <div class="p-d-flex">
          <h6>Target Audience Country</h6>
          <Info text="Please select multiple countries if you wish to run a global campaign
            thus allowing creators from all over to apply and be a part of your campaign"
            style="font-size: 20rem; margin-left: 5rem; margin-top: -4rem;"
          />
        </div>
        <MultiSelect
          v-model="country"
          :options="countryOptions"
          optionLabel="name"
          optionValue="code"
          placeholder="Select Countries"
          display="chip"
          :filter="true"
          class="multiselect-custom w-100"
          :class="invalidClass('country')"
        />
      </div>
    </div>

    <div class="p-grid p-mt-6">
      <div class="p-col-3">
        <div class="p-d-flex">
          <h6>Language</h6>
          <Info text="Please select languages based on your target country"
            style="font-size: 20rem; margin-left: 5rem; margin-top: -4rem;"
          />
        </div>
        <Dropdown
          v-model="language"
          :options="languageOptions"
          optionLabel="name"
          optionValue="code"
          :filter="true"
          placeholder="Select a Language"
          :showClear="true"
          class="w-100"
          :class="invalidClass('language')"
        />
      </div>
      <div class="p-col-6 p-offset-0">
        <div class="p-d-flex">
          <h6>Platforms & Followers</h6>
          <Info text="Please choose the platforms corresponding to the advertising channels you would wish"
                style="font-size: 20rem; margin-left: 5rem; margin-top: -4rem;"
          />
        </div>

        <div class="p-grid">
          <div class="p-col-4">
            <div class="p-field-checkbox">
              <Checkbox id="youtube" name="youtube" value="youtube" v-model="platforms" />
              <label for="youtube">YouTube</label>
            </div>
          </div>
          <div class="p-col-8 p-d-flex">
            <InputNumber placeholder="Minimum followers" class="p-mr-2" :disabled="!platforms.length" />
            <InputNumber placeholder="Maximum followers" :disabled="!platforms.length" />
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-4">
            <div class="p-field-checkbox">
              <Checkbox id="instagram" name="instagram" value="instagram" :disabled="true" />
              <label for="instagram">Instagram</label>
            </div>
          </div>
          <div class="p-col-8 p-d-flex">
            <InputNumber placeholder="Minimum followers" class="p-mr-2" :disabled="true" />
            <InputNumber placeholder="Maximum followers" :disabled="true" />
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-4">
            <div class="p-field-checkbox">
              <Checkbox id="twitch" name="twitch" value="twitch" :disabled="true" />
              <label for="twitch">Twitch</label>
            </div>
          </div>
          <div class="p-col-8 p-d-flex">
            <InputNumber placeholder="Minimum followers" class="p-mr-2" :disabled="true" />
            <InputNumber placeholder="Maximum followers" :disabled="true" />
          </div>
        </div>
      </div>
    </div>

    <div class="next p-d-flex p-justify-between p-mt-4">
      <Button
        label="Previous step"
        class="p-button-text p-button-secondary gi-button p-text-uppercase p-mr-6"
        icon="pi pi-angle-left"
        @click="$router.push({ name: 'edit-campaign--details', params: { campaignID }})"
      />
      <Button
        label="Save and continue"
        :loading="controller.campaign.manager.updatingInProgress.value"
        class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
        icon="pi pi-angle-double-right"
        iconPos="right"
        @click="controller.next"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import MultiSelect from 'primevue/multiselect';
import { CampaignData } from '@/data/types';
import Info from '@/components/Info.vue';
import { CampaignStep, getStep, useCampaignStep } from '@/pages/campaigns/create/modules/campaign-step-module';
import { countries, languages } from '../../../data/static';

export default defineComponent({
  name: 'CampaignCreatorStep',

  components: {
    Info,
    Button,
    Dropdown,
    Checkbox,
    InputNumber,
    MultiSelect,
  },

  props: {
    campaignID: String,
    campaign: Object as PropType<CampaignData>,
    edit: Boolean, // TODO: enum
  },

  setup(props) {
    const campaign = computed<CampaignData | undefined>(() => props.campaign);

    const gender = ref(campaign?.value?.creatorRequirements.gender || '');
    const minAge = ref(campaign?.value?.creatorRequirements.audience.minAge || undefined);
    const maxAge = ref(campaign?.value?.creatorRequirements.audience.maxAge || undefined);
    const country = ref(campaign?.value?.creatorRequirements.country || undefined);
    const language = ref(campaign?.value?.creatorRequirements.language || undefined);
    const platforms = ref([]);

    const genderOptions = [
      { name: 'Any', value: 'any' },
      { name: 'Male', value: 'male' },
      { name: 'Female', value: 'female' },
    ];
    const countryOptions = ref(countries);
    const languageOptions = ref(languages);

    const step = getStep('edit-campaign--creator', props.campaignID) as CampaignStep;
    const controller = useCampaignStep(
      step,
      {
        gender,
        // minAge,
        // maxAge,
        country,
        language,
        // platforms,
      },
      props.campaignID,
    );
    controller.setSerializer(() => ({
      creatorRequirements: {
        gender: gender.value,
        audience: {
          minAge: minAge.value,
          maxAge: maxAge.value,
        },
        country: country.value,
        language: language.value,
      },
    }));

    return {
      gender,
      genderOptions,
      minAge,
      maxAge,
      country,
      countryOptions,
      language,
      languageOptions,
      platforms,
      // step control
      step,
      controller,
      ...controller.validation,
    };
  },
});
</script>
