import { PropType } from 'vue';

export enum Perspective {
  BRAND = 'brand',
  CREATOR = 'creator',
  ADMIN = 'admin',
}

export type AppPagePerspective = Perspective.BRAND | Perspective.CREATOR | Perspective.ADMIN;

export interface AppRouteProps {
  viewPerspective?: AppPagePerspective;
}

export interface SearchConfig {
  enabled?: boolean;
  defaultType?: string;
}

export interface AppPageConfig extends AppRouteProps {
  title: string;
  search?: SearchConfig;
  // list?: boolean;
}

export type AppCampaignSubpageConfig = AppPageConfig & AppRouteProps & {}

export const AppPageProps = {
  viewPerspective: String as PropType<AppPagePerspective>,
};
