<template>
  <app-page :config="config" :loading="loading">
    <div v-if="contract" class="contract">
      <div class="contract-details">
        <div class="p-grid p-nogutter">
          <div class="p-col-4 p-pr-4">
            <CampaignShortPreview :campaign="campaign" />
          </div>

          <div class="p-col-6 p-grid">
            <div class="p-col-12 p-xl-7">
              <h6>Campaign Period</h6>
              <h5>{{ formatDate(campaign.dates.startDate) }} - {{ formatDate(campaign.dates.endDate) }}</h5>
            </div>
            <div class="p-col-12 p-xl-5">
              <h6>Contract Price</h6>
              <h5>${{ contract.price }}</h5>
            </div>
          </div>

          <div class="p-col-2">
            <StatusChip :status="contract.status" :text="contract.status" />
            <br /><br />
            <Button
              v-if="viewPerspective === Perspective.BRAND && contract.status === 'active'"
              label="Complete Contract"
              class="p-button-danger gi-button p-text-uppercase"
              @click="showingCompleteContractModal = true"
            />
          </div>
        </div>
        <!-- // first row -->

        <div class="p-grid p-nogutter p-mt-5">
          <div class="p-col-4">
            <h6>Content Requirements</h6>
            <div class="p-grid">
              <div class="p-col-5">
                <h6 class="small">Content Type</h6>
                <h5>{{ campaign.contentType }}</h5>
              </div>
              <div class="p-col-7">
                <h6 class="small">Reworks Count (if necessary)</h6>
                <h5>{{ campaign.reworksCount }}</h5>
              </div>
            </div>
          </div>
          <div class="p-col-8">
            <h6>Usage Rights</h6>
          </div>
        </div>
      </div>
      <hr>
<!--      campaign: <pre>{{ campaign }}</pre>-->
<!--      contract: <pre>{{ contract }}</pre>-->
<!--      deliverables: <pre>{{ contract.deliverables }}</pre>-->
<!--      viewPerspective: {{ viewPerspective }}-->

<!--      <h5>Deliverables</h5>-->
<!--      <DeliverablesList-->
<!--        :campaign="campaign"-->
<!--        :contract="contract"-->
<!--        :deliverables="contract.deliverables || []"-->
<!--        :viewPerspective="viewPerspective"-->
<!--        :showEmptyList="viewPerspective !== 'creator'"-->
<!--        @resubmit="openResubmitDeliverableModal"-->
<!--        @accept="openAcceptDeliverableModal"-->
<!--        @reject="openRejectDeliverableModal"-->
<!--      />-->

      <div class="p-grid p-nogutter p-mt-4">
        <div class="p-col-12 p-mb-6 p-mb-xl-0 p-xl-4 p-pr-xl-3">
          <h5>Deliverables</h5>
          <Accordion :multiple="true" :activeIndex="[0, 1]">
            <AccordionTab>
              <template #header>
                <span>Videos</span>
                <span class="material-icons-outlined p-mr-2">videocam</span>
              </template>

              <DeliverablesTypeList
                :campaign="campaign"
                :contract="contract"
                :deliverables="videoDeliverablesList"
                :viewPerspective="viewPerspective"
                :showEmptyList="true"
                :selectedDeliverable="selectedDeliverable"
                @select="selectDeliverable"
              />
            </AccordionTab>

            <AccordionTab>
              <template #header>
                <span>Images</span>
                <span class="material-icons-outlined p-mr-2">image</span>
              </template>

              <DeliverablesTypeList
                :campaign="campaign"
                :contract="contract"
                :deliverables="imageDeliverablesList"
                :viewPerspective="viewPerspective"
                :showEmptyList="true"
                :selectedDeliverable="selectedDeliverable"
                @select="selectDeliverable"
              />
            </AccordionTab>
          </Accordion>
        </div>
        <div class="p-col-12 p-xl-8 p-pl-xl-3">
          <DeliverablesList
            :campaign="campaign"
            :contract="contract"
            :deliverables="selectedDeliverableList"
            :viewPerspective="viewPerspective"
            :showEmptyList="viewPerspective !== 'creator'"
            @resubmit="openResubmitDeliverableModal"
            @accept="openAcceptDeliverableModal"
            @reject="openRejectDeliverableModal"
          />

          <div class="actions w-100 p-text-center">
            <Button
              v-if="viewPerspective === Perspective.CREATOR && contract.status === 'active'"
              label="Submit Deliverable"
              class="p-button-danger gi-button p-text-uppercase"
              @click="showSubmitDeliverableModal = true"
            />
          </div>
<!--          <DeliverablesList-->
<!--            :campaign="campaign"-->
<!--            :contract="contract"-->
<!--            :deliverables="contract.deliverables || []"-->
<!--            :viewPerspective="viewPerspective"-->
<!--            :showEmptyList="viewPerspective !== 'creator'"-->
<!--            @resubmit="openResubmitDeliverableModal"-->
<!--            @accept="openAcceptDeliverableModal"-->
<!--            @reject="openRejectDeliverableModal"-->
<!--          />-->
        </div>
      </div>

      <SubmitDeliverableModal
        ref="submitDeliverableModalRef"
        v-model:show="showSubmitDeliverableModal"
        :working="submitting"
        @submit="handleDeliverableModalSubmit"
      />

      <RequestDeliverableChangeModal
        ref="rejectDeliverableModalRef"
        v-model:show="showRejectDeliverableModal"
        :working="rejecting"
        @request="handleDeliverableModalReject"
      />

      <ConfirmModal
        title="Accept this content link?"
        ref="acceptDeliverableModalRef"
        v-model:show="showingAcceptDeliverableModal"
        :working="accepting"
        @confirm="handleDeliverableModalAccept"
      >
        Do you want to accept this content link? Once you accept it you can not revert this action.
      </ConfirmModal>

      <ConfirmModal
        title="Complete contract?"
        ref="completeContractModalRef"
        confirmButtonText="Complete"
        v-model:show="showingCompleteContractModal"
        :working="completing"
        @confirm="handleCompleteContractModalResult"
      >
        Completing the contract means you're satisfied with the results of all deliverables.
        By doing so, the creators won't be able to submit new or edit old deliverables,
        and payment process would be started.
        <br /><br />
        Are you sure you'd like to complete this contract?
      </ConfirmModal>
    </div>
  </app-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

import {
  AppPageConfig,
  AppPageProps,
  CampaignContractData, CampaignDeliverableData,
  Perspective,
} from '@/data/types';
import { APIError } from '@/utils/globals/error-utils';
import { formatDate } from '@/utils/globals/date-utils';
import ConfirmModal from '@/pages/commons/modals/ConfirmModal.vue';
import SubmitDeliverableModal from '@/pages/commons/modals/SubmitDeliverableModal.vue';
import RequestDeliverableChangeModal from '@/pages/commons/modals/RequestDeliverableChangeModal.vue';
import StatusChip from '@/pages/commons/status/StatusChip.vue';
import CampaignShortPreview from '@/pages/campaigns/parts/CampaignShortPreview.vue';
import { usePage, useCampaigns } from '../../../modules';
import AppPage from '../../commons/AppPage.vue';
import DeliverablesList from './deliverables/DeliverablesList.vue';
import DeliverablesTypeList from './deliverables/DeliverablesTypeList.vue';

export default defineComponent({
  components: {
    DeliverablesTypeList,
    AppPage,
    Button,
    DeliverablesList,
    CampaignShortPreview,
    StatusChip,
    ConfirmModal,
    SubmitDeliverableModal,
    RequestDeliverableChangeModal,
    Accordion,
    AccordionTab,
  },

  props: {
    campaignID: {
      type: String,
      required: true,
    },
    contractID: {
      type: String,
      required: true,
    },
    ...AppPageProps,
  },

  setup(props) {
    const toast = useToast();

    const { config } = usePage<AppPageConfig>({
      title: 'Submissions',
      // list: true,
      ...props,
    });

    const handleRequestError = (err?: APIError) => {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    };

    const showSubmitDeliverableModal = ref(false);
    const submitDeliverableModalRef = ref<any>(null);
    const acceptDeliverableModalRef = ref<any>(null);
    const rejectDeliverableModalRef = ref<any>(null);
    const completeContractModalRef = ref<any>(null);

    const page = useCampaigns(config.value.viewPerspective);

    const contract = computed((): CampaignContractData | undefined => (page.contracts.singleContractCampaign.value?.contracts || [])
      .find((cntr: CampaignContractData) => cntr.id === props.contractID));

    const imageDeliverablesList = computed(() => (contract.value?.deliverables || []).filter((d) => d.type === 'image'));
    const videoDeliverablesList = computed(() => (contract.value?.deliverables || []).filter((d) => d.type === 'video'));
    const selectedDeliverableList = ref<Array<any>>([]);
    const selectedDeliverable = ref<CampaignDeliverableData>();

    const selectDeliverable = (d: CampaignDeliverableData) => {
      selectedDeliverableList.value = [d];
      selectedDeliverable.value = d;
    };

    const loadPageData = () => page.contracts.loadSingle(props.campaignID, props.contractID);
    loadPageData();

    watch(contract, () => {
      if (contract.value?.deliverables.length) {
        selectDeliverable(contract.value?.deliverables[0]);
      }
    });

    const handleDeliverableModalSubmit = (payload: any) => { // link, comment
      page.deliverables.submit(props.campaignID, props.contractID, payload)
        .then((success: any) => {
          if (success) {
            loadPageData();
            showSubmitDeliverableModal.value = false;

            toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Deliverable submitted successfully!',
              life: 3000,
            });
          }
        });
    };

    watch(page.deliverables.submittingError, (err?: APIError) => handleRequestError);

    // resubmit
    const openResubmitDeliverableModal = (payload: any) => {
      submitDeliverableModalRef.value.open(payload.deliverable);
    };

    // accept deliverable
    const showingAcceptDeliverableModal = ref(false);
    const openAcceptDeliverableModal = (payload: any) => {
      acceptDeliverableModalRef.value.open(payload);
    };
    const handleDeliverableModalAccept = (payload: any) => { // contract, deliverable, linkSet
      page.deliverables.accept(props.campaignID, props.contractID, payload.deliverable.id)
        .then((success: any) => {
          if (success) {
            loadPageData();
            showingAcceptDeliverableModal.value = false;

            toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Deliverable accepted successfully!',
              life: 3000,
            });
          }
        });
    };
    watch(page.deliverables.acceptingError, handleRequestError);

    // reject deliverable
    const showRejectDeliverableModal = ref(false);
    const openRejectDeliverableModal = (payload: any) => {
      rejectDeliverableModalRef.value.open(payload);
    };
    const handleDeliverableModalReject = (payload: any) => { // contract, deliverable, linkSet
      page.deliverables.reject(props.campaignID, props.contractID, payload.deliverable.id, payload.reason)
        .then((success: any) => {
          if (success) {
            loadPageData();
            showRejectDeliverableModal.value = false;

            toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Deliverable change request send successfully!',
              life: 3000,
            });
          }
        });
    };
    watch(page.deliverables.rejectingError, handleRequestError);

    // complete contract
    const showingCompleteContractModal = ref(false);
    const handleCompleteContractModalResult = () => {
      page.contracts.complete(props.campaignID, props.contractID)
        .then((success: any) => {
          if (success) {
            loadPageData();
            showingCompleteContractModal.value = false;
          }
        });
    };
    // < complete contract

    return {
      formatDate,
      Perspective,
      config,
      campaign: page.contracts.singleContractCampaign,
      loading: page.contracts.loadingSingle,
      contract,
      imageDeliverablesList,
      videoDeliverablesList,
      selectedDeliverableList,
      selectDeliverable,
      selectedDeliverable,
      // modal
      showSubmitDeliverableModal,
      handleDeliverableModalSubmit,
      submitting: page.deliverables.submitting,
      // resubmit
      submitDeliverableModalRef,
      openResubmitDeliverableModal,
      // accept
      acceptDeliverableModalRef,
      openAcceptDeliverableModal,
      showingAcceptDeliverableModal,
      handleDeliverableModalAccept,
      accepting: page.deliverables.accepting,
      // reject
      rejectDeliverableModalRef,
      showRejectDeliverableModal,
      openRejectDeliverableModal,
      handleDeliverableModalReject,
      rejecting: page.deliverables.rejecting,
      // complete contract
      completeContractModalRef,
      showingCompleteContractModal,
      handleCompleteContractModalResult,
      completing: page.contracts.completing,
    };
  },
});
</script>

<style lang="scss" scoped>
.contract {
  &-details {
    margin-bottom: 80rem;
  }
}

::v-deep(.p-accordion) {
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .p-accordion-header {
    .p-accordion-header-link {
      border: 0;
      flex-direction: row-reverse;
      justify-content: flex-end;
      .p-accordion-toggle-icon {
        flex-grow: 1;
        text-align: right;
      }
    }
  }

  .p-accordion-content {
    padding: 0;
    border: 0;
    //background-color: transparent;
    //border-radius: 0 !important;
  }
}

.deliverable-set {
  padding: 25rem 50rem;
}
</style>
