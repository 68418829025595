
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import { User } from '@/data/types';
import { useAuth } from '@/modules/auth-module';
import { useAuthForm } from './modules/auth-form-module';

import { SocialConnectResponse } from './social-def';

import SocialConnect from './components/SocialConnect.vue';
import UserTypeToggle from './components/UserTypeToggle.vue';

export default defineComponent({
  name: 'RegistrationForm',

  components: {
    SocialConnect,
    UserTypeToggle,
  },

  setup() {
    const { loadUser } = useAuth();
    const router = useRouter();
    const { connecting, userIsCreator, userType, availableSocialConnections } = useAuthForm();

    const handleUserConnect = (response: SocialConnectResponse) => {
      console.log('-- user just registered');
      loadUser(response.tokens.access.token)
        .then((success?: User | undefined) => {
          console.log('-- register loading my profile response: ', success);
          if (success) {
            router.push({ name: 'dashboard' });
          } else {
            // TODO: handle somehow?
          }
        });
    };

    return {
      connecting,
      userIsCreator,
      userType,
      availableSocialConnections,
      handleUserConnect,
    };
  },
});
