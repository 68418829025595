<template>
  <div class="profile-photo" :class="{ [type]: true }">
    <img v-if="url && !action" :src="url" alt="" referrerpolicy="no-referrer" class="real" />
    <img v-if="!url && !action && type === 'campaign'" src="/images/campaign-default.svg" alt="" class="default campaign" />
    <div v-if="!url && !action" class="material-icons default">{{ defaultIcon }}</div>
    <div v-if="!url && action === 'add'" class="material-icons" :class="{ [type]: true }">add</div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    url: String,
    type: {
      type: String as PropType<'brand' | 'user' | 'campaign' | 'add'>,
      required: true,
    },
    action: String,
  },

  setup(props) {
    const defaultIcon = computed(() => {
      if (props.type === 'brand') {
        return 'business';
      }
      if (props.type === 'user') {
        return 'account_circle';
      }
      // if (props.type === 'campaign') {
      //   return 'campaign';
      // }
      return '';
    });

    return {
      defaultIcon,
    };
  },
});
</script>

<style lang="scss" scoped>
.profile-photo {
  width: 100%;
  height: 100%;
  background: rgba(232, 232, 232, 0.34);
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  .default {
    // in case of svg default image
    pointer-events: none;
    user-select: none;
    font-size: 30rem;

    &.campaign {
      width: 60%;
      height: 60%;
    }
  }

  &.campaign {
    border-radius: 8rem;
  }
}
</style>
