<template>
  <app-list-page
    :title="(brandOwnerView ? 'My ' : '') + 'Brands'"
    pageListEndpoint="/brands/"
    :showSearch="showSearch"
    searchType="brands"
    searchEndpoint="/brands/search/"
    @searchResult="updateSearchResults"
  >
    <div class="content-list p-grid">
<!--      <span class="material-icons">face</span>-->
      <BrandListItem
        v-for="brand in brands"
        :key="brand.id"
        class="p-col-12 p-md-6 p-lg-6 p-xl-3"
        :brand="brand"
      />

      <EmptyList v-if="!brands.length" title="No Brands" />
    </div>
  </app-list-page>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import AppListPage from '../commons/AppListPage.vue';
import BrandListItem from './BrandListItem.vue';
import EmptyList from '../commons/list/EmptyList.vue';

export default defineComponent({
  name: 'Brands',

  components: { AppListPage, BrandListItem, EmptyList },

  props: {
    brandOwnerView: Boolean,
    showSearch: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const brands = ref([]);

    const updateSearchResults = (list: any) => {
      if (props.limit) {
        brands.value = list.slice(0, props.limit);
      } else {
        brands.value = list;
      }
    };

    return {
      brands,
      updateSearchResults,
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
