
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import { EditUserPayload, User } from '@/data/types';
import ModalWorkingOverlay from '../../commons/modals/ModalWorkingOverlay.vue';
import PhotoGallery from '../../commons/media/PhotoGallery.vue';

export default defineComponent({
  components: {
    ModalWorkingOverlay,
    Button,
    Dialog,
    PhotoGallery,
    InputText,
    Textarea,
  },

  props: {
    show: Boolean,
    working: Boolean,
    creator: Object as PropType<User>,
  },

  emits: ['save', 'update:show'],

  setup(props, { emit }) {
    const creator = computed<User | undefined>(() => props.creator);
    const photo = ref('');
    const name = ref('');
    const description = ref('');

    watch(props, () => {
      name.value = creator.value?.name || '';
      photo.value = creator.value?.profile.photo || '';
      description.value = creator.value?.profile.description || '';
    });

    // working
    const updateProfile = () => {
      emit('save', {
        name: name.value,
        profile: {
          photo: photo.value,
          description: description.value,
        },
      } as EditUserPayload);
    };

    const open = () => emit('update:show', true);

    return {
      updateProfile,
      open,
      photo,
      name,
      description,
    };
  },
});
