
import { defineComponent, ref } from 'vue';
import { useNotifications } from '@/modules/notifications-module';
import Menu from 'primevue/menu';
import { useAuth } from '@/modules/auth-module';

export default defineComponent({
  components: { Menu },
  setup() {
    const { user } = useAuth();
    const { loading: loadingNotifications, notifications } = useNotifications();

    const menuRef = ref();
    const toggleUserMenu = (event: Event) => {
      if (menuRef.value) {
        // eslint-disable-next-line no-unused-expressions
        menuRef.value?.toggle(event);
      }
    };

    const userIsBrand = user?.value?.isBrandOwner();

    const userMenuItems = [
      {
        label: 'My Profile',
        icon: 'pi pi-fw pi-user',
        to: { name: 'my-profile' },
      },
      {
        label: 'Billing',
        icon: 'pi pi-fw pi-dollar',
        visible: userIsBrand,
      },
      {
        label: 'Payment Details',
        icon: 'pi pi-fw pi-money-bill',
        visible: userIsBrand,
      },
      // add logout at the end
      { separator: true },
      {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        to: { name: 'logout' },
      },
    ];

    return {
      loadingNotifications,
      notifications,
      toggleUserMenu,
      menuRef,
      userMenuItems,
    };
  },
});
