// eslint-disable-next-line import/no-cycle
import { ContentItem, ContentItemData, parseContentItems } from './content-types';
// eslint-disable-next-line import/no-cycle
import { Brand, BrandData } from './brand-types';

export interface EditUserPayload {
  name: string;
  profile: {
    photo: string;
    description: string;
  };
}

export interface YoutubeData {
  id: string;
  name: string;
  photo: string;
  uploadsPlaylistID?: string;
  followers?: number;
  itemsCount?: number;
}

export interface UserData {
  id: string;
  name: string;
  email?: string;
  verified: boolean;
  type: UserType;
  profile: UserProfile;

  brands: Array<{
    brand: BrandData;
    role: string;
  }>;
  contentItems: Array<ContentItemData>;

  youtube?: YoutubeData;

  // sub?

  // internal
  source?: string; // dev tools
  canOperateABrand?: boolean;
}

export interface UserProfile {
  photo: string;
  description: string;
}

export enum UserType {
  BrandOwner = 'brandOwner',
  Creator = 'creator',
  Admin = 'admin',
}

export class User {
  public readonly id: string;
  public readonly name: string;
  public readonly verified: boolean;
  public readonly profile: UserProfile;
  public readonly type: UserType;
  public readonly brand?: Brand;
  public readonly contentItems: Array<ContentItem>;
  public readonly youtube?: YoutubeData;
  private _isAdmin = false;

  public readonly authUser?: User;

  constructor(data: UserData, authUser?: User) {
    this.id = data.id;
    this.name = data.name;
    this.verified = data.verified;
    this.profile = data.profile;
    this.contentItems = parseContentItems(data.contentItems || [], this);
    this.youtube = data.youtube;

    if (data.type === UserType.Admin) {
      this._isAdmin = true;
      const actAs = localStorage.getItem('actAs') || UserType.Creator;
      data.type = actAs as UserType; // eslint-disable-line no-param-reassign
    }
    this.type = data.type;

    if (data.brands && data.brands.length) {
      const firstBrand: any = data.brands[0].brand;
      if (typeof firstBrand !== 'string') {
        this.brand = new Brand(firstBrand as BrandData, this);
      }
    }

    this.authUser = authUser;
  }

  isBrandOwner(): boolean {
    return this.type === UserType.BrandOwner;
  }

  isCreator(): boolean {
    return this.type === UserType.Creator;
  }

  isAdmin(): boolean {
    return this._isAdmin;
  }

  isMe(): boolean {
    return this.authUser?.id === this.id;
  }
}
