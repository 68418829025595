import { createApp } from 'vue';

import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';

import './assets/style/main.scss';
import 'primevue/resources/primevue.css';
import 'primeflex/primeflex.scss';
import 'primeicons/primeicons.css';

import App from './App.vue';
import router from './router';

const app = createApp(App);

app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.mount('#app');
