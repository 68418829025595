<template>
  <div class="user-type-toggle">
    brand
    <InputSwitch v-model="selection" class="switch" :disabled="disabled" />
    creator
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import InputSwitch from 'primevue/inputswitch';

export default defineComponent({
  name: 'UserTypeToggle',
  components: { InputSwitch },

  props: {
    isCreator: Boolean,
    disabled: Boolean,
  },

  emits: ['update:isCreator'],

  setup(props, { emit }) {
    const selection = computed({
      get: () => props.isCreator,
      set: (newValue) => {
        emit('update:isCreator', newValue);
      },
    });

    return {
      selection,
    };
  },
});
</script>

<style scoped lang="scss">
.user-type-toggle {
  display: flex;
  align-items: center;

  .switch {
    margin: 0 14rem;
  }
}
</style>
