
import { defineComponent, PropType } from 'vue';
import { CampaignContractData, CampaignData, CampaignDeliverableData, Perspective } from '@/data/types';
import { timeAgo } from '@/utils/globals/date-utils';
import EmptyList from '@/pages/commons/list/EmptyList.vue';
import StatusChip from '@/pages/commons/status/StatusChip.vue';

export default defineComponent({
  components: { StatusChip, EmptyList },

  props: {
    // TODO: reuse
    campaign: Object as PropType<Array<CampaignData>>,
    contract: Object as PropType<Array<CampaignContractData>>,
    selectedDeliverable: Object as PropType<Array<CampaignDeliverableData>>,
    deliverables: {
      type: Array as PropType<Array<CampaignDeliverableData>>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
    showEmptyList: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      timeAgo,
    };
  },

  methods: {
    select(item: any) {
      this.$emit('select', item);
    },
  },
});
