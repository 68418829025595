<template>
  <div class="app-page">
    <div class="header">
      <h1>{{ title }}</h1>
    </div>

    <div v-if="showSearch" class="search">
      <page-search-bar
        :perspective="perspective"
        :defaultSearchType="searchType"
        @search="performSearch"
      />
    </div>

    <div class="content">
      <transition name="fade">
        <div class="spinner-container" v-if="loading">
          <ProgressSpinner class="spinner" />
        </div>
      </transition>

      <slot v-if="!loading" />
      <!--      <transition name="fade">-->
      <!--      </transition>-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from 'vue';
import { useAPI } from '@/modules/api-module';
import { usePageList } from '@/modules/pages/page-list-module';
import ProgressSpinner from 'primevue/progressspinner';
import PageSearchBar from './search/PageSearchBar.vue';

export default defineComponent({
  name: 'AppListPage',

  components: { PageSearchBar, ProgressSpinner },

  props: {
    title: String,
    perspective: String,
    showSearch: Boolean,
    searchType: String,
    searchEndpoint: String,
    pageListEndpoint: String,
  },

  emits: ['search', 'searchResult'],

  setup(props, { emit }) {
    const loading = ref(!!props.pageListEndpoint);
    const { data: searchResults, execute: executeSearchQuery } = useAPI(props.searchEndpoint || '', false);
    const { loading: loadingPageList, list: brands } = usePageList(props.pageListEndpoint || '', !!props.pageListEndpoint);

    const performSearch = (e: any) => {
      emit('search', e);

      loading.value = true;
      executeSearchQuery({ params: { name: e.term } });
    };

    watch(loadingPageList, () => {
      loading.value = loadingPageList.value;
      emit('searchResult', brands.value);
    });

    watch(searchResults, () => {
      emit('searchResult', searchResults.value);
      loading.value = false;
    });

    return {
      loading,
      performSearch,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-search-bar {
  display: flex;

  .search {
    &-type {
    }
  }
}
</style>
