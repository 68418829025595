<template>
  <div>
    <Dialog
      header="Apply"
      :visible="show"
      class="p-col-12 p-md-6 p-xl-4"
      :modal="true"
      :style="{width: '60vw'}"
      :dismissableMask="!working"
      :closable="!working"
      @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <h1 class="p-m-0 w-100 p-text-center" style="margin-top: 30rem !important;">{{ title }}</h1>
      </template>

<!--      <pre>{{ offer }}</pre>-->

      <div class="p-mt-4 p-mb-4">
        <div class="options" :class="working && 'disabled'">
          <div class="option accept" @click="selectOption('accept')" :class="isSelected('accept') && 'selected'">
            <div class="icon"><span class="material-icons">check_circle_outline</span></div>
            <div class="title"><h3 class="text">Accept</h3></div>
            <h5>Current offer: ${{ offer?.price }}</h5>
            <div class="description">
              Accept the current Brand offer.
              This will stop the negotiating process and you will not
              be able to make a counter offer once you send the acceptance.
            </div>
          </div>

          <div
            class="option counter"
            @click="selectOption('counter')"
            :class="{
              selected: isSelected('counter'),
              disabled: lastOfferIsFinal,
            }"
          >
            <div class="icon"><span class="material-icons">forum</span></div>
            <div class="title"><h3 class="text">Counter Offer</h3></div>
            <div class="description">
              <span v-if="!lastOfferIsFinal">Make a counter offer</span>
              <span v-else>Last offer is <strong>final</strong></span>
            </div>
            <div class="p-mt-4" v-if="!lastOfferIsFinal">
<!--              @click.stop="() => {}"-->
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">$</span>
                <InputNumber
                  v-model="counterOfferPrice"
                  placeholder="Enter your offer here"
                  showButtons
                  aria-describedby="apply-price-description"
                  :min="0"
                  :disabled="working"
                />
              </div>
              <small id="apply-price-description" class="p-ml-2">* Price does not include VAT</small>

              <div class="p-mt-4">
                <Checkbox id="finalOffer" v-model="finalOffer" :binary="true" :disabled="working" />
                <label for="finalOffer" class="p-ml-2">Make this my final offer</label>
              </div>
            </div>
          </div>

          <div class="option reject" @click="selectOption('reject')" :class="isSelected('reject') && 'selected'">
            <div class="icon"><span class="material-icons">highlight_off</span></div>
            <div class="title"><h3 class="text">Reject</h3></div>
            <div class="description">
              Reject the Brand offer.
              You will not be able to apply again for this campaign once you send the rejection.
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="p-d-flex p-justify-between p-mt-4">
          <Button
            label="Cancel"
            class="p-button-link p-ml-6"
            :disabled="working"
            @click="$emit('update:show', false)"
          />

          <Button
            :label="`Send${working ? 'ing' : ''} response`"
            class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
            :icon="sendButtonIcon"
            :disabled="!selectedOption || working"
            @click="sendResponse"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  watch,
  computed,
  PropType,
} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import { NegotiationOffer } from '@/data/types/negotiation-types';

export default defineComponent({
  components: {
    Button,
    Dialog,
    Checkbox,
    InputNumber,
  },

  props: {
    show: Boolean,
    working: Boolean,
    title: {
      type: String,
      default: 'Respond to offer',
    },
    // offer: Object as PropType<NegotiationOffer | null>,
  },

  emits: ['update:show', 'respond'],

  setup(props, { emit }) {
    let payload: any = null;
    const offer = ref<NegotiationOffer | null>(null);
    const lastOfferIsFinal = computed(() => !!offer?.value?.final);

    const selectedOption = ref('');
    const isSelected = (option: string) => selectedOption.value === option;
    const selectOption = (option: string) => {
      if (props.working) {
        return;
      }
      if (option === 'counter' && lastOfferIsFinal.value) {
        return;
      }
      selectedOption.value = option;
      // if (selectedOption.value === option) {
      //   selectedOption.value = '';
      // } else {
      //   selectedOption.value = option;
      // }
    };

    // counter offer
    const finalOffer = ref(false);
    const counterOfferPrice = ref();

    watch(() => props.show, () => {
      selectedOption.value = '';
      finalOffer.value = false;
      counterOfferPrice.value = undefined;
    });

    // working
    const sendButtonIcon = computed(() => (props.working ? 'pi pi-spin pi-spinner' : ''));
    const sendResponse = () => {
      emit('respond', { // TODO: enum for negotiation
        ...payload,
        action: selectedOption.value,
        price: counterOfferPrice.value || 0,
        final: finalOffer.value,
      });
    };

    const open = (nOffer: NegotiationOffer, data: any) => {
      console.log('- open: ', nOffer, data);
      payload = data;
      offer.value = nOffer;
      emit('update:show', true);
    };

    return {
      open,
      offer,
      lastOfferIsFinal,
      selectedOption,
      isSelected,
      selectOption,
      // counter offer
      finalOffer,
      counterOfferPrice,
      sendButtonIcon,
      sendResponse,
    };
  },
});
</script>

<style scoped lang="scss">
.options {
  display: flex;

  .option {
    border: 2rem solid #E0E0E0;
    border-radius: 10rem;
    padding: 52rem 36rem;
    text-align: center;
    cursor: pointer;
    margin: 0 24rem;
    width: 33.33%;
    transition: border-color $transitionDuration ease;

    .icon .material-icons {
      font-size: 56rem;
      user-select: none;
      margin-bottom: 30rem;
      transition: color $transitionDuration ease;
    }

    .title .text {
      text-transform: uppercase;
      margin-bottom: 26rem;
      user-select: none;
    }

    .description {
      font-weight: 600;
      color: #8B8F8F;
      user-select: none;
    }

    &.disabled {
      opacity: .5;
      cursor: not-allowed;
    }

    &.selected {
      &.accept {
        border-color: #4EC361;
        .icon .material-icons {
          color: #4EC361;
        }
      }

      &.counter {
        border-color: #756C93;
        .icon .material-icons {
          color: #756C93;
        }
      }

      &.reject {
        border-color: #F54E5D;
        .icon .material-icons {
          color: #F54E5D;
        }
      }
    }
  }

  &.disabled {
    .option {
      opacity: .5;
      cursor: wait;
    }
  }
}
</style>
