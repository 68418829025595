<template>
  <div class="campaign-creation-step">
    <h4>{{ step.label }}</h4>
    <p>
      With an access to Gameinfluencer’s content creators you have a freedom to create branded content that you can
      license for use on different advertising channels (Social Media, Linear TV or both).
    </p>

    <h6>Campaign Title</h6>
    <InputText type="text" v-model="name" placeholder="Give your a campaign name..." style="width: 50%;" :class="invalidClass('name')" />

    <h6>Submissions Deadline</h6>
<!--    dateFormat="d M yy"-->
    <Calendar
      v-model="startDate"
      dateFormat="dd M yy"
      placeholder="Enter start date"
      :class="invalidClass('startDate')"
      v-tooltip="'The platform will only accept the applications before the start date'"
    />
    <Calendar
      v-model="endDate"
      dateFormat="dd M yy"
      placeholder="Enter end date"
      class="p-ml-4"
      :class="invalidClass('endDate')"
      v-tooltip="'Creators will have to deliver the submissions before this date'"
    />

    <h6>Campaign Goal</h6>
    <InputText
      type="text"
      v-model="goal"
      placeholder="Summarise what you would like to achieve with this campaign? (Eg. Brand Awareness, Installs, etc.)"
      class="w-50"
      :class="invalidClass('goal')"
    />

    <div class="next p-d-flex p-justify-end p-mt-4">
      <Button
        :label="`${edit ? 'Save' : 'Create'} and continue`"
        :loading="controller.campaign.manager.creationInProgress.value || controller.campaign.manager.updatingInProgress.value"
        class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
        icon="pi pi-angle-double-right"
        iconPos="right"
        @click="controller.next"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { useRouter } from 'vue-router';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import { CampaignData } from '@/data/types';
import { useCampaignStep, getStep, CampaignStep } from './modules/campaign-step-module';

export default defineComponent({
  components: {
    Button,
    InputText,
    Calendar,
  },

  props: {
    campaignID: String,
    campaign: Object as PropType<CampaignData>,
    edit: Boolean, // TODO: enum
  },

  setup(props) {
    const router = useRouter();
    const campaign = computed(() => props.campaign);

    const name = ref(campaign?.value?.name || '');
    const goal = ref(campaign?.value?.goal || '');
    const startDate = ref(campaign?.value?.dates.startDate ? new Date(campaign?.value?.dates.startDate) : '');
    const endDate = ref(campaign?.value?.dates.endDate ? new Date(campaign?.value?.dates.endDate) : '');

    // controller
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const createCampaign = () => controller.campaign.manager.create({
      name: name.value,
      goal: goal.value,
      startDate: startDate.value,
      endDate: endDate.value,
    })
      .then((createdCampaign?: undefined | CampaignData) => {
        if (createdCampaign) {
          router.push({
            name: 'edit-campaign--creator',
            params: { campaignID: createdCampaign.id },
          });
        }
      });

    const step = getStep(props.edit ? 'edit-campaign--details' : 'create-campaign', props.edit ? props.campaignID : '') as CampaignStep;
    const controller = useCampaignStep(
      step,
      {
        name,
        goal,
        startDate,
        endDate,
      },
      props.edit ? props.campaignID : '',
    );
    controller.setSerializer(() => ({
      name: name.value,
      goal: goal.value,
      dates: {
        startDate: startDate.value,
        endDate: endDate.value,
      },
    }));
    if (!props.edit) {
      controller.setNextStepHandler(createCampaign);
    }

    return {
      name,
      goal,
      startDate,
      endDate,
      // step control
      step,
      controller,
      ...controller.validation,
    };
  },
});
</script>
