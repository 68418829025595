<template>
  <app-list-page
    title="Admin"
    :showSearch="false"
  >
    <div>
      <h2>User Data</h2>
      <p><strong>ID</strong>: {{ user.id }}</p>
      <p><strong>Name</strong>: {{ user.name }}</p>

      <h2>Act as</h2>
      <p>
        Since you're an administrator, you have global access to all pages.
        <br>
        In order to test different display options, you can act as any other user types.
      </p>
      <p>Currently you act as: <strong>{{ user.type }}</strong></p>

      <p>Select how you'd like to impersonate yourself:</p>
      <div class="p-field-radiobutton">
        <RadioButton id="none" name="none" value="" v-model="actAs" />
        <label for="none">None</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="brand" name="brand" :value="UserType.BrandOwner" v-model="actAs" />
        <label for="brand">Brand</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="creator" name="creator" :value="UserType.Creator" v-model="actAs" />
        <label for="creator">Creator</label>
      </div>
      <Button label="Save" icon="pi pi-save" @click="saveActAs" />
    </div>
  </app-list-page>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';

import { UserType } from '@/data/types';
import { useAuth } from '@/modules/auth-module';
import AppListPage from '../commons/AppListPage.vue';

export default defineComponent({
  name: 'Admin',

  components: { AppListPage, Button, RadioButton },

  props: {},

  setup() {
    const { user } = useAuth();
    const actAs = ref(user?.value?.type || '');

    const saveActAs = () => {
      localStorage.setItem('actAs', actAs.value);
      window.location.reload();
    };

    return {
      user,
      UserType,
      actAs,
      saveActAs,
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
