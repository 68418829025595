
import { defineComponent, ref } from 'vue';
import Button from 'primevue/button';
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';

export default defineComponent({
  name: 'EmailConnect',

  components: { Button, Password, InputText },

  props: {
    connecting: String,
  },

  setup() {
    const email = ref<string>('');
    const password = ref<string>('');

    return {
      email,
      password,
    };
  },
});
