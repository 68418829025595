<template>
  <app-page :config="config" :loading="loading">
    <div>
      <EditBrandModal
        v-model:show="showEditBrandModal"
        :brand="brand"
        :working="controller.manager.updatingInProgress.value"
        @save="updateBrand"
      />

      <div class="brand" v-if="brand">
        <div class="p-grid">
          <div class="p-col-3 p-d-flex p-justify-center p-pt-6">
            <BrandPhoto :brand="brand" class="brand-photo" />
          </div>

          <div class="p-col-7">
            <div>
              <h6>Brand Slogan</h6>
              <p>{{ brand.slogan }}</p>
            </div>

            <div class="p-mt-6">
              <h6>Brand Description</h6>
              <p class="text-pre-line">{{ brand.description }}</p>
            </div>
          </div>

          <div class="actions p-col-2 p-d-flex p-justify-end p-align-start" v-if="brand.isOwnedByMe()">
            <Button
              label="Edit Brand"
              class="p-button-link action-edit"
              icon="pi pi-pencil"
              @click="showEditBrandModal = true"
            />
          </div>
        </div>
        <!-- // intro -->

        <hr class="separator">

        <h6>Brand Campaigns</h6>
        <CampaignsList :campaigns="brand.campaigns || []" class="campaigns-list" />
      </div>
    </div>
  </app-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import Button from 'primevue/button';
import AppPage from '@/pages/commons/AppPage.vue';
import EditBrandModal from '@/pages/brands/profile/EditBrandModal.vue';
import { useBrands } from '@/modules';
import { AppPageConfig, AppPageProps, Brand, Perspective } from '@/data/types';
import BrandPhoto from './BrandPhoto.vue';
import CampaignsList from '../../campaigns/CampaignsList.vue';

export default defineComponent({
  components: {
    EditBrandModal,
    AppPage,
    BrandPhoto,
    Button,
    CampaignsList,
  },

  props: {
    brandID: {
      type: String,
    },
    ...AppPageProps,
  },

  setup(props) {
    const brandID = computed(() => props.brandID || '');

    const config = ref<AppPageConfig>({
      title: 'Loading brand',
      ...props,
    });

    const controller = useBrands(props.viewPerspective as Perspective);
    const loadBrand = () => controller.manager.loadBrand(brandID.value);

    loadBrand();
    watch(controller.manager.brand, () => {
      if (controller.manager.brand.value?.name) {
        config.value.title = controller.manager.brand.value?.name;
      }
    });

    // const isMyBrand = computed(() => user?.value?.isBrandOwner() && (user?.value?.brand?.id === brandID.value));
    // edit
    const showEditBrandModal = ref(false);
    const updateBrand = (payload: Record<string, unknown>) => {
      controller.manager
        .update(brandID.value, payload)
        .then((success?: Brand) => {
          if (success) {
            showEditBrandModal.value = false;
          }
        });
    };

    return {
      config,
      controller,
      loading: controller.manager.loadingBrand,
      brand: controller.manager.brand,
      isMyBrand: controller.manager.brand.value?.isOwnedByMe(),
      // edit
      showEditBrandModal,
      updateBrand,
    };
  },
});
</script>

<style scoped lang="scss"></style>
