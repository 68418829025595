/* eslint-disable import/no-cycle */
import { User, UserData } from '@/data/types/user-types';
import { Campaign } from '@/data/types/campaign-types';

export interface BrandData {
  id: string;
  name: string;
  slogan: string;
  description: string;
  photo: string;

  creator: UserData;
  campaigns: Array<Campaign>;
}

export function parseCampaignsList(brand: BrandData, campaigns: Array<any> = []): Array<Campaign> {
  return campaigns.map((campaign: any) => ({
    ...campaign,
    brand: {
      name: brand.name,
      photo: brand.photo,
    },
  }));
}

export class Brand {
  public readonly id: string;
  public readonly name: string;
  public readonly slogan: string;
  public readonly description: string;
  public readonly photo: string;
  public readonly creator: User;
  public readonly campaigns: Array<Campaign>;

  public readonly authUser?: User;

  constructor(data: BrandData, authUser?: User) {
    this.id = data.id;
    this.name = data.name;
    this.slogan = data.slogan;
    this.description = data.description;
    this.photo = data.photo;
    this.creator = new User(data.creator);
    this.campaigns = parseCampaignsList(data, data.campaigns);

    this.authUser = authUser;
  }

  // eslint-disable-next-line class-methods-use-this
  isOwnedByMe(): boolean {
    // return false;
    return this.authUser?.id === this.creator.id;
  }
}
