<template>
  <div class="deliverables-list">
    <div
      v-for="(deliverable, index) in deliverables"
      :key="deliverable.id"
      :viewPerspective="viewPerspective"
      :setIndex="index"
      :deliverable="deliverable"
      :campaign="campaign"
      :contract="contract"
      v-bind="$attrs"
      class="deliverable-set"
      :class="selectedDeliverable?.id === deliverable.id && 'selected'"
      @click="select(deliverable)"
    >
      <div class="p-grid p-nogutter">
        <div class="p-col-6">
          <h5 class="item-name">ITEM {{ index + 1 }}</h5>
          <StatusChip :status="deliverable.status" :text="deliverable.status" />
        </div>
        <div class="p-col-6 time">
          {{ timeAgo(deliverable.updatedAt) }}
        </div>
      </div>
    </div>
    <EmptyList
      v-if="!deliverables.length && showEmptyList"
      class="p-mt-6"
      title="No Deliverables"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { CampaignContractData, CampaignData, CampaignDeliverableData, Perspective } from '@/data/types';
import { timeAgo } from '@/utils/globals/date-utils';
import EmptyList from '@/pages/commons/list/EmptyList.vue';
import StatusChip from '@/pages/commons/status/StatusChip.vue';

export default defineComponent({
  components: { StatusChip, EmptyList },

  props: {
    // TODO: reuse
    campaign: Object as PropType<Array<CampaignData>>,
    contract: Object as PropType<Array<CampaignContractData>>,
    selectedDeliverable: Object as PropType<Array<CampaignDeliverableData>>,
    deliverables: {
      type: Array as PropType<Array<CampaignDeliverableData>>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
    showEmptyList: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      timeAgo,
    };
  },

  methods: {
    select(item: any) {
      this.$emit('select', item);
    },
  },
});
</script>

<style lang="scss" scoped>
.deliverables-list {
  .deliverable-set {
    padding: 25rem 30rem;
    cursor: pointer;

    &.selected {
      background-color: #F8F9FA;
    }

    .time {
      font-size: 12rem;
      text-align: right;
    }
  }

  ::v-deep(.empty-list) {
    .no-items-icon {
      font-size: 22rem;
    }

    h2 {
      font-size: 12rem;
    }
  }
}
</style>
