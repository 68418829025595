
import { PropType } from 'vue';
import { User } from '@/data/types';
import UserPhoto from '@/pages/commons/user/UserPhoto.vue';

export default {
  name: 'CreatorListItem',
  components: { UserPhoto },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async scrapeChannel(channelID: string) {
      console.log('-- scrape disabled :(');
      // eslint-disable-next-line no-underscore-dangle
      // const videos = await HTTPService.get().get(`http://localhost:3000/v1/content/scrape/${this.item._id}/${channelID}`);
      // console.log('videos: ', videos);
    },
  },
};
