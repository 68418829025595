<template>
  <div>
    <Dialog
      header="Send License Request"
      :visible="show"
      class="p-col-12 p-md-6 p-xl-4 gi-dialog"
      :modal="true"
      :style="{width: '60vw'}"
      :dismissableMask="!working"
      :closable="!working"
      @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <div class="w-100 p-text-center">
          <h1>Send License Request</h1>
<!--          <span class="subtitle">Select the licensing options you would like to request</span>-->
        </div>
      </template>

      <div class="p-mt-4 p-mb-4 container">
        <div class="working-overlay" v-if="working">
          <div class="mask"></div>
          <ProgressSpinner class="spinner" />
        </div>
        <div class="p-grid">
          <div class="p-col-8 options">
            <div class="option">
              <div class="option-number">1</div>
              <div class="option-details">
                <h3 class="option-title">License Start and Duration</h3>
<!--                <div class="option-subtitle">-->
<!--                  Date and Duration-->
<!--                </div>-->
                <div class="content-option p-grid">
                  <div class="p-col-5">
                    <span class="material-icons p-mr-2">today</span>
                    <Calendar v-model="startDate" placeholder="Enter Start Date" />
                  </div>
                  <div class="p-col-7">
                    <div class="p-d-flex">
                      <InputNumber
                        v-model="durationPeriod"
                        placeholder="Enter Duration Period"
                        showButtons
                        :min="0"
                        :disabled="perpetualLicense"
                      />

                      <Dropdown
                        v-model="durationType"
                        :options="durationTypes"
                        optionLabel="name"
                        optionValue="value"
                        placeholder="Select Duration Type"
                        class="p-ml-2"
                        :disabled="perpetualLicense"
                      />
                    </div>
                    <div class="p-mt-2">
                      <Checkbox id="license-perpetual" v-model="perpetualLicense" :binary="true" />
                      <label for="license-perpetual" class="p-ml-2">Make License Perpetual</label>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- // start & duration -->

            <div class="option">
              <div class="option-number">2</div>
              <div class="option-details">
                <div class="p-d-flex">
                  <h3 class="option-title">Content Format</h3>
                  <Info text="Depending on your selection, creators will submit the content in the format you have requested for"
                    style="font-size: 20rem; margin-left: 5rem; margin-top: 2rem;"
                  />
                </div>
<!--                <h3 class="option-title">Content Format</h3>-->
<!--                <div class="option-subtitle">-->
<!--                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at vehicula lacus.-->
<!--                </div>-->
                <div class="option-content">
                  <RadioButton id="landscape" name="format" value="landscape" v-model="format" />
                  <label for="landscape" class="p-ml-2 p-mr-6">Landscape</label>

                  <RadioButton id="portrait" name="format" value="portrait" v-model="format" />
                  <label for="portrait" class="p-ml-2">Portrait</label>
                </div>
              </div>
            </div>
            <!-- // format -->

            <div class="option">
              <div class="option-number">3</div>
              <div class="option-details">
                <h3 class="option-title">Platform</h3>
<!--                <div class="option-subtitle">-->
<!--                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at vehicula lacus.-->
<!--                </div>-->
                <div class="option-content">
                  <Dropdown
                    v-model="platforms"
                    :options="platformsOptions"
                    optionLabel="name"
                    optionValue="value"
                    placeholder="Select Platforms"
                    class="w-100"
                  />
                </div>
              </div>
            </div>
            <!-- // platforms -->

            <div class="option">
              <div class="option-number">4</div>
              <div class="option-details">
                <h3 class="option-title">Your Offer</h3>
                <div class="option-subtitle">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at vehicula lacus.
                </div>
                <div class="option-content">
                  <div class="p-mt-0">
                    <div class="p-inputgroup"  @click.stop="() => {}">
                      <span class="p-inputgroup-addon">$</span>
                      <InputNumber
                        v-model="offerPrice"
                        placeholder="Enter your offer here"
                        showButtons
                        aria-describedby="apply-price-description"
                        :min="0"
                        :disabled="working"
                      />
                    </div>
                    <small id="apply-price-description" class="p-ml-2">* Price does not include VAT</small>

                    <div class="p-mt-3" @click.stop="() => {}">
                      <Checkbox id="finalOffer" v-model="finalOffer" :binary="true" :disabled="working" />
                      <label for="finalOffer" class="p-ml-2">Make this my final offer</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- // offer -->
          </div>
          <!-- // options -->

          <div class="p-col-4 p-p-0">
            <div class="licensing-description">
              <h3>What is content licensing?</h3>
              <p>
                Content licensing allows brands to republish or repurpose published material or content through a legal agreement.
              </p>
<!--              <p>-->
<!--                Donec eu semper mauris. Vestibulum placerat nisi in urna suscipit, ut gravida nulla bibendum. Cras-->
<!--                sodales ullamcorper sodales. Praesent elementum eleifend turpis, eu consectetur nunc placerat id.-->
<!--              </p>-->
<!--              <p>-->
<!--                Donec eu semper mauris. Vestibulum placerat nisi in urna suscipit, ut gravida nulla bibendum. Cras-->
<!--                sodales ullamcorper sodales. Praesent elementum eleifend turpis, eu consectetur nunc placerat id.-->
<!--              </p>-->

              <div class="agreement">
                <Checkbox id="agreement" v-model="acceptedAgreement" :binary="true" class="p-mt-4"/>
                <label for="agreement" class="p-ml-2">
                  I agree with the
                  <a href="#asd">Content Licensing Agreement</a>
                  of GameInfluencer.io
                </label>
              </div>
            </div>
          </div>
          <!-- // description -->
        </div>
        <!--
        <div class="options" >
          <div class="option accept" @click="selectOption('accept')" :class="isSelected('accept') && 'selected'">
            <div class="icon"><span class="material-icons">check_circle_outline</span></div>
            <div class="title"><h3 class="text">Accept</h3></div>
            <div class="description">
              Accept the current Brand offer.
              This will stop the negotiating process and you will not
              be able to make a counter offer once you send the acceptance.
            </div>
          </div>

          <div
            class="option counter"
            @click="selectOption('counter')"
            :class="{
              selected: isSelected('counter'),
              disabled: lastOfferIsFinal,
            }"
          >
            <div class="icon"><span class="material-icons">forum</span></div>
            <div class="title"><h3 class="text">Counter Offer</h3></div>
            <div class="description">
              <span v-if="!lastOfferIsFinal">Make a counter offer</span>
              <span v-else>Last offer is <strong>final</strong></span>
            </div>
            <div class="p-mt-4" v-if="!lastOfferIsFinal">
              <div class="p-inputgroup"  @click.stop="() => {}">
                <span class="p-inputgroup-addon">$</span>
                <InputNumber
                  v-model="offerPrice"
                  placeholder="Enter your offer here"
                  showButtons
                  aria-describedby="apply-price-description"
                  :min="0"
                  :disabled="working"
                />
              </div>
              <small id="apply-price-description" class="p-ml-2">* Price does not include VAT</small>

              <div class="p-mt-4" @click.stop="() => {}">
                <Checkbox id="finalOffer" v-model="finalOffer" :binary="true" :disabled="working" />
                <label for="finalOffer" class="p-ml-2">Make this my final offer</label>
              </div>
            </div>
          </div>

          <div class="option reject" @click="selectOption('reject')" :class="isSelected('reject') && 'selected'">
            <div class="icon"><span class="material-icons">highlight_off</span></div>
            <div class="title"><h3 class="text">Reject</h3></div>
            <div class="description">
              Reject the Brand offer.
              You will not be able to apply again for this campaign once you send the rejection.
            </div>
          </div>
        </div>
        -->
      </div>

      <template #footer>
        <div class="p-d-flex p-justify-between p-mt-4">
          <Button
            label="Cancel"
            class="p-button-link p-ml-6"
            :disabled="working"
            @click="$emit('update:show', false)"
          />

          <Button
            :label="`Send${working ? 'ing' : ''} request`"
            class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
            :icon="sendButtonIcon"
            :disabled="sendButtonDisabled"
            @click="sendRequest"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  watch,
  computed,
} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import RadioButton from 'primevue/radiobutton';
import InputNumber from 'primevue/inputnumber';
import ProgressSpinner from 'primevue/progressspinner';
import { LicenseRequestRequest } from '@/data/types';
import Info from '@/components/Info.vue';

export default defineComponent({
  components: {
    Button,
    Dialog,
    Dropdown,
    Calendar,
    Checkbox,
    InputNumber,
    RadioButton,
    ProgressSpinner,
    Info,
  },

  props: {
    show: Boolean,
    working: Boolean,
  },

  emits: ['request'],

  setup(props, { emit }) {
    watch(() => props.show, () => {
      // selectedOption.value = ''; // FIXME: clear modal after closing
    });

    const acceptedAgreement = ref(false);

    // options

    // 1. date & duration
    const startDate = ref('');
    const durationPeriod = ref<number | undefined>(undefined);
    const durationType = ref('');
    const durationTypes = [
      { name: 'Day(s)', value: 'day' },
      { name: 'Week(s)', value: 'week' },
      { name: 'Month(s)', value: 'month' },
      { name: 'Year(s)', value: 'year' },
    ];
    const perpetualLicense = ref(false);

    // 2. format
    const format = ref('');

    // 3. platforms
    const platforms = ref('');
    const platformsOptions = [
      { name: 'Social Media', value: 'social-media' },
      { name: 'Linear TV', value: 'linear-tv' },
      { name: 'Social Media + Linear TV', value: 'social-media+linear-tv' },
    ];

    // 4. offer
    const finalOffer = ref(false);
    const offerPrice = ref();

    // working
    const sendButtonIcon = computed(() => (props.working ? 'pi pi-spin pi-spinner' : ''));
    const sendRequest = () => {
      emit('request', {
        startDate: startDate.value,
        duration: {
          period: durationPeriod.value,
          type: durationType.value,
          perpetual: perpetualLicense.value,
        },
        format: format.value,
        platforms: platforms.value,
        price: offerPrice.value,
        final: finalOffer.value,
      } as LicenseRequestRequest);
    };
    const sendButtonDisabled = computed(() => {
      if (props.working || !acceptedAgreement.value) {
        return true;
      }

      if (!startDate.value || !format.value || !platforms.value || !offerPrice.value) {
        return true;
      }

      if (perpetualLicense.value) {
        return false;
      }

      if (!durationPeriod.value || !durationType.value) {
        return true;
      }

      return false;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fake = () => {
      startDate.value = new Date().toString();
      durationPeriod.value = 3;
      durationType.value = 'week';
      perpetualLicense.value = false;
      format.value = 'portrait';
      platforms.value = 'social-media+linear-tv';
      offerPrice.value = 124651;
      finalOffer.value = false;
      acceptedAgreement.value = true;
    };
    // fake();

    return {
      sendRequest,
      sendButtonIcon,
      sendButtonDisabled,
      acceptedAgreement,

      // options

      // 1. date & duration
      startDate,
      durationPeriod,
      durationType,
      durationTypes,
      perpetualLicense,

      // 2. format
      format,

      // 3. platforms
      platforms,
      platformsOptions,

      // 4. offer
      finalOffer,
      offerPrice,
    };
  },
});
</script>

<style scoped lang="scss">
.container {
  position: relative;

  .working-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;

    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: .5;
    }

    .spinner {
      width: 30px;
      height: 30px;
    }
  }
}
.options {
  padding: 8rem 32rem;

  .option {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 28rem;
    }

    &-number {
      width: 30rem;
      height: 30rem;
      margin-right: 14rem;

      background: $giRed;
      border: 1rem solid $giRed;
      box-shadow: 3rem 5rem 15rem rgba(0, 0, 0, 0.15);
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      color: #fff;
      font-size: 16rem;
      font-weight: 600;
    }

    &-title {
      color: $giDarkGrey;
      margin-bottom: 8rem;
    }
    &-subtitle {
      color: #686E6E;
      margin-bottom: 12rem;
    }
  }
}
.licensing-description {
  background: #F2F2F2;
  border-radius: 8rem;
  padding: 32rem 26rem;
  margin-right: 32rem;

  p {
    font-size: 14rem;
    line-height: 17rem;
  }

  .agreement {
    font-size: 12rem;
    font-weight: 500;

    a {
      color: $giRed;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
