
import { defineComponent } from 'vue';

import { useAPI, usePage, useCampaigns } from '@/modules';
import {
  AppPageConfig,
  AppPageProps,
  CampaignData,
  CampaignInvitationData,
} from '@/data/types';
import AppCampaignSubpage from '../../commons/AppCampaignSubpage.vue';
import InvitationsList from './InvitationsList.vue';

export default defineComponent({
  components: { InvitationsList, AppCampaignSubpage },

  props: AppPageProps,

  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Campaign Invitations',
      // list: true,
      ...props,
    });

    const page = useCampaigns(config.value.viewPerspective);
    page.invitations.loadInvitations();

    // delete
    const { execute: sendDeleteInvitation } = useAPI('', { method: 'delete' }, false);
    const deleteInvitation = ({ campaign, invitation }: { campaign: CampaignData; invitation: CampaignInvitationData }) => {
      sendDeleteInvitation({
        url: `/campaigns/invitations/${campaign.id}/${invitation.id}`,
      })
        .then(() => {
          console.log('invitation deleted!');
          page.invitations.loadInvitations();
        });
    };

    // const filterByStatus = (filters: Array<string>) => {
    //   console.log('--- filters: ', filters);
    //   if (!filters || !filters.length) {
    //     campaigns.value = loadedCampaigns.value || [];
    //     return;
    //   }
    //   const filteredCampaigns: any = (loadedCampaigns.value || [])
    //     .map((campaign: CampaignData) => {
    //       const invitations: Array<CampaignInvitationData> = (campaign.invitations || [])
    //         .filter((inv: CampaignInvitationData) => filters.indexOf(inv.status) > -1);
    //
    //       if (invitations.length) {
    //         return {
    //           ...campaign,
    //           invitations,
    //         };
    //       }
    //       return null;
    //     })
    //     .filter((i: any) => i !== null);
    //   campaigns.value = filteredCampaigns;
    // };

    return {
      config,
      // dataLoaded,
      loading: page.invitations.loading,
      campaigns: page.invitations.invitations,
      associatedCampaigns: page.invitations.associatedCampaigns,
      filterByCampaignID: page.invitations.filterByCampaignID,
      deleteInvitation,
    };
  },
});
