<template>
  <div class="license-request-content-item p-grid p-nogutter">
    <div class="p-col-4">
      <ContentItemsListItem :item="item" />
    </div>

    <div class="p-col-8 content">
      <div class="requests-list-header p-grid p-nogutter">
        <div class="p-col-3"><h6>from</h6></div>
        <div class="p-col-2"><h6>price</h6></div>
        <div class="p-col-2"><h6>start date</h6></div>
        <div class="p-col-2"><h6>status</h6></div>
        <div class="p-col-3"></div>
      </div>
      <div
        v-for="request in item.licenseRequests"
        :key="request.id"
        class="requests-list-item p-grid p-nogutter"
      >
<!--        {{ request }}-->
        <div class="p-col-3"><BrandShortPreview :brand="request.brand" /></div>
        <div class="p-col-2"><h5>${{ getRequestLastOffer(request).price }}</h5></div>
        <div class="p-col-2"><h5>{{ formatDate(request.startDate) }}</h5></div>
        <div class="p-col-2"><StatusChip :status="getRequestLastOffer(request).status" /></div>
        <div class="p-col-3">
          <LicenseRequestActions
            :item="item"
            :request="request"
            :viewPerspective="viewPerspective"
            v-bind="$attrs"
          />
        </div>
        <!-- // actions -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ContentItem, Perspective } from '@/data/types';
import { formatDate } from '@/utils/globals/date-utils';
import StatusChip from '@/pages/commons/status/StatusChip.vue';
import ContentItemsListItem from '@/pages/content/ContentItemsListItem.vue';
import BrandShortPreview from '@/pages/commons/brand/BrandShortPreview.vue';
import LicenseRequestActions from '@/pages/content/license-requests/LicenseRequestActions.vue';

export default defineComponent({
  components: {
    LicenseRequestActions,
    BrandShortPreview,
    StatusChip,
    ContentItemsListItem,
  },

  props: {
    item: {
      type: Object as PropType<ContentItem>,
      required: true,
    },
    viewPerspective: String as PropType<Perspective>,
  },

  setup() {
    const getRequestLastOffer = (request: any) => request.offers[request.offers.length - 1];

    return {
      getRequestLastOffer,
      formatDate,
    };
  },
});
</script>

<style lang="scss" scoped>
.license-request-content-item {
  .content {
    padding-left: 30rem;

    .requests-list-item {
      &:not(:last-child) {
        margin-bottom: 30rem;
      }
      & div {
        display: flex;
        align-items: center;

        h5 {
          margin: 0;
        }
      }
    }
  }
}
</style>
