<template>
  <app-page :config="config" :loading="loading">
    <div>
      <div v-if="private && false" class="p-mb-6">
        <Button
          label="Sync Youtube Videos"
          class="p-button-danger gi-button p-text-uppercase"
          :loading="syncing"
          @click="sync"
        />
      </div>
      <ContentItemsList v-if="!loading" :items="contentItems" />
    </div>
  </app-page>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import Button from 'primevue/button';

import { usePage } from '@/modules';
import { useContentItems } from '@/modules/content-items/content-items-module';
import { AppPageConfig, AppPageProps } from '@/data/types';
import AppPage from '../commons/AppPage.vue';
import ContentItemsList from './ContentItemsList.vue';

export default defineComponent({
  name: 'ContentItems',

  components: { AppPage, ContentItemsList, Button },

  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    ...AppPageProps,
    private: Boolean,
    limit: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Content Items',
      // list: true,
      ...props,
    });

    const page = useContentItems(config.value.viewPerspective);
    const loadPageData = () => page.list.loadContentItems(props.private, props.limit);

    loadPageData();
    watch(() => props.private, loadPageData);

    return {
      config,
      page,
      loading: page.list.loading,
      contentItems: page.list.items,
      sync: page.manager.sync,
      syncing: page.manager.syncingInProgress,
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
