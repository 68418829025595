<template>
  <div>
    <Dialog
      :visible="show"
      class="welcome-modal p-col-12 p-md-6 p-xl-4"
      :modal="true"
      :style="{ width: '60vw' }"
      :dismissableMask="true"
      :closable="true"
      @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <div> </div>
      </template>

      <div class="p-d-flex p-flex-column p-align-center">
        <h1>Hooray!</h1>
        <div class="splash">
          <img class="splash" alt="game creative character" src="../../../assets/images/welcome/welcome-splash.png">
        </div>

        <div class="welcome">
          <h1>Welcome, {{ user.name }}!</h1>
        </div>

        <div class="welcome-creator w-75 p-text-center" v-if="user.isCreator()">
          Welcome, <strong>creator</strong> to G.io! Thank you for signing up.
          The video tutorial will guide you through the platform. Sit tight and Game on!
        </div>

        <div class="welcome-creator w-75 p-text-center" v-if="user.isBrandOwner()">
          Welcome, <strong>brand</strong> to G.io! Thank you for signing up.
          The video tutorial will guide you through the platform. Sit tight and Game on!
        </div>
      </div>

      <template #footer>
        <div class="p-d-flex p-justify-between p-mt-4">
          <Button
            label="Cancel"
            class="p-button-link p-ml-6"
            @click="$emit('update:show', false)"
          />

          <Button
            label="Get Started"
            class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
            @click="$emit('update:show', false)"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useAuth } from '@/modules';

export default defineComponent({
  components: {
    Button,
    Dialog,
  },

  props: {
    show: Boolean,
  },

  emits: ['update:show'],

  setup(props, { emit }) {
    const { user } = useAuth();

    const open = () => {
      emit('update:show', true);
    };

    return {
      open,
      user,
    };
  },
});
</script>

<style lang="scss">
.welcome-modal {
  .p-dialog-header, .p-dialog-content, .p-dialog-footer {
    background: #FBFBFB;
  }
}
</style>
