
import { defineComponent, markRaw, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Terms from '@/pages/static/Terms.vue';
import Privacy from '@/pages/static/Privacy.vue';
import AppPage from '@/pages/commons/AppPage.vue';
import UserShortPreview from '@/pages/commons/user/UserShortPreview.vue';
import ModalWorkingOverlay from '@/pages/commons/modals/ModalWorkingOverlay.vue';
import ModalPage from '@/pages/utility/ModalPage.vue';
import { useStepValidation } from '@/pages/campaigns/create/step-validation-module';
import { useAuth, useToastModule, useUsers } from '@/modules';
import { AppPageConfig, AppPageProps, Perspective, User } from '@/data/types';
import PhotoGallery from '../../commons/media/PhotoGallery.vue';

export default defineComponent({
  components: {
    UserShortPreview,
    AppPage,
    Button,
    Checkbox,
    InputText,
    PhotoGallery,
    ModalPage,
    ModalWorkingOverlay,
  },

  props: AppPageProps,

  setup(props) {
    const router = useRouter();
    const toasts = useToastModule();
    const { user, login } = useAuth();
    const perspective = props.viewPerspective || 'creator';

    const config = ref<AppPageConfig>({
      title: `${perspective} Info`,
      ...props,
    });

    // terms & conditions
    const agreed = ref(false);
    const modalPageRef = ref();
    const openModal = (page: string): void => {
      if (modalPageRef.value) {
        if (page === 'terms') {
          modalPageRef.value.open({
            title: 'Terms & Conditions',
            component: markRaw(Terms),
            payload: page,
          });
        } else if (page === 'privacy') {
          modalPageRef.value.open({
            title: 'Privacy Policy',
            component: markRaw(Privacy),
            payload: page,
          });
        }
      }
    };

    // common
    const name = ref('');
    const photo = ref('');

    // brand
    const slogan = ref('');

    // creator

    // validation
    const fields: Record<string, Ref> = { name, agreed };
    if (perspective === 'brand') {
      // eslint-disable-next-line vue/no-ref-as-operand
      fields.slogan = slogan;
    } else {
      name.value = user?.value?.name || '';
    }
    const validation = useStepValidation(fields);
    const controller = useUsers();

    const register = () => {
      if (validation.validate()) {
        const payload: any = {
          name: name.value,
        };
        if (perspective === 'brand') {
          payload.photo = photo.value;
          payload.slogan = slogan.value;
        }
        controller.manager.verify(perspective as Perspective, { [perspective]: payload })
          .then((success?: User | undefined) => {
            if (success) {
              localStorage.setItem('show-welcome', 'true');
              login(success);
              router.push({ name: 'dashboard' });
            }
          });
      }
    };

    return {
      config,
      user,
      loading: controller.manager.verifying,
      register,
      // common
      name,
      photo,
      // brand
      slogan,
      // creator
      // validation
      validation,
      // terms & conditions
      agreed,
      modalPageRef,
      openModal,
    };
  },
});
