<template>
  <app-page :config="config" :loading="loading">
    <div>
      <Dialog
        header="Campaign Invitation"
        v-model:visible="showCampaignInvitationModal"
        class="login-card p-col-12 p-md-6 p-xl-4 gi-dialog"
        :closable="!inviting"
        :dismissableMask="!inviting"
        :modal="true"
      >
        <div class="campaigns-list p-mt-6 p-mb-4">
          <!--        TODO: check if brand has campaign and if not - show message here-->
          <p>Select the campaign you'd like to invite <strong>{{ creator.name }}</strong> to:</p>
          <div class="p-d-flex p-justify-center">
            <Dropdown
              v-model="selectedCampaignID"
              :options="campaignInviteDropdownOptions"
              :filter="true"
              :showClear="true"
              optionLabel="name"
              optionValue="id"
              optionDisabled="disabled"
              placeholder="Select campaign"
              emptyFilterMessage="No campaign found"
              style="width: 50%;"
            />
          </div>
        </div>
        <template #footer>
          <div class="p-d-flex p-jc-between p-mt-4">
            <Button
              label="Cancel"
              class="p-button-link"
              @click="showCampaignInvitationModal = false"
            />
            <Button
              label="Send Invitation"
              class="gi-button"
              :loading="inviting"
              icon="pi pi-check"
              :disabled="!selectedCampaignID || inviting"
              @click="sendInvite"
            />
          </div>
        </template>
      </Dialog>

      <EditCreatorModal
        v-model:show="showEditModal"
        :creator="creator"
        :working="controller.manager.updatingInProgress.value"
        @save="updateCreator"
      />

      <div v-if="creator" class="creator-profile">
        <div class="top p-grid">

          <div class="p-col-12 p-xl-10 p-grid p-nogutter">
            <div class="p-d-flex p-justify-center p-pt-0 p-pt-xl-6 p-col-12 p-xl-4">
              <UserPhoto :user="creator" class="creator-photo" />
            </div>

            <div class="p-pt-4 p-pb-4 p-justify-center p-d-flex p-d-xl-none p-col-12">
              <Button
                v-if="creator.isMe()"
                label="Edit Profile"
                class="p-button-link action-button"
                icon="pi pi-pencil"
                @click="showEditModal = true"
              />

              <Button
                v-if="user.isBrandOwner()"
                label="Invite to Campaign"
                class="action-button p-button-danger"
                icon="pi pi-user-plus"
                @click="showCampaignInvitationModal = true"
              />
            </div>

            <div class="name p-col-12 p-xl-8">
              <h6>Description</h6>
              <p class="text-pre-line">{{ creator.profile.description }}</p>
            </div>
          </div>

          <div class="actions p-d-none p-d-xl-block p-col-2">
            <Button
              v-if="creator.isMe()"
              label="Edit Profile"
              class="p-button-link action-button"
              icon="pi pi-pencil"
              @click="showEditModal = true"
            />

            <Button
              v-if="user.isBrandOwner()"
              label="Invite to Campaign"
              class="action-button p-button-danger"
              icon="pi pi-user-plus"
              @click="showCampaignInvitationModal = true"
            />
          </div>
        </div>
        <!-- // creator profile -->

        <hr class="separator">

        <h6>Content Items</h6>
        <ContentItemsList :items="creator.contentItems" />
      </div>
    </div>
  </app-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import AppPage from '@/pages/commons/AppPage.vue';
import { useAuth, useCreators, useCampaigns, useToastModule } from '@/modules';
import UserPhoto from '@/pages/commons/user/UserPhoto.vue';
import ContentItemsList from '@/pages/content/ContentItemsList.vue';
import {
  AppPageConfig,
  AppPageProps,
  CampaignData,
  CampaignInvitationData, CampaignStatus,
  EditUserPayload, User,
} from '@/data/types';
import EditCreatorModal from '@/pages/creators/profile/EditCreatorModal.vue';

export default defineComponent({
  components: {
    EditCreatorModal,
    Button,
    Dialog,
    Dropdown,
    ContentItemsList,
    UserPhoto,
    AppPage,
  },

  props: {
    creatorID: {
      type: String,
    },
    ...AppPageProps,
  },

  setup(props) {
    const { user } = useAuth();
    const toast = useToastModule();

    const creatorID = computed(() => props.creatorID || '');

    const config = ref<AppPageConfig>({
      title: 'Loading creator',
      ...props,
    });

    const controller = useCreators(config.value.viewPerspective);
    const loadCreator = () => controller.manager.load(creatorID.value);

    loadCreator();
    watch(controller.manager.creator, () => {
      if (controller.manager.creator.value?.name) {
        config.value.title = controller.manager.creator.value?.name;
      }
    });

    // edit
    // const isMyBrand = computed(() => user?.value?.isBrandOwner() && (user?.value?.brand?.id === brandID.value));
    const showEditModal = ref(false);
    const updateCreator = (payload: EditUserPayload) => {
      controller.manager
        .update(creatorID.value, payload)
        .then((success?: User) => {
          if (success) {
            showEditModal.value = false;
          }
        });
    };
    // < edit

    // invite to a campaign
    interface CampaignDropdownOption {
      id: string;
      name: string;
      optionDisabled: boolean;
    }
    const campaigns = useCampaigns(config.value.viewPerspective);
    const showCampaignInvitationModal = ref(false);
    const selectedCampaignID = ref<string>('');
    const campaignInviteDropdownOptions = ref<Array<CampaignDropdownOption>>();
    const updateCampaignsList = () => {
      campaigns.list
        .loadCampaigns(true)
        .then((data?: Array<CampaignData>) => {
          if (!data) {
            return;
          }
          campaignInviteDropdownOptions.value = data
            .filter((campaign) => campaign.status !== CampaignStatus.Draft)
            .map((campaign) => { // TODO: watcher?
              const result: CampaignDropdownOption = {
                id: campaign.id,
                name: campaign.name,
                optionDisabled: false,
              };
              const invitation: CampaignInvitationData | undefined = (campaign.invitations || [])
                .find((i: CampaignInvitationData) => i.creator.id === creatorID.value);

              if (invitation) {
                result.name = `${result.name} (already invited)`;
                result.optionDisabled = true;
              }
              return result;
            });
        });
    };
    const sendInvite = () => {
      if (selectedCampaignID.value && creatorID.value) {
        campaigns.invitations.invite(selectedCampaignID.value, creatorID.value as string);
      }
    };
    watch(campaigns.invitations.inviteSuccess, () => {
      if (campaigns.invitations.inviteSuccess.value) {
        toast.showSuccess(`User ${controller.manager.creator.value?.name} invited successfully!`);
        showCampaignInvitationModal.value = false;
        updateCampaignsList();
      }
    });
    watch(campaigns.invitations.inviteError, toast.handleRequestError);

    if (user?.value?.isBrandOwner()) {
      updateCampaignsList();
    }
    // < invite to a campaign

    return {
      config,
      controller,
      user,
      loading: controller.manager.loading,
      creator: controller.manager.creator,
      // campaign invitation
      showCampaignInvitationModal,
      selectedCampaignID,
      inviting: campaigns.invitations.inviting,
      campaignInviteDropdownOptions,
      sendInvite,
      // isMyBrand: controller.manager.brand.value?.isOwnedByMe(),
      // edit
      showEditModal,
      updateCreator,
    };
  },
});
</script>

<style scoped lang="scss">
.creator-profile {

  .top {
    display: flex;

    .creator-photo ::v-deep(.default) {
      font-size: 64rem;
    }

    .actions {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      .action-button {
        align-self: flex-end;
      }
    }
  }

  .slogan {
    margin-top: 90rem;
  }

  .description {
    margin-top: 50rem;
  }

  .slogan p, .description p {
    color: #888E95;
  }
}
</style>
