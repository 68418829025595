
import { computed, defineComponent, PropType, ref } from 'vue';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import MultiSelect from 'primevue/multiselect';
import { CampaignData } from '@/data/types';
import { CampaignStep, getStep, useCampaignStep } from '@/pages/campaigns/create/modules/campaign-step-module';

export default defineComponent({
  name: 'CampaignBudgetRightsStep',

  components: {
    Button,
    Dropdown,
    InputNumber,
    MultiSelect,
  },

  props: {
    campaignID: String,
    campaign: Object as PropType<CampaignData>,
    edit: Boolean, // TODO: enum
  },

  setup(props) {
    const campaign = computed<CampaignData | undefined>(() => props.campaign);

    const budget = ref(campaign?.value?.budget || undefined);
    const fixedBudget = ref(campaign?.value?.budget === 0 ? 'open' : 'fixed');
    const platforms = ref(campaign?.value?.rights.platforms || '');
    const duration = ref(campaign?.value?.rights.duration || '');
    const regions = ref(campaign?.value?.rights.regions || '');

    const fixedBudgetOptions = [
      { name: 'Fixed Budget', value: 'fixed' },
      { name: 'Open Budget', value: 'open' },
    ];
    const platformsOptions = [
      { name: 'Social Media', value: 'social-media' },
      { name: 'Linear TV', value: 'linear-tv' },
      { name: 'Social Media + Linear TV', value: 'social-media+linear-tv' },
    ];
    const durationOptions = [
      { name: '1 Month', value: '1m' },
      { name: '3 Months', value: '3m' },
      { name: '6 Months', value: '6m' },
      { name: '12 Months', value: '12m' },
      { name: 'Indefinite', value: 'indefinite' },
    ];
    const regionsOptions = [
      { name: 'EMEA', value: 'emea' },
      { name: 'APAC', value: 'apac' },
      { name: 'North America', value: 'north-america' },
      { name: 'Latin America', value: 'latin-america' },
    ];

    const step = getStep('edit-campaign--budget-rights', props.campaignID) as CampaignStep;
    const controller = useCampaignStep(
      step,
      {
        platforms,
        duration,
        regions,
      },
      props.campaignID,
    );
    controller.setSerializer(() => ({
      budget: fixedBudget.value === 'open' ? 0 : budget.value,
      rights: {
        platforms: platforms.value,
        duration: duration.value,
        regions: regions.value,
      },
    }));

    return {
      budget,
      fixedBudget,
      fixedBudgetOptions,
      platforms,
      platformsOptions,
      duration,
      durationOptions,
      regions,
      regionsOptions,

      // step control
      step,
      controller,
      ...controller.validation,
    };
  },
});
