<template>
  <div class="creator-content-items">
    <transition name="fade">
      <ContentItemsList :items="creator.contentItems" />
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { User } from '../../../../data/types';
import ContentItemsList from '../../../content/ContentItemsList.vue';

export default defineComponent({
  name: 'BrandProfile',

  components: { ContentItemsList },

  props: {
    creator: {
      type: Object as PropType<User>,
    },
  },

  setup() {
    return {
    };
  },
});
</script>

<style lang="scss" scoped>
.creator-content-items {
  margin-top: 60rem;

  .content-items-list {
    margin-top: 40rem;
  }
}
</style>
