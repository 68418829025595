
import { defineComponent, ref, markRaw } from 'vue';
import { AppPageProps, Perspective } from '@/data/types';
import About from './static/About.vue';
import ModalPage from './utility/ModalPage.vue';
import Creators from './creators/Creators.vue';
import ContentItems from './content/ContentItems.vue';
import Campaigns from './campaigns/Campaigns.vue';
import Brands from './brands/Brands.vue';

export default defineComponent({
  name: 'Home',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    Brands,
    Campaigns,
    ContentItems,
    Creators,
    ModalPage,
  },

  props: AppPageProps,

  setup() {
    const modalPage = ref();
    const openModal = () => {
      if (modalPage.value) {
        modalPage.value.open({
          title: 'About',
          component: markRaw(About),
        });
      }
    };
    return {
      About,
      modalPage,
      openModal,
      Perspective,
    };
  },
});
