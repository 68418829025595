
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import { useToast } from 'primevue/usetoast';
import { useAuth } from '@/modules/auth-module';
import { useAPI } from '@/modules/api-module';
import { APIError } from '@/utils/globals/error-utils';
import { User, CampaignData, CampaignInvitationData } from '../../../data/types';

import UserPhoto from '../../commons/user/UserPhoto.vue';

export default defineComponent({
  name: 'CreatorProfile',

  components: {
    UserPhoto,
    Button,
    Dialog,
    Dropdown,
  },

  props: {
    creator: {
      type: Object as PropType<User>,
    },
  },

  setup(props) {
    const { user } = useAuth();

    const showCampaignInvitationModal = ref(false);
    const selectedCampaignID = ref(null);
    const campaignInviteDropdownOptions = ref<Array<any>>();

    const { data: brandCampaigns, execute: getCampaigns } = useAPI<Array<CampaignData>>(`/campaigns/brand/${user?.value?.brand?.id}`, {
      parseResponse: (data: Array<CampaignData>) => {
        campaignInviteDropdownOptions.value = data.map((campaign) => { // TODO: watcher?
          const result: any = {
            id: campaign.id,
            name: campaign.name,
            disabled: false,
          };
          const invitation: CampaignInvitationData | undefined = (campaign.invitations || [])
            .find((i: CampaignInvitationData) => i.creator.id === props.creator?.id);

          if (invitation) {
            result.name = `${result.name} (already invited)`;
            result.optionDisabled = true;
          }
          return result;
        });

        console.log('---- list: ', campaignInviteDropdownOptions.value);
        return data;
      },
    }, false);

    if (user?.value?.isBrandOwner()) {
      getCampaigns();
    }

    const toast = useToast();
    const {
      data: sendInviteSuccess,
      error: sendInviteError,
      loading: sendingInvite,
      execute: callSendInvite,
    } = useAPI('', false);
    const sendInviteButtonIcon = computed(() => (sendingInvite.value ? 'pi pi-spin pi-spinner' : 'pi pi-check'));
    const sendInvite = () => {
      callSendInvite({
        url: `/campaigns/invitations/${selectedCampaignID.value}/${props.creator?.id}`,
        method: 'POST',
      });
    };

    watch(sendInviteError, (err?: APIError) => {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    });

    watch(sendInviteSuccess, () => {
      if (sendInviteSuccess.value) {
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: `User ${props.creator?.name} invited successfully!`,
          life: 3000,
        });
        showCampaignInvitationModal.value = false;
        getCampaigns();
      }
    });

    watch(showCampaignInvitationModal, () => {
      if (!showCampaignInvitationModal.value) {
        selectedCampaignID.value = null;
      }
    });

    return {
      user,
      showCampaignInvitationModal,
      campaignInviteDropdownOptions,
      brandCampaigns,
      selectedCampaignID,
      sendInvite,
      sendInviteButtonIcon,
      sendingInvite,
    };
  },
});
