<template>
  <div class="brand-list-item">
    <div class="box">
      <BrandPhoto :brand="brand" />
<!--      <div class="avatar">-->
<!--        <img v-if="item.photo" :src="item.photo" alt="" referrerpolicy="no-referrer" />-->
<!--        <img v-else src="/images/brand.svg" alt="" referrerpolicy="no-referrer" />-->
<!--      </div>-->
      <div class="info">
        <div class="name">
          {{ brand.name }}
        </div>
        <div class="stats">
<!--          <div class="views">{{ item.youtube?.stats.views }} views</div>-->
<!--          <div class="subs">{{ item.youtube?.stats.subscribers }} subs</div>-->
        </div>
      </div>
    </div>
    <div class="link">
      <router-link :to="{ name: 'brand', params: { brandID: brand.id } }">
        Go to brand <i class="pi pi-angle-right"></i>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Brand } from '@/data/types';
import BrandPhoto from './profile/BrandPhoto.vue';

export default defineComponent({
  name: 'BrandListItem',

  components: { BrandPhoto },

  props: {
    brand: {
      type: Object as PropType<Brand>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.brand-list-item {
  margin-bottom: 40px;

  .box {
    background: #F8F8F8;
    border: 1px solid #EEEEEE;
    border-radius: 15px;
    display: flex;
    padding: 14px;
    position: relative;
    overflow: hidden;

    .brand-photo {
      width: 80rem;
      height: 80rem;
      flex-shrink: 0;
      //box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
      margin-right: 28px;
    }

    .info {
      width: 100%;
      z-index: 5;

      .name {
        @include font-montserrat();
        font-weight: bold;
        font-size: 14px;

        height: 34px;
      }

      .stats {
        display: flex;
        width: 100%;

        .views, .subs {
          flex: 1;
          @include font-montserrat();
          font-size: 12px;
          color: #181818;
        }
      }
    }
  }

  .link a {
    @include font-montserrat();
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    padding-right: 14px;
    margin-top: 6px;
    color: #181818;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    transition: opacity .3s ease;

    &:hover {
      opacity: .5;
    }
  }
}
</style>
