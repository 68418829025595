<template>
  <div class="photo-gallery p-grid p-nogutter">
    <div class="current p-col-5">
      <h6>Current Photo</h6>
      <EntityPhoto :url="selectedPhoto?.url" class="current-photo" :type="type" />
    </div>

    <div class="available p-col-7">
      <h6>Select</h6>
      <div class="available-list">
        <div
          v-for="photo in availablePhotos"
          :key="photo.url"
          class="available-list-item"
          :class="{
            'selected': selectedPhoto.url === photo.url,
            [type]: true,
          }"
          @click="selectPhoto(photo)"
        >
          <EntityPhoto :type="type" :url="photo.url" />
        </div>
        <div v-if="allowAdd" class="available-list-item" :class="{ [type]: true }">
          <EntityPhoto :type="type" action="add" @click="showAddInput = true"/>
        </div>
      </div>

      <div v-if="allowAdd" class="add p-mt-6">
        <div v-if="selectedPhoto.custom">
          <Button
            label="Remove"
            class="p-button-text p-button-secondary gi-button p-text-uppercase"
            icon="pi pi-times"
            @click="removeCustomPhoto"
          />
        </div>
        <div v-if="showAddInput">
          <InputText type="text" v-model="customURL" placeholder="Paste photo url here" style="width: 50%;" />
          <Button
            label="Add"
            class="p-button-text p-button-secondary gi-button p-text-uppercase p-ml-3"
            icon="pi pi-plus-circle"
            @click="addCustomPhoto"
          />
          <Button
            label="Cancel"
            class="p-button-text p-button-secondary gi-button p-text-uppercase"
            icon="pi pi-times"
            @click="showAddInput = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import EntityPhoto from '@/pages/commons/media/EntityPhoto.vue';

export default defineComponent({
  components: { EntityPhoto, Button, InputText },
  props: {
    current: {
      type: String,
      default: '',
    },
    photo: {
      type: String,
    },
    type: {
      type: String as PropType<'brand' | 'user' | 'campaign'>,
      required: true,
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    interface Photo {
      url: string;
      custom?: boolean;
    }
    const availablePhotos = ref<Array<Photo>>([{ url: props.photo || '' }]);
    const selectedPhoto = ref<Photo>({ url: props.photo || '' });

    const customURL = ref('');
    const showAddInput = ref(false);
    const addCustomPhoto = () => {
      if (customURL.value) {
        const photo: Photo = { url: customURL.value, custom: true };
        availablePhotos.value.push(photo);
        showAddInput.value = false;
        customURL.value = '';
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        selectPhoto(photo);
      }
    };
    const removeCustomPhoto = () => {
      if (!selectedPhoto.value.custom) {
        return;
      }
      const index = availablePhotos.value.findIndex((i) => i.url === selectedPhoto.value.url);
      if (index > -1) {
        availablePhotos.value.splice(index, 1);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        selectPhoto(availablePhotos.value[availablePhotos.value.length - 1]);
      }
    };

    const selectPhoto = (photo: any) => {
      selectedPhoto.value = photo;
      showAddInput.value = false;

      emit('selected', selectedPhoto.value.url);
      emit('update:photo', selectedPhoto.value.url);
      emit('update:modelValue', selectedPhoto.value.url);
    };

    return {
      availablePhotos,
      selectedPhoto,
      customURL,
      showAddInput,
      addCustomPhoto,
      removeCustomPhoto,
      selectPhoto,
    };
  },
});
</script>

<style scoped lang="scss">
.photo-gallery {
  display: flex;

  .current {
    &-photo {
      border: 4px solid #E0010B;
      //filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.15));

      width: 170rem;
      height: 170rem;

      &.campaign {
        width: 234rem;
        height: 182rem;
      }
    }
  }

  .available {
    &-list {
      display: flex;
      flex-wrap: wrap;

      &-item {
        border: 2px solid transparent;

        cursor: pointer;
        transition: opacity .3s ease;

        &.selected .profile-photo {
          border: 2px solid #E0010B;
        }

        &:not(:first-child) {
          margin-left: 20rem;
        }

        &:hover {
          opacity: .75;
        }
      }

      &-item {
        width: 56px;
        height: 56px;
      }

      &-item.campaign {
        width: 90rem;
        height: 70rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
