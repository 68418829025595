<template>
  <app-campaign-subpage
    :config="config"
    :campaignFilterOptions="associatedCampaigns"
    @filterByCampaignID="filterByCampaignID"
  >
    <div>
      <ApplicationsList
        v-if="!loading"
        :campaigns="campaigns"
        :viewPerspective="viewPerspective"
        @respondToApplication="respondToApplication"
      />

      <RespondToNegotiationModal
        v-model:show="showResponseModal"
        title="Respond to application"
        :working="sendingResponse"
        ref="respondModalRef"
        @respond="handleApplicationModalResponse"
      />
    </div>
  </app-campaign-subpage>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useToast } from 'primevue/usetoast';

import { useAPI, usePage, useCampaigns } from '@/modules';
import { APIError } from '@/utils/globals/error-utils';
import RespondToNegotiationModal from '@/pages/commons/modals/RespondToNegotiationModal.vue';
import {
  AppPageConfig,
  AppPageProps,
  CampaignData,
  CampaignApplicationData,
} from '@/data/types';
import AppCampaignSubpage from '../../commons/AppCampaignSubpage.vue';
import ApplicationsList from './ApplicationsList.vue';

export default defineComponent({
  components: { RespondToNegotiationModal, ApplicationsList, AppCampaignSubpage },

  props: AppPageProps,

  setup(props) {
    const toast = useToast();

    const { config } = usePage<AppPageConfig>({
      title: 'Campaign Applications',
      // list: true,
      ...props,
    });

    const page = useCampaigns(config.value.viewPerspective);
    page.applications.loadApplications();

    // delete
    const {
      loading: sendingResponse,
      execute: sendApplicationResponse,
      error: sendingResponseError,
    } = useAPI('', { method: 'put' }, false);

    // responding to application
    const respondModalRef = ref<any>(null);
    const showResponseModal = ref(false);
    // const respondingToCampaign = ref<CampaignData | null>(null);
    // const respondingToApplication = ref<CampaignApplicationData | null>(null);
    // const respondingApplicationLastOffer = ref<NegotiationOffer | null>(null);
    const respondToApplication = ({ campaign, application }: { campaign: CampaignData; application: CampaignApplicationData }) => {
      console.log('respondToApplication', campaign, application);
      if (respondModalRef.value) {
        respondModalRef.value.open(application.offers[application.offers.length - 1], { campaign, application });
      }
      // showResponseModal.value = true;
      // respondingToCampaign.value = campaign;
      // respondingToApplication.value = application;
      // respondingApplicationLastOffer.value = application.offers[application.offers.length - 1];
    };

    const handleApplicationModalResponse = ({ campaign, application, ...payload }: any) => {
      console.log('handleApplicationModalResponse', campaign, application, payload);
      // answer (action, string); counterOfferPrice: Number, finalOffer: Boolean

      const { action } = payload;
      // eslint-disable-next-line no-param-reassign
      payload.perspective = config.value.viewPerspective;

      sendApplicationResponse({
        url: `/campaigns/applications/${campaign.id}/${application.id}`,
        data: payload,
      })
        .then((success: any) => {
          console.log(`application response result (action: ${action}) -> ${success}`);
          page.applications.loadApplications();

          if (success) {
            showResponseModal.value = false;
            let successMessage = '';
            if (action === 'accept') {
              successMessage = 'Application successfully accepted!';
            } else if (action === 'reject') {
              successMessage = 'Application successfully rejected!';
            } else if (action === 'counter') {
              successMessage = 'Counter offer sent successfully!';
            } else if (action === 'withdraw') {
              if (config.value.viewPerspective === 'brand') {
                successMessage = 'Your offer for this application has been withdrawn';
              }
            }

            toast.add({
              severity: 'success',
              summary: 'Success',
              detail: successMessage,
              life: 3000,
            });
          }
        });
    };

    watch(sendingResponseError, (err?: APIError) => {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    });

    // withdrawing an application (from creator perspective)
    // withdrawApplication

    return {
      config,
      loading: page.applications.loading,
      campaigns: page.applications.applications,
      associatedCampaigns: page.applications.associatedCampaigns,
      filterByCampaignID: page.applications.filterByCampaignID,
      // admin/brand responding to application
      respondModalRef,
      respondToApplication,
      showResponseModal,
      handleApplicationModalResponse,
      sendingResponse,
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
