
import { computed, defineComponent, PropType, ref } from 'vue';
import Button from 'primevue/button';
import { CampaignData } from '@/data/types';
import { CampaignStep, getStep, useCampaignStep } from '@/pages/campaigns/create/modules/campaign-step-module';
import PhotoGallery from '@/pages/commons/media/PhotoGallery.vue';

export default defineComponent({
  name: 'CampaignBriefingStep',

  components: {
    PhotoGallery,
    Button,
  },

  props: {
    campaignID: String,
    campaign: Object as PropType<CampaignData>,
    edit: Boolean, // TODO: enum
  },

  setup(props) {
    const campaign = computed(() => props.campaign);

    const photo = ref(campaign?.value?.photo || '');

    const step = getStep('edit-campaign--image', props.campaignID) as CampaignStep;
    const controller = useCampaignStep(
      step,
      {},
      props.campaignID,
    );
    controller.setSerializer(() => ({
      photo: photo.value,
    }));

    return {
      photo,
      // step control
      step,
      controller,
      ...controller.validation,
    };
  },
});
