import { Ref, ref } from 'vue';
import { useAuth } from '@/modules/auth-module';

export interface MenuItem {
  id?: string;
  label?: string;
  icon?: string;
  to?: {
    name: string;
    params?: Record<string, string | undefined>;
  };
  actionIcon?: string;
  badge?: number;
  badgeSeverity?: string;
  opened?: boolean;
  separator?: boolean;
  items?: Array<MenuItem>;
}

const items = ref<Array<MenuItem>>([]);

export const useMenu = () => {
  const updateItem = (id: string, data: MenuItem) => {
    const item = items.value.reduce((result: Array<MenuItem>, current) => {
      result.push(current);
      if (current.items) {
        result.push(...current.items);
      }
      return result;
    }, []).find((i) => i?.id === id);

    if (item) {
      Object.assign(item, data);
    }
  };

  const generateMenu = (): Ref<Array<MenuItem>> => {
    const { user } = useAuth();

    if (!user || !user.value) {
      return items;
    }

    const menu: Array<MenuItem> = [];

    menu.push({
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'dashboard',
      to: { name: 'dashboard' },
    });

    if (user.value.isCreator()) {
      menu.push({
        label: 'Brands',
        icon: 'business',
        to: { name: 'brands' },
      });
      menu.push({
        label: 'Campaigns',
        icon: 'campaign',
        to: { name: 'campaigns' },
      });
    } else if (user.value.isBrandOwner()) {
      menu.push({
        label: 'Creators',
        icon: 'portrait',
        to: { name: 'creators' },
      });
      menu.push({
        label: 'Content Items',
        icon: 'play_circle_outline',
        to: { name: 'content-items' },
      }); // shop_two
    }

    menu.push({ separator: true });

    const campaignSubItems = [];

    campaignSubItems.push({
      id: 'campaign-invites',
      label: 'Invites',
      icon: 'drafts',
      to: { name: 'campaign-invitations' },
      // badge: 128,
    });
    campaignSubItems.push({
      id: 'campaign-applications',
      label: 'Applications',
      icon: 'note_alt',
      to: { name: 'campaign-applications' },
    });
    campaignSubItems.push({
      id: 'campaign-submissions',
      label: 'Submissions',
      icon: 'assignment_turned_in',
      to: { name: 'campaign-submissions' },
      // badge: 3,
      // badgeSeverity: 'warning',
    });

    menu.push({
      id: 'campaigns',
      label: 'My Campaigns',
      icon: 'campaign',
      items: campaignSubItems,
      to: { name: 'my-campaigns' },
      opened: true,
    });

    const contentSubItems = [];
    contentSubItems.push({
      id: 'license-requests',
      label: 'License Requests',
      icon: 'pending_actions',
      to: { name: 'license-requests' },
    });
    if (user.value.isBrandOwner()) {
      menu.push({
        label: 'My Content Items',
        icon: 'request_quote',
        to: { name: 'licensed-items' },
        items: contentSubItems,
        opened: true,
      }); // shop_two
    } else if (user.value.isCreator()) {
      menu.push({
        label: 'My Content Items',
        icon: 'shop_two',
        // actionIcon: 'refresh',
        to: { name: 'my-content-items' },
        items: [
          ...contentSubItems,
          {
            label: 'Licensed Items',
            icon: 'request_quote',
            to: { name: 'licensed-items' },
          },
        ],
        opened: true,
      });
    }

    if (user.value.isBrandOwner()) {
      menu.push({
        label: 'My Brand',
        icon: 'business',
        to: {
          name: 'brand',
          params: { brandID: user.value.brand?.id },
        },
      });
    } else if (user.value.isCreator()) {
      menu.push({
        label: 'My Profile',
        icon: 'manage_accounts',
        to: {
          name: 'creator',
          params: { creatorID: user.value.id },
        },
      });
    }

    if (user.value.isAdmin()) {
      menu.push({ separator: true });
      menu.push({
        label: 'Admin',
        icon: 'admin_panel_settings',
        to: { name: 'admin' },
        items: [
          {
            label: 'Invites',
            icon: 'drafts',
            to: { name: 'admin-campaign-invitations' },
          },
          //   { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: { name: 'admin' } },
        ],
      });
    }

    items.value = menu;
    return items;
  };

  generateMenu();

  return {
    items,
    generateMenu,
    updateItem,
  };
};
