import { ref } from 'vue';
import { useUserManager } from './users/user-manager-module';
import { useContext, MANAGER } from './context-module';
import HTTPService from '../services/http/HTTPService';
import { Perspective, User, UserData, UserType } from '../data/types';

const AUTH_KEY = 'gi-auth-token';

const user = ref<User | undefined>(undefined);
const authenticationDone = ref(false);

export const useAuth = () => {
  const manager = useUserManager();
  const context = useContext(MANAGER);

  const loadUser = (token: string | null = window.localStorage.getItem(AUTH_KEY)): Promise<User | undefined> => {
    if (token) {
      return manager.loadMyProfile(token)
        .then((loggedUser) => {
          if (loggedUser) {
            // console.log('auth :: loading user success', loggedUser);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            login(loggedUser, token);
          } else {
            // console.log('auth :: loading user failed, removing token');
            window.localStorage.removeItem(AUTH_KEY); // token is invalid
          }

          authenticationDone.value = true;
          return loggedUser;
        });
    }

    authenticationDone.value = true;
    return Promise.resolve(undefined);
  };

  const login = (loggedUser?: User | UserData, token?: string) => {
    // console.log('auth :: login: ', loggedUser, token);
    if (!loggedUser) {
      return;
    }

    if (token) {
      window.localStorage.setItem(AUTH_KEY, token);
      HTTPService.setAuthorizationHeader(token);
    }

    if (loggedUser instanceof User) {
      user.value = loggedUser;
    } else {
      user.value = new User(loggedUser);
    }

    // eslint-disable-next-line no-unused-expressions
    context.setPerspective?.(user.value.type === UserType.BrandOwner ? Perspective.BRAND : Perspective.CREATOR);
  };

  const logout = (): Promise<void> => {
    window.localStorage.removeItem(AUTH_KEY);
    user.value = undefined;

    return Promise.resolve();
  };

  return {
    authenticationDone,
    user,
    loadUser,
    login,
    logout,
  };
};
