/* eslint-disable no-underscore-dangle */
import { AxiosError } from 'axios';

export interface ErrorDetails {
  status?: number;
  code?: number;
  message?: string;
}

export class APIError extends Error {
  _details: ErrorDetails;

  constructor(error: AxiosError | string) {
    const details: ErrorDetails = {};

    if (typeof error === 'string') {
      details.message = error;
    } else {
      const { response } = error;
      const { request } = error;

      if (response) { // eslint-disable-line no-lonely-if
        details.status = response.status;

        if (response.data) {
          details.code = response.data.code;
          details.message = response.data.message;
        }
      } else if (request) { // eslint-disable-line no-else-return
        details.message = 'Error occurred';
      } else {
        details.message = error.message || 'Internal error';
      }
    }

    super(details.message);

    this._details = details;
  }

  get details() {
    return this._details;
  }

  get code() {
    return this._details.code;
  }

  get status() {
    return this._details.status;
  }
}
