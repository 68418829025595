
import { defineComponent, PropType } from 'vue';
import { Brand, BrandData } from '../../../data/types';

export default defineComponent({
  name: 'BrandPhoto',

  props: {
    brand: {
      type: Object as PropType<Brand | BrandData>,
    },
  },
});
