import { watch } from 'vue';
import { useAPI } from '@/modules';
import { AppPagePerspective, User, UserData } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useUserManager = () => {
  const toasts = useToastModule();

  // load my profile
  const {
    loading: loadingMyProfile,
    error: loadingMyProfileError,
    execute: executeLoadMyProfile,
    data: myProfile,
  } = useAPI<User>('/users/me', false);

  const loadMyProfile = (accessToken?: string) => {
    const headers: any = {};
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    return executeLoadMyProfile({
      headers,
      parseResponse: (raw: UserData) => new User(raw),
    });
  };
  watch(loadingMyProfileError, toasts.handleRequestError);
  // < load my profile

  // verify
  const {
    loading: verifying,
    error: verificationError,
    data: verificationSuccess,
    execute: sendVerify,
  } = useAPI<User>('/users', false);

  const verify = (perspective: AppPagePerspective, payload: Record<string, any>) => sendVerify({
    method: 'PUT',
    data: {
      action: 'verify',
      perspective,
      ...payload,
    },
    parseResponse: (userData: UserData) => new User(userData),
  });
  watch(verificationError, toasts.handleRequestError);
  // < verify

  // signin
  const {
    loading: signingIn,
    error: signinError,
    data: signinSuccess,
    execute: sendSingIn,
  } = useAPI<{ tokens: any }>('/auth/login', false);

  const signin = (email: string, password: string) => sendSingIn({
    method: 'POST',
    data: { email, password },
  });
  watch(signinError, toasts.handleRequestError);
  // < signin

  return {
    // load my profile
    loadMyProfile,
    loadingMyProfile,
    loadingMyProfileError,
    myProfile,
    // verification
    verify,
    verifying,
    verificationError,
    verificationSuccess,
    // signin
    signin,
    signingIn,
    signinError,
    signinSuccess,
  };
};
