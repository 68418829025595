
import { defineComponent, ref, watch } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import ModalWorkingOverlay from './ModalWorkingOverlay.vue';

export default defineComponent({
  components: {
    ModalWorkingOverlay,
    Button,
    Dialog,
    Textarea,
  },

  props: {
    show: Boolean,
    working: Boolean,
  },

  emits: ['request', 'update:show'],

  setup(props, { emit }) {
    const reason = ref('');
    const payload = ref<any>(null);

    // working
    const sendRequest = () => {
      emit('request', { ...payload.value, reason: reason.value });
    };

    watch(() => props.show, () => {
      reason.value = '';
    });

    const open = (data: any) => {
      payload.value = data;
      emit('update:show', true);
    };

    return {
      sendRequest,
      reason,
      open,
    };
  },
});
