
import { computed, defineComponent, ref, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

import {
  AppPageConfig,
  AppPageProps,
  CampaignContractData, CampaignDeliverableData,
  Perspective,
} from '@/data/types';
import { APIError } from '@/utils/globals/error-utils';
import { formatDate } from '@/utils/globals/date-utils';
import ConfirmModal from '@/pages/commons/modals/ConfirmModal.vue';
import SubmitDeliverableModal from '@/pages/commons/modals/SubmitDeliverableModal.vue';
import RequestDeliverableChangeModal from '@/pages/commons/modals/RequestDeliverableChangeModal.vue';
import StatusChip from '@/pages/commons/status/StatusChip.vue';
import CampaignShortPreview from '@/pages/campaigns/parts/CampaignShortPreview.vue';
import { usePage, useCampaigns } from '../../../modules';
import AppPage from '../../commons/AppPage.vue';
import DeliverablesList from './deliverables/DeliverablesList.vue';
import DeliverablesTypeList from './deliverables/DeliverablesTypeList.vue';

export default defineComponent({
  components: {
    DeliverablesTypeList,
    AppPage,
    Button,
    DeliverablesList,
    CampaignShortPreview,
    StatusChip,
    ConfirmModal,
    SubmitDeliverableModal,
    RequestDeliverableChangeModal,
    Accordion,
    AccordionTab,
  },

  props: {
    campaignID: {
      type: String,
      required: true,
    },
    contractID: {
      type: String,
      required: true,
    },
    ...AppPageProps,
  },

  setup(props) {
    const toast = useToast();

    const { config } = usePage<AppPageConfig>({
      title: 'Submissions',
      // list: true,
      ...props,
    });

    const handleRequestError = (err?: APIError) => {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    };

    const showSubmitDeliverableModal = ref(false);
    const submitDeliverableModalRef = ref<any>(null);
    const acceptDeliverableModalRef = ref<any>(null);
    const rejectDeliverableModalRef = ref<any>(null);
    const completeContractModalRef = ref<any>(null);

    const page = useCampaigns(config.value.viewPerspective);

    const contract = computed((): CampaignContractData | undefined => (page.contracts.singleContractCampaign.value?.contracts || [])
      .find((cntr: CampaignContractData) => cntr.id === props.contractID));

    const imageDeliverablesList = computed(() => (contract.value?.deliverables || []).filter((d) => d.type === 'image'));
    const videoDeliverablesList = computed(() => (contract.value?.deliverables || []).filter((d) => d.type === 'video'));
    const selectedDeliverableList = ref<Array<any>>([]);
    const selectedDeliverable = ref<CampaignDeliverableData>();

    const selectDeliverable = (d: CampaignDeliverableData) => {
      selectedDeliverableList.value = [d];
      selectedDeliverable.value = d;
    };

    const loadPageData = () => page.contracts.loadSingle(props.campaignID, props.contractID);
    loadPageData();

    watch(contract, () => {
      if (contract.value?.deliverables.length) {
        selectDeliverable(contract.value?.deliverables[0]);
      }
    });

    const handleDeliverableModalSubmit = (payload: any) => { // link, comment
      page.deliverables.submit(props.campaignID, props.contractID, payload)
        .then((success: any) => {
          if (success) {
            loadPageData();
            showSubmitDeliverableModal.value = false;

            toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Deliverable submitted successfully!',
              life: 3000,
            });
          }
        });
    };

    watch(page.deliverables.submittingError, (err?: APIError) => handleRequestError);

    // resubmit
    const openResubmitDeliverableModal = (payload: any) => {
      submitDeliverableModalRef.value.open(payload.deliverable);
    };

    // accept deliverable
    const showingAcceptDeliverableModal = ref(false);
    const openAcceptDeliverableModal = (payload: any) => {
      acceptDeliverableModalRef.value.open(payload);
    };
    const handleDeliverableModalAccept = (payload: any) => { // contract, deliverable, linkSet
      page.deliverables.accept(props.campaignID, props.contractID, payload.deliverable.id)
        .then((success: any) => {
          if (success) {
            loadPageData();
            showingAcceptDeliverableModal.value = false;

            toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Deliverable accepted successfully!',
              life: 3000,
            });
          }
        });
    };
    watch(page.deliverables.acceptingError, handleRequestError);

    // reject deliverable
    const showRejectDeliverableModal = ref(false);
    const openRejectDeliverableModal = (payload: any) => {
      rejectDeliverableModalRef.value.open(payload);
    };
    const handleDeliverableModalReject = (payload: any) => { // contract, deliverable, linkSet
      page.deliverables.reject(props.campaignID, props.contractID, payload.deliverable.id, payload.reason)
        .then((success: any) => {
          if (success) {
            loadPageData();
            showRejectDeliverableModal.value = false;

            toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Deliverable change request send successfully!',
              life: 3000,
            });
          }
        });
    };
    watch(page.deliverables.rejectingError, handleRequestError);

    // complete contract
    const showingCompleteContractModal = ref(false);
    const handleCompleteContractModalResult = () => {
      page.contracts.complete(props.campaignID, props.contractID)
        .then((success: any) => {
          if (success) {
            loadPageData();
            showingCompleteContractModal.value = false;
          }
        });
    };
    // < complete contract

    return {
      formatDate,
      Perspective,
      config,
      campaign: page.contracts.singleContractCampaign,
      loading: page.contracts.loadingSingle,
      contract,
      imageDeliverablesList,
      videoDeliverablesList,
      selectedDeliverableList,
      selectDeliverable,
      selectedDeliverable,
      // modal
      showSubmitDeliverableModal,
      handleDeliverableModalSubmit,
      submitting: page.deliverables.submitting,
      // resubmit
      submitDeliverableModalRef,
      openResubmitDeliverableModal,
      // accept
      acceptDeliverableModalRef,
      openAcceptDeliverableModal,
      showingAcceptDeliverableModal,
      handleDeliverableModalAccept,
      accepting: page.deliverables.accepting,
      // reject
      rejectDeliverableModalRef,
      showRejectDeliverableModal,
      openRejectDeliverableModal,
      handleDeliverableModalReject,
      rejecting: page.deliverables.rejecting,
      // complete contract
      completeContractModalRef,
      showingCompleteContractModal,
      handleCompleteContractModalResult,
      completing: page.contracts.completing,
    };
  },
});
