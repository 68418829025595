<template>
  <div>
    <Dialog
      :visible="show"
      class="p-col-12 p-md-6 p-xl-4 gi-dialog"
      :modal="true"
      :dismissableMask="!working"
      :closable="!working"
      @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <div class="w-100 p-text-center">
          <h1>Request a change</h1>
          <span class="subtitle">
            Enter detailed description of what you'd like to have changed.
          </span>
        </div>
      </template>

      <div class="p-mt-4 p-mb-4 container">
        <ModalWorkingOverlay v-if="working" />
        <div class="p-grid p-d-flex p-flex-column p-p-4">
          <h6>Reason for change</h6>
          <Textarea v-model="reason" rows="5" cols="30" placeholder="What's the reason you'd like a change?" />
        </div>
      </div>

      <template #footer>
        <div class="p-d-flex p-justify-between p-mt-4">
          <Button
            label="Cancel"
            class="p-button-link p-ml-6"
            :disabled="working"
            @click="$emit('update:show', false)"
          />

          <Button
            label="Send request"
            class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
            :loading="working"
            :disabled="working || !reason"
            @click="sendRequest"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import ModalWorkingOverlay from './ModalWorkingOverlay.vue';

export default defineComponent({
  components: {
    ModalWorkingOverlay,
    Button,
    Dialog,
    Textarea,
  },

  props: {
    show: Boolean,
    working: Boolean,
  },

  emits: ['request', 'update:show'],

  setup(props, { emit }) {
    const reason = ref('');
    const payload = ref<any>(null);

    // working
    const sendRequest = () => {
      emit('request', { ...payload.value, reason: reason.value });
    };

    watch(() => props.show, () => {
      reason.value = '';
    });

    const open = (data: any) => {
      payload.value = data;
      emit('update:show', true);
    };

    return {
      sendRequest,
      reason,
      open,
    };
  },
});
</script>

<style scoped lang="scss">
.container {
  position: relative;

  .working-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;

    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: .5;
    }

    .spinner {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
