import { ref, Ref, watch } from 'vue';

export const useStepValidation = (inputs: Record<string, Ref>) => {
  const errors = ref<any>({});
  const entries = Object.entries(inputs); // keeps an order
  const fields: Array<Ref> = entries.map(([, field]) => field);

  watch(fields, (newVals, oldVals) => {
    // console.log('-- updated', newVals, oldVals);
    newVals.forEach((val: any, index) => {
      if (val !== oldVals[index]) {
        errors.value[entries[index][0]] = false;
        // console.log('updated field: ', entries[index][0]);
      }
    });
  });

  const validate = () => {
    entries.forEach(([name, input]) => {
      errors.value[name] = !input.value;
      if (Array.isArray(input.value)) {
        errors.value[name] = !input.value.length;
      }
    });
    return !Object.values(errors.value).filter((value) => !!value).length;
  };
  const isInvalid = (field: string): boolean => errors.value[field];
  const invalidClass = (field: string): string => (isInvalid(field) ? 'p-invalid' : '');

  return {
    validate,
    errors,
    isInvalid,
    invalidClass,
  };
};
