import { useAPI } from '@/modules/api-module';
import { useAuth } from '@/modules/auth-module';
import { CampaignData, AppPagePerspective, Perspective, CampaignStatus } from '@/data/types';
import { ref, watch } from 'vue';
import { useCampaignsList } from './campaigns-list-module';

const { user } = useAuth();

export const useCampaignApplications = (perspective: AppPagePerspective) => {
  const selectedCampaignID = ref<string>('');
  const applications = ref<Array<CampaignData>>([]);
  const associatedCampaigns = ref<Array<CampaignData>>([]);

  const { loading, finished, data: loadedApplicationCampaigns, execute } = useAPI<Array<CampaignData>>('/campaigns/applications/', false);
  const allCampaigns = useCampaignsList(perspective);

  const loadApplications = () => {
    const creatorID = user?.value?.id;
    const brandID = user?.value?.brand?.id;
    const targetID = perspective === Perspective.BRAND ? brandID : creatorID;

    if (perspective === Perspective.BRAND && !brandID) {
      throw new Error('Could not load campaigns for a brand, because brandID is missing!');
    }

    if (perspective === Perspective.BRAND || perspective === Perspective.ADMIN) {
      allCampaigns.loadCampaigns();
    }

    return execute({ params: { perspective, targetID } });
  };

  watch(loadedApplicationCampaigns, () => {
    if (perspective === Perspective.CREATOR) {
      associatedCampaigns.value = loadedApplicationCampaigns.value || [];
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    filterList();
  });

  watch(allCampaigns.campaigns, () => {
    associatedCampaigns.value = allCampaigns
      .campaigns.value?.filter((campaign) => campaign.status !== CampaignStatus.Draft)
      || [];
  });

  // filtering

  const filterList = () => {
    const campaignsWithApplications = loadedApplicationCampaigns
      .value?.filter((campaign) => !!campaign.applications?.length) || [];

    if (selectedCampaignID.value) {
      applications.value = campaignsWithApplications.filter((campaign) => campaign.id === selectedCampaignID.value);
    } else {
      applications.value = campaignsWithApplications;
    }
  };

  const filterByCampaignID = (campaignID = '') => {
    selectedCampaignID.value = campaignID;
    filterList();
  };

  return {
    applications,
    loading,
    finished,
    loadApplications,
    filterByCampaignID,
    associatedCampaigns,
  };
};
