<template>
  <div class="campaigns-subpage-list-item">
    <h5 class="campaign-name">{{ campaign.name }}</h5>
    <div class="box">
      <div class="headers p-grid p-nogutter">
        <div class="p-col-4">
          <h6 v-if="viewPerspective === 'creator'">Campaign</h6>
          <h6 v-if="viewPerspective === 'brand'">Creator</h6>
          <h6 v-if="viewPerspective === 'admin'">Campaign / Creator / Brand</h6>
        </div>
        <div class="p-col-2">
          <h6>Price</h6>
        </div>
        <div class="p-col-3">
          <h6>Status</h6>
        </div>
      </div>

      <div>
        <component
          :is="campaignBoxClass"
          v-for="item in list"
          :key="item.id"
          :campaign="campaign"
          :application="item"
          :contract="item"
          :viewPerspective="viewPerspective"
          v-bind="$attrs"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { CampaignData, Perspective } from '@/data/types';

export default defineComponent({

  props: {
    campaign: {
      type: Object as PropType<CampaignData>,
      default: [],
    },
    campaignBoxClass: {
      type: Object,
      required: true,
    },
    campaignBoxType: { // application, contract
      type: String,
      required: true,
    },
    viewPerspective: String as PropType<Perspective>,
  },

  setup(props) {
    const list = computed(() => {
      if (props.campaignBoxType === 'application') {
        return props.campaign.applications;
      }
      if (props.campaignBoxType === 'contract') {
        return props.campaign.contracts;
      }
      return [];
    });

    // application in campaign.applications
    return {
      list,
    };
  },
});
</script>

<style lang="scss" scoped>
.campaigns-subpage-list-item {
  .campaign-name {
    margin-top: 40rem;
    margin-bottom: 8rem;
  }
  .box {
    border: 1px solid #C8CCCC;
    //box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .headers {
      padding: 30rem 40rem 0 40rem;
    }
  }
}
</style>
