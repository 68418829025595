<template>
  <div class="user-photo">
    <img v-if="user.profile.photo" :src="user.profile.photo" alt="" referrerpolicy="no-referrer" class="real" />
    <span v-else class="material-icons default">account_circle</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { User } from '../../../data/types';

export default defineComponent({
  name: 'UserPhoto',

  props: {
    user: {
      type: Object as PropType<User>,
    },
  },
});
</script>

<style lang="scss" scoped>
.user-photo {
  width: 170rem;
  height: 170rem;
  //background: #F7F7F7;
  background: rgba(232, 232, 232, 0.34);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  .real {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .default {
    // in case of svg default image
    //width: 50%;
    //height: 50%;
    pointer-events: none;
    user-select: none;
    font-size: 32rem;
  }
}
</style>
