<template>
  <div class="email-connect">
    <h5 class="p-mb-3 p-text-center">OR</h5>
    <h6 class="p-mt-2 p-mb-2">Using Email</h6>
    <InputText type="text" v-model="email" class="w-100 p-mb-2" />
    <Password v-model="password" :feedback="false" toggleMask class="w-100 password p-mb-2" />
    <Button
      label="SIGN IN"
      :disabled="!!connecting"
      :loading="connecting === 'email'"
      class="social-connect-item p-button-sm w-100 login-button"
      @click="$emit('signin', { email, password })"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Button from 'primevue/button';
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';

export default defineComponent({
  name: 'EmailConnect',

  components: { Button, Password, InputText },

  props: {
    connecting: String,
  },

  setup() {
    const email = ref<string>('');
    const password = ref<string>('');

    return {
      email,
      password,
    };
  },
});
</script>

<style lang="scss">
.email-connect {
  width: 100%;

  .password {
    input {
      width: 100%;
    }
  }

  .login-button {
    text-transform: uppercase;
    @include font-montserrat();
    font-weight: 600;
  }
}
</style>
