<template>
  <div class="page-menu">
    <PageMenuList :items="items" />
  </div>
</template>

<script>
import PageMenuList from './PageMenuList.vue';

export default {
  props: {
    items: Array,
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event);
    },
  },
  components: {
    PageMenuList,
    // PageSubmenu,
  },
};
</script>

<style lang="scss">
.page-menu {
  padding-bottom: 80rem;
  padding-left: 25rem;
}
</style>
