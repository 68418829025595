
import { defineComponent, PropType, ref, watch } from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import EntityPhoto from '@/pages/commons/media/EntityPhoto.vue';

export default defineComponent({
  components: { EntityPhoto, Button, InputText },
  props: {
    current: {
      type: String,
      default: '',
    },
    photo: {
      type: String,
    },
    type: {
      type: String as PropType<'brand' | 'user' | 'campaign'>,
      required: true,
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    interface Photo {
      url: string;
      custom?: boolean;
    }
    const availablePhotos = ref<Array<Photo>>([{ url: props.photo || '' }]);
    const selectedPhoto = ref<Photo>({ url: props.photo || '' });

    const customURL = ref('');
    const showAddInput = ref(false);
    const addCustomPhoto = () => {
      if (customURL.value) {
        const photo: Photo = { url: customURL.value, custom: true };
        availablePhotos.value.push(photo);
        showAddInput.value = false;
        customURL.value = '';
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        selectPhoto(photo);
      }
    };
    const removeCustomPhoto = () => {
      if (!selectedPhoto.value.custom) {
        return;
      }
      const index = availablePhotos.value.findIndex((i) => i.url === selectedPhoto.value.url);
      if (index > -1) {
        availablePhotos.value.splice(index, 1);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        selectPhoto(availablePhotos.value[availablePhotos.value.length - 1]);
      }
    };

    const selectPhoto = (photo: any) => {
      selectedPhoto.value = photo;
      showAddInput.value = false;

      emit('selected', selectedPhoto.value.url);
      emit('update:photo', selectedPhoto.value.url);
      emit('update:modelValue', selectedPhoto.value.url);
    };

    return {
      availablePhotos,
      selectedPhoto,
      customURL,
      showAddInput,
      addCustomPhoto,
      removeCustomPhoto,
      selectPhoto,
    };
  },
});
