<template>
  <div>
    <Button
      v-if="viewPerspective === 'brand' && action === 'brand-respond'"
      label="Respond to Request"
      class="p-button-link"
      @click.stop="$emit('respond', { item, request, offer: lastOffer })"
    />

    <Button
      v-if="viewPerspective === 'creator' && action === 'creator-respond'"
      label="Respond to Request"
      class="p-button-link"
      @click.stop="$emit('respond', { item, request, offer: lastOffer })"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import Button from 'primevue/button';
import { ContentItem, Perspective } from '@/data/types';

export default defineComponent({
  components: {
    Button,
  },

  props: {
    item: {
      type: Object as PropType<ContentItem>,
      required: true,
    },
    request: {
      type: Object, // TODO: enum
    },
    viewPerspective: String as PropType<Perspective>,
  },

  emits: ['respond'],

  setup(props) {
    const lastOffer = computed(() => props.request?.offers[props.request?.offers.length - 1]);
    const action = computed(() => {
      if (lastOffer.value.status === 'pending') {
        if (props.viewPerspective === 'brand') {
          if (lastOffer.value.from === 'creator') {
            return 'brand-respond';
          }
          if (lastOffer.value.from === 'brand') {
            return 'creator-respond';
          }
        }
        if (props.viewPerspective === 'creator') {
          if (lastOffer.value.from === 'creator') {
            return 'brand-respond';
          }
          if (lastOffer.value.from === 'brand') {
            return 'creator-respond';
          }
        }
      }
      return '';
    });
    const statusText = computed(() => {
      if (props.viewPerspective === 'creator' && action.value === 'brand-respond') {
        return 'Waiting for brand';
      }
      if (props.viewPerspective === 'brand' && action.value === 'creator-respond') {
        return 'Waiting for creator';
      }
      return '';
    });

    return {
      lastOffer,
      statusText,
      action,
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
