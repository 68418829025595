
import { defineComponent, PropType } from 'vue';

import { User } from '../../../../data/types';
import ContentItemsList from '../../../content/ContentItemsList.vue';

export default defineComponent({
  name: 'BrandProfile',

  components: { ContentItemsList },

  props: {
    creator: {
      type: Object as PropType<User>,
    },
  },

  setup() {
    return {
    };
  },
});
