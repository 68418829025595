
import { computed, defineComponent, ref } from 'vue';
import Button from 'primevue/button';

import { AppPageProps, LicenseRequestRequest } from '@/data/types';
import { useAuth, useContentItems } from '@/modules';
import LicenseRequestModal from '@/pages/commons/modals/LicenseRequestModal.vue';
import AppListPage from '../../commons/AppListPage.vue';
import CreatorListItem from '../../creators/CreatorListItem.vue';

export default defineComponent({
  name: 'ContentItem',

  components: {
    Button,
    LicenseRequestModal,
    AppListPage,
    // eslint-disable-next-line vue/no-unused-components
    CreatorListItem,
  },

  props: {
    itemID: String,
    ...AppPageProps,
  },

  setup(props) {
    const { user } = useAuth();
    const content = useContentItems(props.viewPerspective);

    content.manager.loadSingle(props.itemID as string);

    // requesting a license
    const showRequestLicenseModal = ref(false);
    const sendLicenseRequest = (payload: LicenseRequestRequest) => {
      content.requests
        .request(props.itemID as string, payload)
        .then((success?: boolean) => {
          if (success) {
            showRequestLicenseModal.value = false;
          }
        });
    };

    const toggleItemVisibility = () => {
      if (props.itemID) {
        content.manager.update(props.itemID, { public: !content.manager.singleItem.value?.public });
      }
    };

    const itemIsMine = computed(() => user?.value?.id === content.manager.singleItem.value?.owner.id);

    return {
      content,
      loading: content.manager.loadingSingle,
      item: content.manager.singleItem,
      itemIsMine,
      // licensing
      showRequestLicenseModal,
      sendLicenseRequest,
      // visibility
      toggleItemVisibility,
    };
  },
});
