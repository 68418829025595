
import { defineComponent, PropType } from 'vue';
import { CampaignContractData, CampaignData, CampaignDeliverableData, Perspective } from '@/data/types';
import EmptyList from '@/pages/commons/list/EmptyList.vue';
import DeliverablesSet from '@/pages/campaigns/contracts/deliverables/DeliverablesSet.vue';

export default defineComponent({
  components: { DeliverablesSet, EmptyList },

  props: {
    // TODO: reuse
    campaign: Object as PropType<Array<CampaignData>>,
    contract: Object as PropType<Array<CampaignContractData>>,
    deliverables: {
      type: Array as PropType<Array<CampaignDeliverableData>>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
    showEmptyList: {
      type: Boolean,
      default: true,
    },
  },
});
