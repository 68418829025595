<template>
  <div class="social-connect">
    <div class="social-connect-list">
      <Button
        v-for="item in items"
        :key="item.type"
        :disabled="platformDisabled(item)"
        :label="item.label || `sign ${action === 'login' ? 'in' : 'up' } with ${item.type}`"
        :icon="getIconClass(item.type)"
        @click="openConnectPopup(item.type)"
        class="social-connect-item p-button-sm"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import { openSocialPopup } from '@/utils/globals/popup-utils';
import { SocialConnectItem, SocialConnectResponse } from '../social-def';

export default defineComponent({
  name: 'SocialConnect',
  components: { Button },

  props: {
    connecting: String,
    items: Array as PropType<Array<SocialConnectItem>>,
    action: String,
    userType: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: {
    'update:connecting': (payload: string) => true, // eslint-disable-line @typescript-eslint/no-unused-vars
    connect: (payload: SocialConnectResponse) => true, // eslint-disable-line @typescript-eslint/no-unused-vars
  },

  setup(props, { emit }) {
    const toast = useToast();

    const connectingPlatform = ref('');

    watch(() => props.connecting, () => {
      connectingPlatform.value = props.connecting || '';
    });

    watch(connectingPlatform, () => {
      emit('update:connecting', connectingPlatform.value);
    });

    const getIconClass = (type: string): string => {
      let result = 'fab';
      if (connectingPlatform.value === type) {
        result += ' fas fa-spinner fa-spin';
      } else {
        result += ` fa-${type}`;
      }
      return result;
    };

    const platformDisabled = (item: SocialConnectItem): boolean => {
      if (connectingPlatform.value || props.disabled || item.disabled) {
        return true;
      }
      if (props.action === 'login') {
        return false;
      }
      return item.forUser !== props.userType;
    };

    const openConnectPopup = (network: string): void => {
      connectingPlatform.value = network;
      openSocialPopup(
        network,
        { type: props.userType, action: props.action },
        async (err?: Error | null, response?: SocialConnectResponse) => {
          if (err) {
            console.log('-- err:', err);
            let errorMessage = err.message;
            if (err.message === 'popup-closed') {
              errorMessage = 'Popup was closed. You should select your social profile and complete all the steps. Please try again!';
            }

            toast.add({
              severity: 'error',
              summary: 'Error',
              detail: errorMessage,
              life: 3000,
            });

            connectingPlatform.value = '';
          } else {
            if (response && response.source && response.source === 'vue-devtools-backend') {
              return;
            }

            if (!response) {
              console.warn('Login :: no error, but user is missing!');
              return;
            }

            console.log('-- auth response: ', response);
            emit('connect', response);
          }
        },
      );
    };

    return {
      getIconClass,
      platformDisabled,
      connectingPlatform,
      openConnectPopup,
    };
  },
});
</script>

<style scoped lang="scss">
.social-connect {
  width: 100%;
  margin-bottom: 20px;

  .social-connect-list {
    display: flex;
    flex-direction: column;

    .social-connect-item {
      font-size: 16rem;
      color: #000;
      margin-bottom: 8rem;
      width: 100%;
      text-transform: uppercase;
      @include font-montserrat();
      font-weight: 800;

      background: rgba(255, 255, 255, 0.6);
      border: 1px solid rgba(24, 24, 24, 0.05);
      backdrop-filter: blur(25px);
      filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
    }
  }
}
</style>
