<template>
  <div class="app-page" :class="padded && 'padded'">
    <div class="header">
      <h1>{{ config?.title || title }}</h1>
    </div>

<!--    <pre>{{ config }}</pre>-->

    <slot name="search" v-if="config?.search?.enabled">
      <PageSearchBar
        :defaultSearchType="config?.search.defaultType"
        @search="$emit('search', $event)"
      />
    </slot>

    <div class="content">
      <transition name="fade">
        <div class="spinner-container" v-if="loading">
          <ProgressSpinner class="spinner" />
        </div>
      </transition>

      <transition name="fade">
        <slot :loading="loading"></slot>
      </transition>

      <slot name="router"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import ProgressSpinner from 'primevue/progressspinner';
import PageSearchBar from '@/pages/commons/search/PageSearchBar.vue';
import { AppPageConfig } from '../../data/types';

export default defineComponent({
  name: 'AppPage',

  components: { PageSearchBar, ProgressSpinner },

  props: {
    config: Object as PropType<AppPageConfig>,
    title: String,
    loading: Boolean,
    padded: Boolean,
  },

  emits: ['search'],
});
</script>

<style lang="scss" scoped></style>
