import { watch } from 'vue';
import { useAPI } from '@/modules';
import { AppPagePerspective, ContentItem, ContentItemData } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useContentItemsManager = (perspective: AppPagePerspective) => {
  const toast = useToastModule();

  // load single item
  const {
    loading: loadingSingle,
    error: loadingSingleError,
    execute: loadSingleItem,
    data: singleItem,
  } = useAPI<ContentItem>('', false);

  const loadSingle = (itemID: string) => loadSingleItem({
    url: `/content/${itemID}`,
    parseResponse: (raw: ContentItemData) => new ContentItem(raw),
  });
  watch(loadingSingleError, toast.handleRequestError);
  // < load single

  // update
  const {
    loading: updatingInProgress,
    error: updatingError,
    data: updatingResult,
    execute: sendUpdateRequest,
  } = useAPI<ContentItem>('', false);

  const update = (itemID: string, payload: Record<string, any>) => sendUpdateRequest({
    url: `/content/${itemID}`,
    method: 'PUT',
    data: payload,
    parseResponse: (raw: ContentItemData) => new ContentItem(raw),
  })
    .then((updatedItem?: ContentItem) => {
      if (updatedItem && singleItem.value) {
        singleItem.value = updatedItem;
      }
    });
  // < update

  // sync
  const {
    loading: syncingInProgress,
    error: syncingError,
    data: syncingResult,
    execute: sendSyncRequest,
  } = useAPI('/content/sync', false);

  const sync = () => sendSyncRequest();
  // < sync

  return {
    // loading single
    loadSingle,
    loadingSingle,
    singleItem,
    // update campaign
    update,
    updatingInProgress,
    updatingError,
    updatingResult,
    // syncing content items
    sync,
    syncingInProgress,
    syncingError,
    syncingResult,
  };
};
