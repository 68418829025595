
import {
  defineComponent,
  ref,
  watch,
  computed,
  PropType,
} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import { NegotiationOffer } from '@/data/types/negotiation-types';

export default defineComponent({
  components: {
    Button,
    Dialog,
    Checkbox,
    InputNumber,
  },

  props: {
    show: Boolean,
    working: Boolean,
    title: {
      type: String,
      default: 'Respond to offer',
    },
    // offer: Object as PropType<NegotiationOffer | null>,
  },

  emits: ['update:show', 'respond'],

  setup(props, { emit }) {
    let payload: any = null;
    const offer = ref<NegotiationOffer | null>(null);
    const lastOfferIsFinal = computed(() => !!offer?.value?.final);

    const selectedOption = ref('');
    const isSelected = (option: string) => selectedOption.value === option;
    const selectOption = (option: string) => {
      if (props.working) {
        return;
      }
      if (option === 'counter' && lastOfferIsFinal.value) {
        return;
      }
      selectedOption.value = option;
      // if (selectedOption.value === option) {
      //   selectedOption.value = '';
      // } else {
      //   selectedOption.value = option;
      // }
    };

    // counter offer
    const finalOffer = ref(false);
    const counterOfferPrice = ref();

    watch(() => props.show, () => {
      selectedOption.value = '';
      finalOffer.value = false;
      counterOfferPrice.value = undefined;
    });

    // working
    const sendButtonIcon = computed(() => (props.working ? 'pi pi-spin pi-spinner' : ''));
    const sendResponse = () => {
      emit('respond', { // TODO: enum for negotiation
        ...payload,
        action: selectedOption.value,
        price: counterOfferPrice.value || 0,
        final: finalOffer.value,
      });
    };

    const open = (nOffer: NegotiationOffer, data: any) => {
      console.log('- open: ', nOffer, data);
      payload = data;
      offer.value = nOffer;
      emit('update:show', true);
    };

    return {
      open,
      offer,
      lastOfferIsFinal,
      selectedOption,
      isSelected,
      selectOption,
      // counter offer
      finalOffer,
      counterOfferPrice,
      sendButtonIcon,
      sendResponse,
    };
  },
});
