<template>
  <div class="campaign-creation-step">
    <h4>Briefing</h4>
    <p>
      This section gives creators an understanding of the campaign, a direction to creating the content.
      It helps them understand the content style you’re looking for.
      This section influences the creator's deliverables and the quality of the content.
    </p>

    <div class="p-grid p-mt-6">
      <div class="p-col-12">
        <h6>Campaign Brief</h6>
        <Textarea
          v-model="brief"
          rows="5"
          class="w-100"
          placeholder="Be as detailed as possible..."
          :class="invalidClass('brief')"
        />
      </div>
    </div>

    <div class="p-grid p-mt-6">
      <div class="p-col-2">
        <h6>Content Type</h6>
        <Dropdown
          v-model="contentType"
          :options="contentTypeOptions"
          optionLabel="name"
          optionValue="value"
          placeholder="Select Content Type"
          class="w-100"
          :class="invalidClass('contentType')"
        />
      </div>
      <div class="p-col-2">
        <div class="p-d-flex">
          <h6>Reworks (if needed)</h6>
          <Info text="Everybody makes mistakes, everybody deserves a second chance,
            we give three for you to be fully satisfied with your content"
            style="font-size: 20rem; margin-left: 5rem; margin-top: -4rem;"
          />
        </div>

        <InputNumber
          v-model="reworks"
          :min="0"
          :max="2"
          mode="decimal"
          showButtons
          incrementButtonClass="gi-input-subbutton" decrementButtonClass="gi-input-subbutton"
          placeholder="Reworks count"
          class="w-100"
        />
      </div>
      <div class="p-col-4">
        <h6>Dos</h6>
        <div class="p-grid">
          <div class="p-col-12">
            <div>
              <div class="do-list-item p-inputgroup" v-for="(item, index) in dos" :key="item">
                <div class="text w-100">
                  {{ index + 1 }}. {{ item }}
                </div>
                <Button icon="pi pi-times" class="button p-button-danger" @click="removeDo(item)"/>
              </div>
            </div>

            <div class="p-inputgroup">
              <InputText placeholder="Add a note like 'be original, be you'" v-model="doModel" class="w-100" @keyup.enter="addDo" />
              <Button icon="pi pi-plus" class="p-button-success" @click="addDo" />
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-4">
        <h6>Don'ts</h6>
        <div class="p-grid">
          <div class="p-col-12">
            <div>
              <div class="do-list-item p-inputgroup" v-for="(item, index) in donts" :key="item">
                <div class="text w-100">
                  {{ index + 1 }}. {{ item }}
                </div>
                <Button icon="pi pi-times" class="button p-button-danger" @click="removeDont(item)"/>
              </div>
            </div>

            <div class="p-inputgroup">
              <InputText placeholder="Add a note like 'no racist comments'" v-model="dontModel" class="w-100" @keyup.enter="addDont" />
              <Button icon="pi pi-plus" class="p-button-warning" @click="addDont" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="next p-d-flex p-justify-between p-mt-4">
      <Button
        label="Previous step"
        class="p-button-text p-button-secondary gi-button p-text-uppercase p-mr-6"
        icon="pi pi-angle-left"
        @click="$router.push({ name: 'edit-campaign--creator', params: { campaignID }})"
      />
      <Button
        label="Save and continue"
        :loading="controller.campaign.manager.updatingInProgress.value"
        class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
        icon="pi pi-angle-double-right"
        iconPos="right"
        @click="controller.next"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import { CampaignData } from '@/data/types';
import { CampaignStep, getStep, useCampaignStep } from '@/pages/campaigns/create/modules/campaign-step-module';
import Info from '@/components/Info.vue';

export default defineComponent({
  name: 'CampaignBriefingStep',

  components: {
    Info,
    Button,
    Dropdown,
    Textarea,
    InputText,
    InputNumber,
  },

  props: {
    campaignID: String,
    campaign: Object as PropType<CampaignData>,
    edit: Boolean, // TODO: enum
  },

  setup(props) {
    const campaign = computed<CampaignData | undefined>(() => props.campaign);

    const brief = ref(campaign?.value?.brief || '');
    const contentType = ref(campaign?.value?.contentType || '');
    const reworks = ref(campaign?.value?.reworksCount || 0);
    const doModel = ref('');
    const dontModel = ref('');
    const dos = ref<Array<string>>(campaign?.value?.dos || []);
    const donts = ref<Array<string>>(campaign?.value?.donts || []);

    const contentTypeOptions = [
      { name: 'Video', value: 'video' },
      { name: 'Still', value: 'still' },
      { name: 'Video + Still', value: 'video+still' },
    ];

    const addDo = () => {
      if (doModel.value) {
        dos.value.push(doModel.value); // @ts
        doModel.value = '';
      }
    };
    const removeDo = (item: string) => {
      dos.value.splice(dos.value.indexOf(item), 1);
    };

    const addDont = () => {
      if (dontModel.value) {
        donts.value.push(dontModel.value);
        dontModel.value = '';
      }
    };
    const removeDont = (item: string) => {
      donts.value.splice(donts.value.indexOf(item), 1);
    };

    const step = getStep('edit-campaign--briefing', props.campaignID) as CampaignStep;
    const controller = useCampaignStep(
      step,
      {
        brief,
        contentType,
      },
      props.campaignID,
    );
    controller.setSerializer(() => ({
      contentType: contentType.value,
      brief: brief.value,
      reworksCount: reworks.value,
      dos: dos.value,
      donts: donts.value,
    }));

    return {
      brief,
      contentType,
      contentTypeOptions,
      reworks,

      dos,
      doModel,
      addDo,
      removeDo,

      donts,
      dontModel,
      addDont,
      removeDont,

      // step control
      step,
      controller,
      ...controller.validation,
    };
  },
});
</script>

<style lang="scss" scoped>
.do-list-item {
  padding-left: 10rem;
  margin-bottom: 10rem;
  display: flex;
  align-items: center;
  //background-color: $giDarkGrey;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .text {
    padding: 10rem 0;
  }

  .button {
    //align-self: flex-start;
  }

  &:last-child {
    margin-bottom: 20rem;
  }
}
</style>
