
import { defineComponent, PropType, ref, watch } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import { Brand } from '@/data/types';
import ModalWorkingOverlay from '../../commons/modals/ModalWorkingOverlay.vue';
import PhotoGallery from '../../commons/media/PhotoGallery.vue';

export default defineComponent({
  components: {
    ModalWorkingOverlay,
    Button,
    Dialog,
    PhotoGallery,
    InputText,
    Textarea,
  },

  props: {
    show: Boolean,
    working: Boolean,
    brand: Object as PropType<Brand>,
  },

  emits: ['save', 'update:show'],

  setup(props, { emit }) {
    const photo = ref('');
    const slogan = ref('');
    const description = ref('');

    watch(props, () => {
      if (props.brand) {
        photo.value = props.brand.photo;
        slogan.value = props.brand.slogan;
        description.value = props.brand.description;
      }
    });

    // working
    const saveBrand = () => {
      emit('save', {
        photo: photo.value,
        slogan: slogan.value,
        description: description.value,
      });
    };

    const open = () => emit('update:show', true);

    return {
      saveBrand,
      open,
      photo,
      slogan,
      description,
    };
  },
});
