import { watch } from 'vue';
import { useAPI, useAuth } from '@/modules';
import { AppPagePerspective, User, UserData } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useCreatorManager = (perspective: AppPagePerspective) => {
  const { user } = useAuth();
  const toast = useToastModule();

  // load single item
  const {
    loading,
    error: loadingError,
    execute: loadCreator,
    data: creator,
  } = useAPI<User>('', false);

  const load = (itemID: string) => loadCreator({
    url: `/users/creators/${itemID}`,
    parseResponse: (userData: UserData) => new User(userData, user?.value as User),
  });
  watch(loadingError, toast.handleRequestError);
  // < load single

  // // update
  const {
    loading: updatingInProgress,
    error: updatingError,
    data: updatingResult,
    execute: sendUpdateRequest,
  } = useAPI<User>('', false);

  const update = (userID: string, body: Record<string, any>) => sendUpdateRequest({
    url: `/users/creators/${userID}`,
    method: 'PUT',
    data: body,
    parseResponse: (userData: UserData) => new User(userData, user?.value as User),
  });
  watch(updatingResult, () => {
    if (updatingResult.value) {
      creator.value = updatingResult.value;
      toast.showSuccess('Profile updated successfully!');
    }
  });
  watch(updatingError, toast.handleRequestError);
  // // < update

  return {
    load,
    loading,
    creator,
    // update creator
    update,
    updatingInProgress,
    updatingError,
    updatingResult,
  };
};
