import { Ref } from 'vue';
import { useAPI } from './api-module';

export interface Notification {
  message?: string;
  recipient: string;
  type: string;
}

export const useNotifications = () => {
  const { loading, error, data, execute } = useAPI<Array<Notification>>('/notifications/mine');

  const notifications: Ref<Array<Notification> | undefined> = data;

  return {
    getNotifications: execute,
    loading,
    error,
    notifications,
  };
};
