<template>
  <div>
    <Dialog
      :visible="show"
      class="p-col-12 p-md-6 p-xl-4 gi-dialog"
      :modal="true"
      :dismissableMask="!working"
      :closable="!working"
      @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <div class="w-100 p-text-center">
          <h1>{{ action.charAt(0).toUpperCase() + action.slice(1) }} Deliverable</h1>
          <span class="subtitle">
            To submit the content please insert Links from Dropbox/ Google Drive/ WeTransfer or another platform.
          </span>
        </div>
      </template>

      <div class="p-mt-4 p-mb-4 container">
        <ModalWorkingOverlay v-if="working" />
        <div class="p-grid p-d-flex p-flex-column p-p-4">
          <h6>Content Link</h6>
          <InputText type="text" v-model="link" placeholder="Enter link here" />

          <h6>Content Type</h6>
          <div>
            <RadioButton id="content-type-video" name="content-type-video" value="video" v-model="type" :disabled="action === 'resubmit'" />
            <label for="content-type-video" class="p-mr-4"> Video</label>

            <RadioButton id="content-type-image" name="content-type-image" value="image" v-model="type" />
            <label for="content-type-image"> Image</label>
          </div>

          <h6>Comment</h6>
          <Textarea v-model="comment" rows="5" cols="30" placeholder="Enter comment here if necessary" />
        </div>
      </div>

      <template #footer>
        <div class="p-d-flex p-justify-between p-mt-4">
          <Button
            label="Cancel"
            class="p-button-link p-ml-6"
            :disabled="working"
            @click="$emit('update:show', false)"
          />

          <Button
            :label="`${action.charAt(0).toUpperCase() + action.slice(1)}${working ? 'ting' : ''} deliverable`"
            class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
            :icon="submitButtonIcon"
            :disabled="working || !link || !type"
            @click="submitDeliverable"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  watch,
  computed,
} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import { CampaignDeliverableData } from '@/data/types';
import ModalWorkingOverlay from '@/pages/commons/modals/ModalWorkingOverlay.vue';

export default defineComponent({
  components: {
    ModalWorkingOverlay,
    Button,
    Dialog,
    InputText,
    Textarea,
    RadioButton,
  },

  props: {
    show: Boolean,
    working: Boolean,
  },

  emits: ['submit', 'update:show'],

  setup(props, { emit }) {
    const link = ref('');
    const type = ref();
    const comment = ref('');
    const deliverable = ref<CampaignDeliverableData | null>(null);

    // working
    // FIXME: check button.loading property, along with loadingIcon!!
    const submitButtonIcon = computed(() => (props.working ? 'pi pi-spin pi-spinner' : ''));
    const submitDeliverable = () => {
      emit('submit', {
        link: link.value,
        type: type.value,
        comment: comment.value,
        deliverableID: deliverable.value?.id,
      });
    };

    watch(() => props.show, () => {
      link.value = '';
      type.value = '';
      comment.value = '';
    });

    const action = ref('submit');
    const open = (updateDeliverable?: CampaignDeliverableData) => {
      action.value = updateDeliverable ? 'resubmit' : 'submit';
      if (updateDeliverable) {
        deliverable.value = updateDeliverable;
      }
      emit('update:show', true);
    };

    return {
      submitDeliverable,
      submitButtonIcon,
      link,
      type,
      comment,
      open,
      action,
      deliverable,
    };
  },
});
</script>

<style scoped lang="scss">
.container {
  position: relative;

  .working-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;

    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: .5;
    }

    .spinner {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
