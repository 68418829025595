<template>
  <div class="creators-list p-grid">
    <CreatorListItem
      v-for="user in creators"
      :key="user.id"
      class="p-col-12 p-md-6 p-lg-6 p-xxl-4 p-xxxl-3"
      :user="user"
    />

    <EmptyList v-if="!creators.length" title="No Creators" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { User } from '@/data/types';
import CreatorListItem from '../CreatorListItem.vue';
import EmptyList from '../../commons/list/EmptyList.vue';

export default defineComponent({
  name: 'CampaignsList',

  // eslint-disable-next-line vue/no-unused-components
  components: { CreatorListItem, EmptyList },

  props: {
    creators: {
      type: Array as PropType<Array<User>>,
      default: [],
    },
    select: Function,
  },
});
</script>

<style lang="scss" scoped>
</style>
