
import { defineComponent, PropType, markRaw } from 'vue';
import { CampaignData, Perspective } from '@/data/types';
import EmptyList from '@/pages/commons/list/EmptyList.vue';
import CampaignBox from '../parts/CampaignBox.vue';
import ContractRow from './ContractRow.vue';

export default defineComponent({
  components: { CampaignBox, EmptyList },

  data: () => ({
    ContractRow: markRaw(ContractRow),
  }),

  props: {
    // TODO: reuse
    campaigns: {
      type: Array as PropType<Array<CampaignData>>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
  },
});
