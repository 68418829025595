
import { defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { Campaign } from '@/data/types';
import CampaignListItem from './CampaignListItem.vue';
import EmptyList from '../commons/list/EmptyList.vue';

export default defineComponent({
  name: 'CampaignsList',

  // eslint-disable-next-line vue/no-unused-components
  components: { CampaignListItem, EmptyList },

  props: {
    campaigns: {
      type: Array as PropType<Array<Campaign>>,
      default: [],
    },
    select: Function,
  },

  emits: ['clicked'],

  setup(props, { emit }) {
    const router = useRouter();

    return {
      selectItem(campaign: any) {
        emit('clicked');
        if (props.select) {
          props.select(campaign);
        } else {
          router.push({ name: 'campaign', params: { campaignID: campaign.id } });
        }
      },
    };
  },
});
