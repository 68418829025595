<template>
  <div class="registration-form">
    <SocialConnect
      action="register"
      v-model:connecting="connecting"
      :items="availableSocialConnections"
      :userType="userType"
      @connect="handleUserConnect"
    />
    <UserTypeToggle
      v-model:is-creator="userIsCreator"
      :disabled="!!connecting"
    />

    <div class="link-login">
      Already have an account?
      <br>
      <router-link :to="{ name: 'login' }">Login</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import { User } from '@/data/types';
import { useAuth } from '@/modules/auth-module';
import { useAuthForm } from './modules/auth-form-module';

import { SocialConnectResponse } from './social-def';

import SocialConnect from './components/SocialConnect.vue';
import UserTypeToggle from './components/UserTypeToggle.vue';

export default defineComponent({
  name: 'RegistrationForm',

  components: {
    SocialConnect,
    UserTypeToggle,
  },

  setup() {
    const { loadUser } = useAuth();
    const router = useRouter();
    const { connecting, userIsCreator, userType, availableSocialConnections } = useAuthForm();

    const handleUserConnect = (response: SocialConnectResponse) => {
      console.log('-- user just registered');
      loadUser(response.tokens.access.token)
        .then((success?: User | undefined) => {
          console.log('-- register loading my profile response: ', success);
          if (success) {
            router.push({ name: 'dashboard' });
          } else {
            // TODO: handle somehow?
          }
        });
    };

    return {
      connecting,
      userIsCreator,
      userType,
      availableSocialConnections,
      handleUserConnect,
    };
  },
});
</script>

<style lang="scss">
.registration-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .link-login {
    margin-top: 22rem;
    text-align: center;
  }

  .agreements {
    display: flex;
    margin-top: 30px;

    .terms, .privacy {
      display: flex;
      align-items: center;
    }
  }
}
</style>
