<template>
  <app-page :config="config" :loading="loading">
    <div>
      <ContentItemsList v-if="!loading" :items="contentItems" />
    </div>
  </app-page>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

import { usePage } from '@/modules';
import { useContentItems } from '@/modules/content-items/content-items-module';
import { AppPageConfig, AppPageProps, ContentItem } from '@/data/types';
import AppPage from '../../commons/AppPage.vue';
import ContentItemsList from '../ContentItemsList.vue';

export default defineComponent({
  components: { AppPage, ContentItemsList },

  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    ...AppPageProps,
    private: Boolean,
  },

  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Licensed Items',
      // list: true,
      ...props,
    });

    const items = ref<Array<ContentItem>>([]);

    const page = useContentItems(config.value.viewPerspective);
    const loadPageData = () => page.list
      .loadContentItems(props.private)
      .then((result?: Array<ContentItem>) => {
        items.value = (result || [])
          .filter((item: ContentItem) => !!item.licenseRequests.find((r) => r.status === 'accepted'));
      });

    loadPageData();
    watch(() => props.private, loadPageData);

    return {
      config,
      loading: page.list.loading,
      contentItems: items,
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
