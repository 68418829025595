<template>
  <div>
    <Dialog
      :visible="show"
      class="p-col-12 p-md-6 p-xl-4"
      :modal="true"
      :dismissableMask="!working"
      :closable="!working"
      @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <h3 class="p-m-0 w-100 p-text-left" style="margin-top: 10rem !important;">{{ title }}</h3>
      </template>

      <div class="p-mt-4 p-mb-4" style="position: relative;">
        <ModalWorkingOverlay v-if="working" />
        <slot>
          {{ description }}
        </slot>
      </div>

      <template #footer>
        <div class="p-d-flex p-justify-between p-mt-4">
          <Button
            :label="cancelButtonText"
            class="p-button-link p-ml-6"
            :disabled="working"
            @click="$emit('update:show', false)"
          />

          <Button
            :label="confirmButtonText"
            :loading="working"
            class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
            @click="confirm"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import ModalWorkingOverlay from './ModalWorkingOverlay.vue';

export default defineComponent({
  components: {
    ModalWorkingOverlay,
    Button,
    Dialog,
  },

  props: {
    show: Boolean,
    working: Boolean,
    title: {
      type: String,
      default: 'Confirm?',
    },
    description: String,
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    confirmButtonText: {
      type: String,
      default: 'Accept',
    },
  },

  emits: ['update:show', 'confirm'],
  setup(props, { emit }) {
    const payload = ref<any>(null);

    const confirm = () => {
      emit('confirm', payload.value);
    };

    const open = (data: any) => {
      payload.value = data;
      emit('update:show', true);
    };

    return {
      confirm,
      open,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
