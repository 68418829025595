<template>
  <div class="page-menu-list">
    <template v-for="(item, index) of items" :key="index">
      <router-link
        v-if="item.to"
        :to="item.to"
        :class="[
          item.class,
          'menu-list-item',
          {
            'p-disabled': item.disabled
          },
        ]"
        :style="[
          item.style,
          `--level: ${level};`
        ]"
        :target="item.target"
        role="menuitem"
      >
        <i v-if="item.icon" class="material-icons">{{ item.icon }}</i>
        <span>{{ item.label }}</span>
        <Badge v-if="item.badge" class="badge" :value="item.badge" :severity="item.badgeSeverity || 'success'" />
        <i v-if="item.actionIcon" class="pi-spin material-icons p-ml-2">{{ item.actionIcon }}</i>
        <span
          v-if="item.items"
          :class="[
            'expand-arrow material-icons',
            {
              'opened': isSubmenuOpened(index),
            }
           ]"
          @click="toggleSubmenu(index, $event)"
        >
          expand_more
        </span>
      </router-link>

      <div v-if="item.separator" class="separator"></div>

      <transition name="submenu-transition">
        <PageMenuList v-if="isSubmenuOpened(index) && item.items" :items="item.items" :level="level + 1" />
      </transition>
    </template>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  watch,
} from 'vue';
import Badge from 'primevue/badge';
import { MenuItem } from '@/modules/menu-module';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'PageMenuList',

  components: { Badge },

  props: {
    items: Array as PropType<Array<MenuItem>>,
    level: {
      type: Number,
      default: 1,
    },
  },

  setup(props) {
    const route = useRoute();

    const openedSubmenus = ref<Array<number>>([]);
    const isSubmenuOpened = (index: number) => openedSubmenus.value.includes(index);
    const toggleSubmenu = (index: number, $event?: Event) => {
      const position = openedSubmenus.value.indexOf(index);
      if (position > -1) {
        openedSubmenus.value = openedSubmenus.value.filter((i) => i !== index);
      } else {
        openedSubmenus.value.push(index);
      }
      if ($event) {
        $event.preventDefault();
      }
    };

    onMounted(() => {
      (props.items || []).forEach((item, index) => {
        if (item.items && item.opened) {
          openedSubmenus.value.push(index);
        }
      });
    });

    watch(() => route.name, () => {
      // TODO: this one should open a parent group if you click on it
      // it does not work with programmatic navigation (if you click back and you go to child of this item)
      // make it work :)

      (props.items || []).forEach((item, index) => {
        if (item.to && item.to.name && item.to.name === route.name) {
          if (item.items && !openedSubmenus.value.includes(index)) {
            openedSubmenus.value.push(index);
          }
        }
      });
    });

    return {
      isSubmenuOpened,
      toggleSubmenu,
      openedSubmenus,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-menu-list {
  display: flex;
  flex-direction: column;

  .separator {
    border-top: 1px solid #dee2e6;
    margin: 8rem 0;
  }

  .menu-list-item {
    display: flex;
    height: 38rem;
    align-items: center;
    padding-left: calc(var(--level) * 20rem);
    border-radius: 4rem;

    color: $giDarkGrey;
    font-size: 14rem;
    font-weight: bold;

    transition: color $transitionDuration, background-color $transitionDuration;

    i {
      font-size: 22rem;
      margin-right: 8rem;
    }

    &:hover {
      color: $giRed;
    }

    &.router-link-active {
      color: $giRed;
    }

    &.router-link-exact-active {
      color: $giRed;
      background-color: #F8F9FA;
    }

    .expand-arrow {
      margin-left: auto;
      margin-right: 20rem;
      //border: 1px solid red;
      transition: transform $transitionDuration;

      &.opened {
        transform: rotate(-180deg);
      }
    }

    .badge {
      margin-left: 6rem;
      font-size: 11rem;
      min-width: 18rem;
      height: 18rem;
      line-height: 18rem;
    }
  }

  &.submenu-transition-enter-from,
  &.submenu-transition-leave-to {
    max-height: 0;
  }

  &.submenu-transition-enter-to,
  &.submenu-transition-leave-from {
    max-height: 1000px;
  }

  &.submenu-transition-leave-active {
    overflow: hidden;
    transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
  }

  &.submenu-transition-enter-active {
    overflow: hidden;
    transition: max-height 1s ease-in-out;
  }
}
</style>
