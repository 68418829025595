<template>
  <div class="creator-information-tab">
    <transition name="fade">
      <div v-if="creator">
        <CreatorProfile :creator="creator" class="creator-info" />

        <div v-if="!creator.isMe()">
          <h6>Creator Content Items</h6>
          <ContentItemsList :items="creator.contentItems" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { User } from '@/data/types';
import CreatorProfile from '../CreatorProfile.vue';
import ContentItemsList from '../../../content/ContentItemsList.vue';

export default defineComponent({
  name: 'CreatorInformationTab',

  components: { ContentItemsList, CreatorProfile },

  props: {
    creator: {
      type: Object as PropType<User>,
    },
  },

  setup() {
    return {
    };
  },
});
</script>

<style lang="scss" scoped>
.creator-information-tab {
  .creator-info {
    margin: 80rem 140rem 80rem 140rem;
  }

  .content-items-list {
    margin-top: 40rem;
  }
}
</style>
