
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  watch,
} from 'vue';
import Badge from 'primevue/badge';
import { MenuItem } from '@/modules/menu-module';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'PageMenuList',

  components: { Badge },

  props: {
    items: Array as PropType<Array<MenuItem>>,
    level: {
      type: Number,
      default: 1,
    },
  },

  setup(props) {
    const route = useRoute();

    const openedSubmenus = ref<Array<number>>([]);
    const isSubmenuOpened = (index: number) => openedSubmenus.value.includes(index);
    const toggleSubmenu = (index: number, $event?: Event) => {
      const position = openedSubmenus.value.indexOf(index);
      if (position > -1) {
        openedSubmenus.value = openedSubmenus.value.filter((i) => i !== index);
      } else {
        openedSubmenus.value.push(index);
      }
      if ($event) {
        $event.preventDefault();
      }
    };

    onMounted(() => {
      (props.items || []).forEach((item, index) => {
        if (item.items && item.opened) {
          openedSubmenus.value.push(index);
        }
      });
    });

    watch(() => route.name, () => {
      // TODO: this one should open a parent group if you click on it
      // it does not work with programmatic navigation (if you click back and you go to child of this item)
      // make it work :)

      (props.items || []).forEach((item, index) => {
        if (item.to && item.to.name && item.to.name === route.name) {
          if (item.items && !openedSubmenus.value.includes(index)) {
            openedSubmenus.value.push(index);
          }
        }
      });
    });

    return {
      isSubmenuOpened,
      toggleSubmenu,
      openedSubmenus,
    };
  },
});
