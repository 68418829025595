<template>
  <app-page :config="config" :loading="loading">
    <div class="app-campaign-subpage">
      <div class="filters p-d-flex">
        <div class="filter-campaign p-mr-4">
          <Dropdown
            v-model="selectedCampaignID"
            :options="campaignFilterOptions"
            :filter="true"
            :showClear="true"
            optionLabel="name"
            optionValue="id"
            placeholder="Filter by campaign"
            emptyFilterMessage="No campaign found"
          />
        </div>
        <div class="filter-status">
<!--          <MultiSelect-->
<!--            v-model="selectedStatuses"-->
<!--            :options="statuses"-->
<!--            optionLabel="name"-->
<!--            optionValue="value"-->
<!--            placeholder="Filter by status"-->
<!--          />-->
<!--          display="chip"-->
        </div>
      </div>
      <slot />
    </div>
  </app-page>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  watch,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import { AppCampaignSubpageConfig, CampaignData } from '../../data/types';
import AppPage from './AppPage.vue';

export default defineComponent({
  name: 'AppCampaignSubpage',

  // eslint-disable-next-line vue/no-unused-components
  components: { AppPage, Dropdown, MultiSelect },

  props: {
    config: {
      type: Object as PropType<AppCampaignSubpageConfig>,
      required: true,
    },
    campaignFilterOptions: Array as PropType<Array<CampaignData>>,
    loading: Boolean,
  },

  emits: ['filterByCampaignID', 'filterByStatus'],

  setup(props, { emit }) {
    const selectedCampaignID = ref(null);

    watch(selectedCampaignID, () => {
      emit('filterByCampaignID', selectedCampaignID.value);
    });

    return {
      selectedCampaignID,
    };
  },
});
</script>

<style lang="scss" scoped>
.app-compaign-subpage {
}
</style>
