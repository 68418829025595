
import { defineComponent, PropType } from 'vue';
import Button from 'primevue/button';
import { formatDate } from '@/utils/globals/date-utils';
import {
  CampaignContractData,
  CampaignData,
  CampaignDeliverableData,
  CampaignDeliverableLink,
  Perspective,
} from '@/data/types';
import UserShortPreview from '@/pages/commons/user/UserShortPreview.vue';
import StatusChip from '@/pages/commons/status/StatusChip.vue';
import BrandShortPreview from '@/pages/commons/brand/BrandShortPreview.vue';

export default defineComponent({
  components: {
    BrandShortPreview,
    Button,
    StatusChip,
    UserShortPreview,

  },

  props: {
    setIndex: Number,
    campaign: Object as PropType<Array<CampaignData>>,
    contract: Object as PropType<Array<CampaignContractData>>,
    deliverable: Object as PropType<CampaignDeliverableData>,
    viewPerspective: String as PropType<Perspective>,
  },

  emits: ['resubmit', 'accept', 'reject'],

  setup(props, { emit }) {
    const statusText = (linkSet: CampaignDeliverableLink) => {
      if (props.viewPerspective === 'creator' && linkSet.status === 'rejected') {
        return 'change requested';
      }
      return linkSet.status;
    };

    return {
      formatDate,
      statusText,
    };
  },
});
