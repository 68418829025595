/* eslint-disable */
import { useAPI } from '@/modules/api-module';
import { useAuth } from '@/modules/auth-module';
import { useToastModule } from '@/modules/messages/toast-module';
import { CampaignData, AppPagePerspective, Perspective, ContentItem, LicenseRequestRequest } from '@/data/types';
import { ref, watch } from 'vue';
import { useContentItemsList } from './content-items-list-module';

const { user } = useAuth();

export const useContentItemsRequests = (perspective: AppPagePerspective) => {
  const toast = useToastModule();
  const list = useContentItemsList(perspective);
  const brandID = user?.value?.brand?.id;

  // make a request
  const {
    loading: requestingInProgress,
    error: requestingError,
    data: requestingResult,
    execute: sendLicenseRequest,
  } = useAPI('/content/license-requests', false);

  const request = (itemID: string, payload: LicenseRequestRequest) => {
    if (perspective === Perspective.BRAND && !brandID) {
      throw new Error('Could not make a license request for this item, because brandID is missing!');
    }

    return sendLicenseRequest({
      url: `/content/license-requests/${itemID}`,
      method: 'POST',
      data: {
        brandID,
        ...payload,
      },
    });
  }

  watch(requestingResult, toast.handleRequestSuccess('Content license requested successfully!'));
  watch(requestingError, toast.handleRequestError);
  // < make a request

  // responding to a request
  const {
    loading: responding,
    data: respondingSuccess,
    error: respondingError,
    execute: sendLicenseRequestResponse,
  } = useAPI('', false);

  const respond = (
    itemID: string,
    requestID: string,
    payload: any,
  ) => sendLicenseRequestResponse({
    url: `/content/license-requests/${itemID}/${requestID}`,
    method: 'PUT',
    data: { ...payload, perspective },
  });
  // < responding to a request

  // content items subpage
  const selectedItemID = ref<string>('');
  const requests = ref<Array<ContentItem>>([]);
  // const associatedCampaigns = ref<Array<CampaignData>>([]);

  const {
    loading: requestsLoading,
    error: requestsError,
    data: loadedRequests,
    execute: callGetRequests,
  } = useAPI<Array<CampaignData>>('/content/license-requests/', false);
  // const allCampaigns = useCampaignsList(perspective);

  const loadRequests = () => {
    const creatorID = user?.value?.id;
    const brandID = user?.value?.brand?.id;
    const targetID = perspective === Perspective.BRAND ? brandID : creatorID;

    if (perspective === Perspective.BRAND && !brandID) {
      throw new Error('Could not load license requests for a brand, because brandID is missing!');
    }

    if (perspective === Perspective.BRAND) {
      // allCampaigns.loadCampaigns();
    }

    // return list.loadContentItems();
    return callGetRequests({ params: { perspective, targetID } });
  };

  watch(list.items, () => {
    if (perspective === Perspective.CREATOR) {
      // associatedCampaigns.value = loadedApplicationCampaigns.value || [];
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    filterList();
  });

  // watch(allCampaigns.campaigns, () => {
  //   associatedCampaigns.value = allCampaigns
  //       .campaigns.value?.filter((campaign) => campaign.status !== CampaignStatus.Draft)
  //     || [];
  // });

  // filtering

  const filterList = () => {
    const itemsWithRequests: Array<ContentItem> = list.items
      .value?.filter((item: ContentItem) => item.licenseRequests && item.licenseRequests.length > 0) || [];

    if (selectedItemID.value) {
      requests.value = itemsWithRequests.filter((item) => item.id === selectedItemID.value);
    } else {
      requests.value = itemsWithRequests;
    }
  };

  // const filterByCampaignID = (campaignID = '') => {
  //   selectedCampaignID.value = campaignID;
  //   filterList();
  // };

  return {
    // make a request
    request,
    requestingInProgress,
    requestingError,
    requestingResult,
    // respond to a request
    respond,
    responding,
    respondingSuccess,
    respondingError,
    // list requests
    loadRequests,
    requests: loadedRequests,
    requestsLoading,
    requestsError,
    // applications,
    // loading,
    // finished,
    // loadApplications,
    // filterByCampaignID,
    // associatedCampaigns,
  };
};
