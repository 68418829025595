
import {
  defineComponent,
  ref,
  watch,
  computed,
} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import { CampaignDeliverableData } from '@/data/types';
import ModalWorkingOverlay from '@/pages/commons/modals/ModalWorkingOverlay.vue';

export default defineComponent({
  components: {
    ModalWorkingOverlay,
    Button,
    Dialog,
    InputText,
    Textarea,
    RadioButton,
  },

  props: {
    show: Boolean,
    working: Boolean,
  },

  emits: ['submit', 'update:show'],

  setup(props, { emit }) {
    const link = ref('');
    const type = ref();
    const comment = ref('');
    const deliverable = ref<CampaignDeliverableData | null>(null);

    // working
    // FIXME: check button.loading property, along with loadingIcon!!
    const submitButtonIcon = computed(() => (props.working ? 'pi pi-spin pi-spinner' : ''));
    const submitDeliverable = () => {
      emit('submit', {
        link: link.value,
        type: type.value,
        comment: comment.value,
        deliverableID: deliverable.value?.id,
      });
    };

    watch(() => props.show, () => {
      link.value = '';
      type.value = '';
      comment.value = '';
    });

    const action = ref('submit');
    const open = (updateDeliverable?: CampaignDeliverableData) => {
      action.value = updateDeliverable ? 'resubmit' : 'submit';
      if (updateDeliverable) {
        deliverable.value = updateDeliverable;
      }
      emit('update:show', true);
    };

    return {
      submitDeliverable,
      submitButtonIcon,
      link,
      type,
      comment,
      open,
      action,
      deliverable,
    };
  },
});
