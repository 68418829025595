<template>
  <div class="contracts-list">
    <CampaignBox
      v-for="campaign in campaigns"
      :key="campaign.id"
      :viewPerspective="viewPerspective"
      :campaign="campaign"
      :campaignBoxClass="ContractRow"
      campaignBoxType="contract"
      v-bind="$attrs"
    />

    <EmptyList
      v-if="!campaigns.length"
      class="p-mt-6"
      title="No Submissions"
      text="Have you applied a campaign filter?"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, markRaw } from 'vue';
import { CampaignData, Perspective } from '@/data/types';
import EmptyList from '@/pages/commons/list/EmptyList.vue';
import CampaignBox from '../parts/CampaignBox.vue';
import ContractRow from './ContractRow.vue';

export default defineComponent({
  components: { CampaignBox, EmptyList },

  data: () => ({
    ContractRow: markRaw(ContractRow),
  }),

  props: {
    // TODO: reuse
    campaigns: {
      type: Array as PropType<Array<CampaignData>>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
  },
});
</script>
