<template>
  <div class="content-list-item" @click="$router.push({ name: 'content-item', params: { itemID: item.id } })">
    <div class="background" :style="`background-image:url('${item.thumbnails.medium.url}');`"></div>
    <div class="dates">
      <div class="added">{{ formatDate(item.publishedAt) }}</div>
    </div>
    <div class="kpis p-grid p-nogutter">
      <div class="p-col-4">
        <div class="kpis-title">Views</div>
        <div class="kpis-number">{{ Math.round(Math.random() * 10000) }}</div>
      </div>
      <div class="p-col-4">
        <div class="kpis-title">Likes</div>
        <div class="kpis-number">{{ Math.round(Math.random() * 10000) }}</div>
      </div>
      <div class="p-col-4">
        <div class="kpis-title">Comments</div>
        <div class="kpis-number">{{ Math.round(Math.random() * 10000) }}</div>
      </div>
    </div>
    <div class="info">
      <div class="user">
        <img :src="item.owner.profile.photo" referrerpolicy="no-referrer" alt="" />
      </div>
      <div class="names">
        <h5 class="content-title">{{ item.title }}</h5>
        <div class="user-name">{{ item.owner.name }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { ContentItem } from '@/data/types';
import { formatDate } from '@/utils/globals/date-utils';

export default {
  name: 'ContentItemsListItem',

  components: {},

  props: {
    item: {
      type: Object as PropType<ContentItem>,
      required: true,
    },
  },
  data: () => ({}),

  methods: {
    getImageUrl(index: number) {
      return `/images/content/${index}.jpg`;
    },
    formatDate,
  },
};
</script>

<style lang="scss" scoped>
.content-list-item {
  position: relative;
  cursor: pointer;

  &:hover {
    .background {
      opacity: 0.9;
    }
    .kpis {
      opacity: 1;
    }
  }

  .background {
    height: 230px;
    background-size: cover;
    border-radius: 15px;
    margin-bottom: 10px;
    z-index: 3;

    transition: opacity .3s ease;
  }

  .dates {
    position: absolute;
    top: 16rem;
    left: 16rem;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    padding: 4rem 8rem;
    pointer-events: none;

    @include font-montserrat();
    font-size: 12rem;
    color: #162020;
  }

  .kpis {
    position: absolute;
    padding: 0 40rem;
    left: 8rem;
    bottom: 60rem;
    width: 100%;
    opacity: 0;
    transition: opacity .3s ease;

    &-title {
      font-size: 12rem;
      color: #B9BCBC;
    }

    &-number {
      margin-top: 6rem;
      font-size: 16rem;
      font-weight: bold;
      color: #F8F9FA;
    }
  }

  .info {
    display: flex;

    .user, .user img {
      width: 36px;
      height: 36px;
      border-radius: 5px;
      img {
      }
    }

    .names {
      padding-left: 14rem;
      @include font-montserrat();

      .content-title {
        margin: 0;
      }

      .user-name {
        font-size: 12px;
        color: rgba(24, 24, 24, 0.5); // FIXME:
        font-weight: 400;
      }
    }
  }
}
</style>
