
import { defineComponent, computed } from 'vue';
import InputSwitch from 'primevue/inputswitch';

export default defineComponent({
  name: 'UserTypeToggle',
  components: { InputSwitch },

  props: {
    isCreator: Boolean,
    disabled: Boolean,
  },

  emits: ['update:isCreator'],

  setup(props, { emit }) {
    const selection = computed({
      get: () => props.isCreator,
      set: (newValue) => {
        emit('update:isCreator', newValue);
      },
    });

    return {
      selection,
    };
  },
});
