<template>
  <div class="auth app-page">
    <img class="explosions" alt="game explosions" src="../../assets/images/auth/explosions.png">
    <img class="splash" alt="game creative character" src="../../assets/images/auth/splash.png">
    <img class="controller" alt="game creative controller" src="../../assets/images/auth/controller.png">

    <Card class="auth-card">
      <template #title>
        <div class="logo">
          <router-link :to="{ name: 'landing' }">
            <img alt="Vue logo" src="../../assets/images/logo.png">
          </router-link>
        </div>
      </template>
      <!-- // title -->

      <template #content>
        <router-view />
      </template>
    </Card>
    <img class="character" alt="game creative character" src="../../assets/images/auth/login-character.png">
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Card from 'primevue/card';

export default defineComponent({
  name: 'Auth',

  components: { Card },
});
</script>

<style scoped lang="scss">
.auth {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 0;

  .splash, .character, .explosions, .controller {
    position: absolute;
    pointer-events: none;
  }
  .splash, .character {
    left: 50%;
    transform: translateX(-75%);
  }
  .splash {
    top: 10%;
  }
  .explosions {
    left: 50%;
    transform: translateX(-20%);
  }
  .controller {
    left: 50%;
    transform: translateX(-5%) translateY(25%);
  }

  &-card {
    width: 450rem;

    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(24, 24, 24, 0.15);
    box-sizing: border-box;
    backdrop-filter: blur(25px);
    border-radius: 10px;

    .p-card-title .logo {
      text-align: center;
    }
    .logo {
      margin-bottom: 50rem;
    }
  }

  ::v-deep(.p-card-body) {
    padding: 40rem 70rem;
  }

  .p-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
}
</style>
