<template>
  <div class="campaigns-list p-grid">
    <CampaignListItem
      v-for="item in campaigns"
      :key="item.id"
      class="p-col-12 p-md-6 p-lg-6 p-xl-6 p-xxl-4 p-xxxl-3"
      :item="item"
      @click="selectItem(item)"
    />

    <EmptyList v-if="!campaigns.length" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { Campaign } from '@/data/types';
import CampaignListItem from './CampaignListItem.vue';
import EmptyList from '../commons/list/EmptyList.vue';

export default defineComponent({
  name: 'CampaignsList',

  // eslint-disable-next-line vue/no-unused-components
  components: { CampaignListItem, EmptyList },

  props: {
    campaigns: {
      type: Array as PropType<Array<Campaign>>,
      default: [],
    },
    select: Function,
  },

  emits: ['clicked'],

  setup(props, { emit }) {
    const router = useRouter();

    return {
      selectItem(campaign: any) {
        emit('clicked');
        if (props.select) {
          props.select(campaign);
        } else {
          router.push({ name: 'campaign', params: { campaignID: campaign.id } });
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
