<template>
  <div class="landing-page">
    <div class="logo">
      <img alt="Logo" src="../assets/images/logo.png" />
    </div>

    <div class="auth">
      <Button label="Login" class="p-button-link" @click="$router.push({ name: 'login' })" />
      <Button label="Register" class="p-button-link" @click="$router.push({ name: 'register' })" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Button from 'primevue/button';

export default defineComponent({
  name: 'LandingPage',
  components: { Button },
});
</script>

<style scoped lang="scss">
  .landing-page {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      margin-top: 50px;
    }
  }
</style>
