<template>
  <app-page :config="config" :loading="loading">
    <div>
      <LicenseRequestsList
        v-if="!loading"
        :items="requests"
        :viewPerspective="viewPerspective"
        @respond="openRequestModal"
      />

      <RespondToNegotiationModal
        v-model:show="showResponseModal"
        title="Respond to application"
        ref="respondModalRef"
        :working="controller.requests.responding.value"
        @respond="handleRequestModalResponse"
      />
    </div>
  </app-page>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useToast } from 'primevue/usetoast';

import { usePage } from '@/modules';
import { useContentItems } from '@/modules/content-items/content-items-module';
import RespondToNegotiationModal from '@/pages/commons/modals/RespondToNegotiationModal.vue';
import { AppPageConfig, AppPageProps } from '@/data/types';
import AppPage from '../../commons/AppPage.vue';
import LicenseRequestsList from './LicenseRequestsList.vue';

export default defineComponent({
  components: { RespondToNegotiationModal, LicenseRequestsList, AppPage },

  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    ...AppPageProps,
    private: Boolean,
  },

  setup(props) {
    const toast = useToast();

    const { config } = usePage<AppPageConfig>({
      title: 'License Requests',
      // list: true,
      ...props,
    });

    const controller = useContentItems(config.value.viewPerspective);
    const loadPageData = () => controller.requests.loadRequests();

    loadPageData();
    watch(() => props.private, loadPageData);

    // responding to a request
    const showResponseModal = ref(false);
    const respondModalRef = ref<InstanceType<typeof RespondToNegotiationModal>>();
    const openRequestModal = ({ item, request, offer }: any) => {
      if (respondModalRef.value) {
        respondModalRef.value.open(offer, { item, request });
      }
    };
    const handleRequestModalResponse = ({ item, request, ...payload }: any) => {
      const { action } = payload;

      controller.requests
        .respond(item.id, request.id, payload)
        .then((success) => {
          if (success) {
            loadPageData(); // refresh list

            showResponseModal.value = false;

            let successMessage = '';
            if (action === 'accept') {
              successMessage = 'License request successfully accepted!';
            } else if (action === 'reject') {
              // successMessage = 'Application successfully rejected!';
            } else if (action === 'counter') {
              // successMessage = 'Counter offer sent successfully!';
            }

            toast.add({
              severity: 'success',
              summary: 'Success',
              detail: successMessage,
              life: 3000,
            });
          }
        });
    };

    return {
      config,
      controller,
      loading: controller.requests.requestsLoading,
      requests: controller.requests.requests,
      // responding
      respondModalRef,
      showResponseModal,
      openRequestModal,
      handleRequestModalResponse,
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
