
import { defineComponent, PropType } from 'vue';

import { User } from '@/data/types';
import CreatorProfile from '../CreatorProfile.vue';
import ContentItemsList from '../../../content/ContentItemsList.vue';

export default defineComponent({
  name: 'CreatorInformationTab',

  components: { ContentItemsList, CreatorProfile },

  props: {
    creator: {
      type: Object as PropType<User>,
    },
  },

  setup() {
    return {
    };
  },
});
