
import { defineComponent, watch } from 'vue';
import { AppPageConfig, AppPageProps } from '@/data/types';
import { usePage, useCampaigns } from '@/modules';
import AppPage from '@/pages/commons/AppPage.vue';
import CampaignsList from './CampaignsList.vue';

export default defineComponent({
  name: 'Campaigns',

  components: { AppPage, CampaignsList },

  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 0,
    },
    brandOwnerView: Boolean,
    ...AppPageProps,
    private: Boolean,
  },

  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Campaigns',
      // list: true,
      ...props,
    });

    const page = useCampaigns(config.value.viewPerspective);
    const loadPageData = () => page.list.loadCampaigns(props.private, props.limit);

    loadPageData();
    watch(() => props.private, loadPageData);

    return {
      config,
      loading: page.list.loading,
      campaigns: page.list.campaigns,
    };
  },
});
