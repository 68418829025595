<template>
  <Chip
    :label="text || status"
    class="status-chip chip"
    :data-status="status"
  />
</template>

<script lang="ts">
import Chip from 'primevue/chip';

import { defineComponent } from 'vue';

export default defineComponent({
  components: { Chip },
  props: {
    text: String,
    status: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.status-chip {
  @include font-montserrat();
  font-weight: 600;
  font-size: 12rem;
  text-transform: uppercase;
  padding: 0 14rem;

  ::v-deep(.p-chip-text) {
    line-height: 18rem;
    margin: 0;
    color: #fff;
  }

  &[data-status="active"], &[data-status="accepted"] {
    background-color: #4EC461;
  }
  &[data-status="pending"] {
    background-color: #756C93;
  }
  &[data-status="draft"] {
    background-color: #F5B74E;
  }
  &[data-status="completed"], &[data-status="finished"], &[data-status="rejected"] {
    background-color: #F54E5D;
  }
}
</style>
