<template>
  <div class="creator-list-item">
<!--    @click="scrapeChannel(user.youtube.id)"-->
    <div class="box">
      <div class="avatar">
        <UserPhoto :user="user" style="width: 100%; height: 100%;" />
      </div>
      <span v-if="user.isAdmin()" class="material-icons admin p-absolute" title="Admin">verified</span>
      <div class="info">
        <div class="name">
          {{ user.name }}
        </div>
        <div class="stats">
<!--          <div class="views">{{ user.youtube?.stats.views || Math.round(Math.random() * 10000) }} views</div>-->
          <div class="subs">{{ user.youtube?.followers }} followers</div>
        </div>
      </div>
    </div>
    <div class="link">
      <router-link :to="{ name: 'creator', params: { creatorID: user.id } }">
        Go to profile <i class="pi pi-angle-right"></i>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { User } from '@/data/types';
import UserPhoto from '@/pages/commons/user/UserPhoto.vue';

export default {
  name: 'CreatorListItem',
  components: { UserPhoto },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async scrapeChannel(channelID: string) {
      console.log('-- scrape disabled :(');
      // eslint-disable-next-line no-underscore-dangle
      // const videos = await HTTPService.get().get(`http://localhost:3000/v1/content/scrape/${this.item._id}/${channelID}`);
      // console.log('videos: ', videos);
    },
  },
};
</script>

<style lang="scss" scoped>
.creator-list-item {
  margin-bottom: 30rem;

  .box {
    background: #F8F8F8;
    border: 1px solid #EEEEEE;
    border-radius: 15px;
    display: flex;
    padding: 14px;
    position: relative;

    .admin {
      position: absolute;
      top: 6rem;
      right: 6rem;
      user-select: none;
    }

    .avatar {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      flex-shrink: 0;
      background: #C4C4C4;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
      margin-right: 28px;

      img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
      }
    }

    .info {
      width: 100%;

      .name {
        @include font-montserrat();
        font-weight: bold;
        font-size: 14px;

        height: 34px;
      }

      .stats {
        display: flex;
        width: 100%;

        .views, .subs {
          flex: 1;
          @include font-montserrat();
          font-size: 12rem;
          font-weight: 600;
          color: rgba(24, 24, 24, 0.5);;
        }
      }
    }
  }

  .link a {
    @include font-montserrat();
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    padding-right: 14px;
    margin-top: 6px;
    color: #181818;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    transition: opacity .3s ease;

    &:hover {
      opacity: .5;
    }
  }
}
</style>
