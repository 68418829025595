<template>
  <div class="creator-profile">

    <Dialog
      header="Campaign Invitation"
      v-model:visible="showCampaignInvitationModal"
      class="login-card p-col-12 p-md-6 p-xl-4 gi-dialog"
      :closable="!sendingInvite"
      :dismissableMask="!sendingInvite"
      :modal="true"
    >
      <div class="campaigns-list p-mt-6 p-mb-4">
<!--        TODO: check if brand has campaign and if not - show message here-->
        <p>Select a campaign that you'd like to invite this user to:</p>
        <div class="p-d-flex p-justify-center">
          <Dropdown
            v-model="selectedCampaignID"
            :options="campaignInviteDropdownOptions"
            :filter="true"
            :showClear="true"
            optionLabel="name"
            optionValue="id"
            optionDisabled="disabled"
            placeholder="Select campaign"
            emptyFilterMessage="No campaign found"
            style="width: 50%;"
          />
        </div>
      </div>
      <template #footer>
        <div class="p-d-flex p-jc-between p-mt-4">
          <Button
            label="Cancel"
            class="p-button-link"
            @click="showCampaignInvitationModal = false"
          />
          <Button
            label="Send Invitation"
            class="gi-button"
            :icon="sendInviteButtonIcon"
            :disabled="!selectedCampaignID || sendingInvite"
            @click="sendInvite"
          />
        </div>
      </template>
    </Dialog>

    <div class="top p-grid">
      <div class="p-col-3">
        <UserPhoto :user="creator" class="creator-photo" />
      </div>

      <div class="name p-col-3">
        <h3>{{ creator.name }}</h3>
      </div>

      <div class="actions p-col-6">
        <Button
          v-if="creator.isMe()"
          label="Edit Profile Info"
          class="p-button-link action-button"
          icon="pi pi-pencil"
        />

        <Button
          v-if="user.isBrandOwner()"
          label="Invite to Campaign"
          class="action-button p-button-danger"
          icon="pi pi-user-plus"
          @click="showCampaignInvitationModal = true"
        />
      </div>
    </div>

    <div class="description">
      <h6>DESCRIPTION</h6>
      <p>{{ creator.profile.description }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import { useToast } from 'primevue/usetoast';
import { useAuth } from '@/modules/auth-module';
import { useAPI } from '@/modules/api-module';
import { APIError } from '@/utils/globals/error-utils';
import { User, CampaignData, CampaignInvitationData } from '../../../data/types';

import UserPhoto from '../../commons/user/UserPhoto.vue';

export default defineComponent({
  name: 'CreatorProfile',

  components: {
    UserPhoto,
    Button,
    Dialog,
    Dropdown,
  },

  props: {
    creator: {
      type: Object as PropType<User>,
    },
  },

  setup(props) {
    const { user } = useAuth();

    const showCampaignInvitationModal = ref(false);
    const selectedCampaignID = ref(null);
    const campaignInviteDropdownOptions = ref<Array<any>>();

    const { data: brandCampaigns, execute: getCampaigns } = useAPI<Array<CampaignData>>(`/campaigns/brand/${user?.value?.brand?.id}`, {
      parseResponse: (data: Array<CampaignData>) => {
        campaignInviteDropdownOptions.value = data.map((campaign) => { // TODO: watcher?
          const result: any = {
            id: campaign.id,
            name: campaign.name,
            disabled: false,
          };
          const invitation: CampaignInvitationData | undefined = (campaign.invitations || [])
            .find((i: CampaignInvitationData) => i.creator.id === props.creator?.id);

          if (invitation) {
            result.name = `${result.name} (already invited)`;
            result.optionDisabled = true;
          }
          return result;
        });

        console.log('---- list: ', campaignInviteDropdownOptions.value);
        return data;
      },
    }, false);

    if (user?.value?.isBrandOwner()) {
      getCampaigns();
    }

    const toast = useToast();
    const {
      data: sendInviteSuccess,
      error: sendInviteError,
      loading: sendingInvite,
      execute: callSendInvite,
    } = useAPI('', false);
    const sendInviteButtonIcon = computed(() => (sendingInvite.value ? 'pi pi-spin pi-spinner' : 'pi pi-check'));
    const sendInvite = () => {
      callSendInvite({
        url: `/campaigns/invitations/${selectedCampaignID.value}/${props.creator?.id}`,
        method: 'POST',
      });
    };

    watch(sendInviteError, (err?: APIError) => {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    });

    watch(sendInviteSuccess, () => {
      if (sendInviteSuccess.value) {
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: `User ${props.creator?.name} invited successfully!`,
          life: 3000,
        });
        showCampaignInvitationModal.value = false;
        getCampaigns();
      }
    });

    watch(showCampaignInvitationModal, () => {
      if (!showCampaignInvitationModal.value) {
        selectedCampaignID.value = null;
      }
    });

    return {
      user,
      showCampaignInvitationModal,
      campaignInviteDropdownOptions,
      brandCampaigns,
      selectedCampaignID,
      sendInvite,
      sendInviteButtonIcon,
      sendingInvite,
    };
  },
});
</script>

<style lang="scss" scoped>
.creator-profile {

  .top {
    display: flex;

    .creator-photo ::v-deep(.default) {
      font-size: 64rem;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      .action-button {
        align-self: flex-end;
        @include font-montserrat();
        font-weight: 800;
        font-size: 14rem;
        line-height: 17rem;
        text-transform: uppercase;

        &.p-button-link {
          color: #E0010B;
        }
      }
    }
  }

  .slogan {
    margin-top: 90rem;
  }

  .description {
    margin-top: 50rem;
  }

  .slogan p, .description p {
    color: #888E95;
  }
}
</style>
