
import { defineComponent, PropType } from 'vue';
import { Campaign, CampaignData } from '../../../data/types';

export default defineComponent({
  name: 'CampaignPhoto',

  props: {
    campaign: {
      type: Object as PropType<Campaign | CampaignData>,
    },
  },
});
