/* eslint-disable import/no-cycle */
import { BrandData } from './brand-types';
import { UserData } from './user-types';
import { NegotiationOffer } from './negotiation-types';

export enum CampaignStatus {
  Draft = 'draft',
  Active = 'active',
  Live = 'live',
  Completed = 'completed',
}

export interface CampaignPlatform {
  platform: string;
  minFollowers: number;
  maxFollowers: number;
}

export interface CampaignRights {
  platforms: string;
  duration: string;
  regions: Array<string>;
}

export interface CampaignData {
  id: string;
  name: string;
  dates: {
    startDate: string;
    endDate: string;
  };
  goal: string;
  creatorRequirements: {
    gender: string;
    audience: {
      minAge: number;
      maxAge: number;
    };
    country: Array<string>;
    language: string;
  };
  platforms: Array<CampaignPlatform>;

  contentType: string;
  brief: string;
  reworksCount: number;
  dos: Array<string>;
  donts: Array<string>;

  budget: number;
  rights: CampaignRights;

  photo: string;

  invitations?: Array<CampaignInvitationData>;
  applications?: Array<CampaignApplicationData>;
  contracts?: Array<CampaignContractData>;

  brand: string | BrandData;
  // creator
  status: CampaignStatus;
}

export interface Campaign { // FIXME: remove!
  id: string;
  name: string;
  description: string;
  photo: string;

  // brand
  // creator
  status: CampaignStatus;
}

/// INVITATIONS
export interface CampaignInvitationData {
  id: string;
  creator: UserData;
  status: string;
  createdAt: string;
}

/// APPLICATIONS
export interface CampaignApplicationData {
  id: string;
  creator: UserData;
  status: string; // TODO: enum
  createdAt?: string;
  updatedAt?: string;
  offers: Array<NegotiationOffer>;
}

/// CONTRACTS
export interface CampaignContractData {
  id: string;
  creator: UserData;
  status: string; // TODO: enum
  price: number;
  createdAt?: string;
  updatedAt?: string;
  deliverables: Array<CampaignDeliverableData>;
}

export interface CampaignDeliverableComment {
  id: string;
  from: string; // creator/brand // TODO: enum
  text: string;
}

export interface CampaignDeliverableLink {
  id: string;
  status: string; // TODO: enum
  link: string;
  comments?: Array<CampaignDeliverableComment>;
}

export interface CampaignDeliverableData {
  id: string;
  status: string; // TODO: enum
  links?: Array<CampaignDeliverableLink>;
  createdAt?: string;
  updatedAt?: string;
  type?: 'image' | 'video';
}

// export class CampaignInvitation {
//   public readonly id: string;
//   public readonly creator: User;
//
//   constructor(data: CampaignInvitationData) {
//     this.id = data.id;
//     this.creator = new User(data.creator);
//   }
// }
