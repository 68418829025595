<template>
  <app-page :config="config">
    <div class="campaign" v-if="campaign">

      <Dialog
        header="Apply"
        v-model:visible="showCampaignApplyModal"
        class="p-col-12 p-md-6 p-xl-4"
        :modal="true"
        :dismissableMask="!applyingInProgress"
        :closable="!applyingInProgress"
      >
        <template #header>
          <h2>Apply for this campaign</h2>
        </template>

        <div>
          <p>Do you want to apply for the <strong>{{ campaign.name }}</strong> Campaign with the following details:</p>

          <div class="p-grid p-mt-6">
            <div class="p-col-6">
              <h6>Campaign Period</h6>
              <h5>{{ formatDate(campaign.dates.startDate) }} - {{ formatDate(campaign.dates.endDate) }}</h5>

              <h6>Content Type</h6>
              <h5>{{ campaign.contentType }}</h5>

              <h6>Reworks (if necessary)</h6>
              <h5>{{ campaign.reworksCount }} Rework{{ campaign.reworksCount > 1 ? 's' : '' }}</h5>
            </div>

            <div class="p-col-6">
              <h6>Budget</h6>
              <h5 v-if="campaignIsFixedPrice">Fixed: ${{ campaign.budget }}</h5>
              <div v-else>
                <p>The campaign is <strong>open budget</strong>, meaning you must provide your price when applying.</p>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">$</span>
                  <InputNumber
                    v-model="applicationOfferPrice"
                    placeholder="Enter your offer here"
                    showButtons
                    aria-describedby="apply-price-description"
                    :min="0"
                  />
                </div>
                <small id="apply-price-description" class="p-ml-2">* Price does not include VAT</small>

                <div class="p-mt-4">
                  <Checkbox id="applicationFinal" v-model="applicationFinal" :binary="true" />
                  <label for="applicationFinal" class="p-ml-2">Make this my final offer</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template #footer>
          <div class="p-d-flex p-justify-end p-mt-4">
            <Button
              label="Cancel"
              class="p-button-link"
              :disabled="applyingInProgress"
              @click="showCampaignApplyModal = false"
            />
            <Button
              label="Apply"
              class="p-button-text p-button-danger gi-button p-text-uppercase"
              :icon="applyButtonIcon"
              :disabled="applyButtonDisabled"
              @click="sendApply"
            />
          </div>
        </template>
      </Dialog>

      <Dialog
        v-model:visible="showCampaignWithdrawModal"
        class="p-col-12 p-md-6 p-xl-4"
        :modal="true"
        :dismissableMask="!withdrawingInProgress"
        :closable="!withdrawingInProgress"
      >
        <template #header>
          <h2>Withdraw Application</h2>
        </template>

        <div>
          <p>Are you sure you want to withdraw your application for <strong>{{ campaign.name }}</strong> campaign?</p>
        </div>

        <template #footer>
          <div class="p-d-flex p-justify-end p-mt-4">
            <Button
              label="Cancel"
              class="p-button-link"
              :disabled="withdrawingInProgress"
              @click="showCampaignWithdrawModal = false"
            />
            <Button
              label="Withdraw"
              class="p-button-text p-button-danger gi-button p-text-uppercase"
              :icon="withdrawButtonIcon"
              :disabled="withdrawingInProgress"
              @click="sendWithdraw"
            />
          </div>
        </template>
      </Dialog>

      <Dialog
        v-model:visible="showCampaignRejectInviteModal"
        class="p-col-12 p-md-6 p-xl-4"
        :modal="true"
        :dismissableMask="!rejectingInvitationInProgress"
        :closable="!rejectingInvitationInProgress"
      >
        <template #header>
          <h2>Reject Invitation</h2>
        </template>

        <div>
          <p>Are you sure you want to reject the invitation for <strong>{{ campaign.name }}</strong> campaign?</p>
        </div>

        <template #footer>
          <div class="p-d-flex p-justify-end p-mt-4">
            <Button
              label="Cancel"
              class="p-button-link"
              :disabled="rejectingInvitationInProgress"
              @click="showCampaignRejectInviteModal = false"
            />
            <Button
              label="Reject"
              class="p-button-text p-button-danger gi-button p-text-uppercase"
              :icon="rejectInvitationButtonIcon"
              :disabled="rejectingInvitationInProgress"
              @click="sendRejectInvitation"
            />
          </div>
        </template>
      </Dialog>

<!--      <ConfirmModal-->
<!--        title="Mark the campaign as completed?"-->
<!--        ref="finishCampaignModalRef"-->
<!--        confirmButtonText="Finish"-->
<!--        v-model:show="showingFinishCampaignModal"-->
<!--        :working="completing"-->
<!--        @confirm="handleFinishCampaignModalAccept"-->
<!--      >-->
<!--        Finishing the campaign means you're satisfied with the results of all deliverables.-->
<!--        If you do so, creators won't be able to submit new or edit old deliverables,-->
<!--        and payment process would be started.-->
<!--        <br /><br />-->
<!--        Are you sure you'd like to complete this campaign?-->
<!--      </ConfirmModal>-->

      <div class="intro p-grid">
        <div class="p-col-3">
          <CampaignPhoto :campaign="campaign" />
        </div>

        <div class="p-col-4">
          <div class="p-d-flex p-flex-column">
            <div class="status">
              <CampaignStatusChip :campaign="campaign" />
            </div>
          </div>
          <h3>{{ campaign.name }}</h3>
        </div>

        <div class="p-col-5">
          <div class="budget">
            <h6>Budget</h6>
            <h5 class="p-d-flex p-align-center">
              <span class="material-icons p-mr-2">sell</span>
              {{ campaignIsFixedPrice ? `$${campaign.budget}` : 'open budget' }}
            </h5>
          </div>
          <div class="actions">
            <div v-if="campaignInvitation && !campaignApplication">
              <h6>Invitation</h6>
              <p>Invited on {{ formatDate(campaignInvitation.createdAt) }}</p>
              <div v-if="campaignInvitation.status === 'pending'" class="invitation-pending">
                <p>
                  You are invited to this campaign. Use the buttons below to either apply, or reject the invitation.
                </p>
                <Button
                  label="Reject invitation"
                  class="action-button p-button-link p-button-danger"
                  @click="showCampaignRejectInviteModal = true"
                />
              </div>
              <div v-else>
                <p v-if="campaignInvitation.status === 'rejected'">
                  Invitation <strong>rejected</strong> on {{ formatDate(campaignInvitation.updatedAt) }}
                </p>
              </div>
              <hr>
            </div>
            <!-- // invitation -->

<!--            campaignApplication: <pre>{{ campaignApplication }}</pre>-->
<!--            campaignInvitation: {{ campaignInvitation }}-->

            <div class="apply">
              <Button
                label="Apply for this campaign"
                class="action-button p-button-link"
                v-if="user?.isCreator() && !campaignApplication && campaign.status === CampaignStatus.Active"
                @click="showCampaignApplyModal = true"
              />
              <div v-if="campaignApplication">
                <h6>Application</h6>
                <p>Applied on {{ formatDate(campaignApplication.createdAt) }}</p>
<!--                FIXME: enum campaign application status-->
                <Button
                  v-if="campaignApplication.status === 'active'"
                  label="Withdraw application"
                  class="action-button p-button-link p-button-danger"
                  icon="pi pi-times"
                  @click="showCampaignWithdrawModal = true"
                />
                <div v-if="campaignApplication.status === 'withdrawn'">
                  <p>Application withdrawn on {{ formatDate(campaignApplication.updatedAt) }}</p>
                </div>

                <div v-if="campaignApplication.status === 'accepted'">
                  <p>Application accepted on {{ formatDate(campaignApplication.updatedAt) }}</p>
<!--                  <strong>view contract btn [TODO]</strong>-->
                </div>

                <div v-if="campaignApplication.status === 'rejected'">
                  <p>Application <strong>rejected</strong> on {{ formatDate(campaignApplication.updatedAt) }}</p>
                </div>
              </div>
            </div>
            <!-- // apply -->

            <div v-if="isMyCampaign">
              <h6>Manage</h6>
<!--              <Button-->
<!--                v-if="isMyCampaign && campaign.status !== CampaignStatus.Draft"-->
<!--                label="Deactivate campaign"-->
<!--                class="action-button p-button-danger"-->
<!--                icon="pi pi-times"-->
<!--              />-->
              <Button
                v-if="isMyCampaign && campaign.status === CampaignStatus.Draft"
                label="Edit campaign"
                class="action-button p-button-danger"
                icon="pi pi-pencil"
                @click="$router.push({ name: 'edit-campaign--details', params: { campaignID: campaign.id } })"
              />
              <br>
              <Button
                v-if="isMyCampaign && campaign.status === CampaignStatus.Draft"
                label="Publish campaign"
                class="action-button p-button-danger p-mt-3"
                icon="pi pi-forward"
                :disabled="publishing || discarding"
                :loading="publishing"
                @click="publishCampaign"
              />
              <br>
              <Button
                v-if="isMyCampaign && campaign.status === CampaignStatus.Draft"
                label="Discard campaign"
                class="action-button p-button-danger p-mt-3"
                icon="pi pi-times"
                :disabled="discarding || publishing"
                :loading="discarding"
                @click="discardCampaign"
              />
<!--              <br>-->
<!--              <Button-->
<!--                v-if="isMyCampaign && campaign.status == CampaignStatus.Active"-->
<!--                label="Complete campaign"-->
<!--                class="action-button p-button-danger p-mt-3"-->
<!--                icon="pi pi-check"-->
<!--                :disabled="discarding || publishing || completing"-->
<!--                :loading="completing"-->
<!--                @click="showingCompleteCampaignModal = true"-->
<!--              />-->
              <hr>
            </div>
            <!-- // manage -->
          </div>
        </div>
      </div>
      <!-- // intro -->

      <div class="p-grid section">
        <div class="p-col-4">
          <h6>Campaign Period</h6>
          <h5>{{ formatDate(campaign.dates.startDate) }} - {{ formatDate(campaign.dates.endDate) }}</h5>
        </div>
        <div class="p-col-8">
          <h6>Campaign Goal</h6>
          <h5>{{ campaign.goal }}</h5>
        </div>
      </div>

      <div class="section">
        <div class="p-grid">
          <div class="p-col-12">
            <h6>Creator Requirements</h6>
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-3">
            <h6>Gender</h6>
            <h5>{{ campaign.creatorRequirements.gender }}</h5>
          </div>
          <div class="p-col-3">
            <h6>Target Audience Age</h6>
            <h5>{{ campaign.creatorRequirements.audience.minAge }} - {{ campaign.creatorRequirements.audience.maxAge }}</h5>
          </div>
          <div class="p-col-6">
            <h6>Target Audience Country</h6>
            <h5>{{ campaign.creatorRequirements.country.map(parseCountry).join(', ') }}</h5>
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-3">
            <h6>Language</h6>
            <h5>{{ parseLanguage(campaign.creatorRequirements.language) }}</h5>
          </div>
          <div class="p-col-3">
            <h6>Platforms</h6>
            <h5>Youtube</h5>
          </div>
          <div class="p-col-6">
            <h6>Followers</h6>
            <h5>100k - 300k</h5>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="p-grid">
          <div class="p-col-12">
            <h6>Campaign Brief</h6>
          </div>
        </div>
        <p class="text-pre-line">{{ campaign.brief }}</p>
      </div>

      <div class="section">
        <div class="p-grid">
          <div class="p-col-12">
            <h6>Content Requirements</h6>
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-3">
            <h6>Content Type</h6>
            <h5>{{ campaign.contentType }}</h5>
          </div>
          <div class="p-col-3">
            <h6>Reworks Count (if necessary)</h6>
            <h5>{{ campaign.reworksCount }}</h5>
          </div>
        </div>
      </div>

      <hr>

      <div class="p-grid section">
        <div class="p-col-6">
          <h6>Dos</h6>
          <div v-for="item in campaign.dos" :key="item" class="do-list-item do">
            <span class="material-icons">done</span>
            <span class="text">{{ item }}</span>
          </div>
        </div>

        <div class="p-col-6">
          <h6>Don'ts</h6>
          <div v-for="item in campaign.donts" :key="item" class="do-list-item dont">
            <span class="material-icons">close</span>
            <span class="text">{{ item }}</span>
          </div>
        </div>
      </div>

      <hr>
    </div>
  </app-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import { useToast } from 'primevue/usetoast';
import { useAPI, useAuth, useCampaigns } from '@/modules';
import { APIError } from '@/utils/globals/error-utils';
import { formatDate } from '@/utils/globals/date-utils';
import {
  AppPageConfig,
  AppPageProps,
  BrandData,
  CampaignApplicationData,
  CampaignInvitationData,
  CampaignStatus,
} from '@/data/types';
import AppPage from '@/pages/commons/AppPage.vue';
import { parseCountry } from '@/data/static/countries';
import { parseLanguage } from '@/data/static/languages';
import CampaignPhoto from './CampaignPhoto.vue';
import CampaignStatusChip from './CampaignStatusChip.vue';

export default defineComponent({
  name: 'CreateCampaign',

  components: {
    AppPage,
    CampaignPhoto,
    CampaignStatusChip,
    Button,
    Dialog,
    InputNumber,
    Checkbox,
  },

  data: () => ({
    CampaignStatus,
  }),

  props: {
    campaignID: {
      type: String,
    },
    ...AppPageProps,
  },

  setup(props) {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const { user } = useAuth();

    if (!route.params.campaignID) {
      console.warn('campaign id is missing', route.params.campaignID);
      router.push({ name: 'campaigns' });
      return null;
    }

    const { campaignID } = route.params;

    const config = ref<AppPageConfig>({
      title: 'Loading campaign',
      ...props,
    });

    const controller = useCampaigns(props.viewPerspective);

    const campaignInvitation = ref<CampaignInvitationData | undefined>(undefined);
    const campaignApplication = ref<CampaignApplicationData | undefined>(undefined);
    const campaignIsFixedPrice = ref(true);

    const refreshData = () => controller.manager.loadSingle(route.params.campaignID as string);

    watch(controller.manager.loadingSingleError, () => {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: controller.manager.loadingSingleError?.value?.message,
        life: 3000,
      });
      router.push({ name: 'campaigns' });
    });

    // TODO: make backend send the entire campaign once any update is made? we won't need reloading this way...
    watch(controller.manager.singleCampaign, () => {
      config.value.title = controller.manager.singleCampaign.value?.name || '';
      const invitations: Array<CampaignInvitationData> = controller.manager.singleCampaign.value?.invitations || [];
      campaignInvitation.value = invitations.find((inv) => inv.creator.id === user?.value?.id);

      const applications: Array<CampaignApplicationData> = controller.manager.singleCampaign.value?.applications || [];
      campaignApplication.value = applications.find((app) => app.creator.id === user?.value?.id);

      campaignIsFixedPrice.value = !!controller.manager.singleCampaign.value?.budget;
    });

    const campaignBrandID = computed(() => (controller.manager.singleCampaign.value?.brand as BrandData).id);
    const isMyCampaign = computed(() => user?.value?.isBrandOwner() && (user?.value?.brand?.id === campaignBrandID.value));

    // applying
    const {
      loading: applyingInProgress,
      data: applicationResponse,
      error: applicationError,
      execute: sendApplyRequest,
    } = useAPI(`/campaigns/${route.params.campaignID}`, false);
    const canApplyForCampaign = computed(() => user?.value?.isCreator() && !campaignApplication.value);
    const showCampaignApplyModal = ref(false);
    const applyButtonIcon = computed(() => (applyingInProgress.value ? 'pi pi-spin pi-spinner' : ''));
    const applicationOfferPrice = ref();
    const applicationFinal = ref(false);
    const applyButtonDisabled = computed(() => {
      if (applyingInProgress.value) {
        return true;
      }
      if (!campaignIsFixedPrice.value) {
        return !applicationOfferPrice.value;
      }
      return false;
    });

    const sendApply = () => {
      sendApplyRequest({
        url: `/campaigns/applications/${campaignID}/${user?.value?.id}`,
        method: 'POST',
        data: {
          final: campaignIsFixedPrice.value ? true : applicationFinal.value,
          price: campaignIsFixedPrice.value ? controller.manager.singleCampaign.value?.budget : (applicationOfferPrice.value || 0),
        },
      })
        .then(() => {
          refreshData();
        });
    };

    watch(applicationResponse, () => {
      if (applicationResponse.value) {
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: `Your application for ${controller.manager.singleCampaign.value?.name} was successful!`,
          life: 3000,
        });
        showCampaignApplyModal.value = false;
      }
    });

    watch(applicationError, (err?: APIError) => {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    });

    // withdrawing
    const {
      loading: withdrawingInProgress,
      data: withdrawingResponse,
      error: withdrawingError,
      execute: sendWithdrawRequest,
    } = useAPI('', false);
    const withdrawButtonIcon = computed(() => (withdrawingInProgress.value ? 'pi pi-spin pi-spinner' : ''));
    const showCampaignWithdrawModal = ref(false);

    const sendWithdraw = () => {
      sendWithdrawRequest({
        url: `/campaigns/applications/${route.params.campaignID}/${campaignApplication.value?.id}`,
        method: 'DELETE',
      })
        .then(() => {
          refreshData();
        });
    };

    watch(withdrawingResponse, () => {
      if (withdrawingResponse.value) {
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: `Your withdrawal for ${controller.manager.singleCampaign.value?.name} was successful!`,
          life: 3000,
        });
        showCampaignWithdrawModal.value = false;
      }
    });

    watch(withdrawingError, (err?: APIError) => { // FIXME: find a way to combine both
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    });

    // reject invitation
    const {
      loading: rejectingInvitationInProgress,
      data: rejectingInvitationResponse,
      error: rejectingInvitationError,
      execute: sendRejectInvitationRequest,
    } = useAPI('', false);
    const rejectInvitationButtonIcon = computed(() => (rejectingInvitationInProgress.value ? 'pi pi-spin pi-spinner' : ''));
    const showCampaignRejectInviteModal = ref(false);

    const sendRejectInvitation = () => {
      sendRejectInvitationRequest({
        url: `/campaigns/invitations/${route.params.campaignID}/${campaignInvitation.value?.id}`,
        method: 'PUT',
        data: {
          status: 'rejected', // TODO: enum
        },
      })
        .then(() => {
          refreshData();
        });
    };

    watch(rejectingInvitationResponse, () => {
      if (rejectingInvitationResponse.value) {
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: `You rejected the invitation for ${controller.manager.singleCampaign.value?.name}!`,
          life: 3000,
        });
        showCampaignRejectInviteModal.value = false;
      }
    });

    watch(rejectingInvitationError, (err?: APIError) => { // FIXME: find a way to combine both
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    });

    // publish campaign
    const publishCampaign = () => {
      controller.manager
        .publish(campaignID as string)
        .then((success: any) => {
          if (success) {
            refreshData();
          }
        });
    };
    // < publish campaign

    // discard campaign
    const discardCampaign = () => {
      controller.manager
        .discard(campaignID as string)
        .then((success: boolean | undefined) => {
          if (success) {
            router.push({ name: 'campaigns' });
          }
        });
    };
    // < discard campaign

    // complete campaign
    // const showingFinishCampaignModal = ref(false);
    // const handleFinishCampaignModalAccept = () => {
    //   page.manager
    //     .complete(props.campaignID)
    //     .then((success: any) => {
    //       if (success) {
    //         loadPageData();
    //         showingFinishCampaignModal.value = false;
    //       }
    //     });
    // };
    // < complete campaign

    refreshData();

    return {
      config,
      user,
      campaign: controller.manager.singleCampaign,
      loading: controller.manager.loadingSingle,
      isMyCampaign,
      campaignInvitation,
      campaignIsFixedPrice,
      formatDate,
      parseCountry,
      parseLanguage,

      // apply
      canApplyForCampaign,
      showCampaignApplyModal,
      applyButtonIcon,
      sendApply,
      applyingInProgress,
      applicationOfferPrice,
      applicationFinal,
      applyButtonDisabled,
      campaignApplication,

      // withdraw
      showCampaignWithdrawModal,
      withdrawingInProgress,
      sendWithdraw,
      withdrawButtonIcon,

      // reject invitation
      rejectingInvitationInProgress,
      rejectInvitationButtonIcon,
      sendRejectInvitation,
      showCampaignRejectInviteModal,

      // publish campaign
      publishing: controller.manager.publishing,
      publishCampaign,

      // discard campaign
      discarding: controller.manager.discarding,
      discardCampaign,
    };
  },
});
</script>

<style scoped lang="scss">
.section {
  margin-top: 60rem;
}

.do-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 12rem;

  .text {
    font-size: 14rem;
    font-weight: bold;
    color: $giDarkGrey;
  }

  .material-icons {
    font-size: 18rem;
    margin-right: 6rem
  }

  &.do {
    .material-icons {
      color: #4EC361;
    }
  }

  &.dont {
    .material-icons {
      color: #F54E5D;
    }
  }
}
</style>
