<template>
  <app-page :config="config" class="verify-page">
    <div class="verify">
      <ModalPage ref="modalPageRef" @accept="agreed = true" @reject="agreed = false"/>
      <ModalWorkingOverlay :showSpinner="false" v-if="loading"/>

      <div v-if="viewPerspective === 'brand'">
        <div class="p-text-center p-mb-6">
          <p class="w-75 p-d-inline-block">
            Before we verify your account, you need to create a brand that you'll operate.
            Please use the form below to do so.
          </p>
        </div>
        <div class="p-grid p-d-flex p-flex-column p-p-4">
          <PhotoGallery class="gallery" type="campaign" v-model:photo="photo" />
        </div>

        <div class="p-grid p-d-flex p-flex-column p-p-4">
          <h6>Brand Name</h6>
          <InputText type="text" v-model="name" placeholder="Enter brand name" :class="validation.invalidClass('name')" />
        </div>

        <div class="p-grid p-d-flex p-flex-column p-p-4">
          <h6>Brand Slogan</h6>
          <InputText type="text" v-model="slogan" placeholder="Enter brand slogan" :class="validation.invalidClass('slogan')" />
        </div>
      </div>
      <!-- // brand -->

      <div v-else class="p-d-flex p-flex-column p-align-center p-mt-4 p-mb-6">
        <div class="w-50">
          <h6>Name</h6>
          <InputText type="text" v-model="name" placeholder="Enter creator name" :class="validation.invalidClass('name')" class="w-100"/>
        </div>

        <div class="w-50 p-mt-4">
          <h6>Social Platforms</h6>
          <UserShortPreview :user="user" :link="false" />
        </div>
      </div>
      <!-- // creator -->

      <div class="agreement p-d-flex p-justify-center">
        <div class="w-50">
          <Checkbox v-model="agreed" :binary="true" :class="validation.invalidClass('agreed')" />
          I agree with the
          <a href="#" @click.stop="openModal('terms')">Terms and Conditions</a>
          and the
          <a href="#" @click.stop="openModal('privacy')">Privacy Policy</a>
          of Game Influencer.
        </div>
      </div>
      <!-- // agreement -->

      <div class="p-grid p-d-flex p-nogutter p-justify-center p-mt-3">
        <Button
          label="Register"
          class="p-button-text p-button-danger gi-button p-text-uppercase"
          :loading="loading"
          :disabled="loading || !agreed || !name"
          @click="register"
        />
      </div>
    </div>
  </app-page>
</template>

<script lang="ts">
import { defineComponent, markRaw, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Terms from '@/pages/static/Terms.vue';
import Privacy from '@/pages/static/Privacy.vue';
import AppPage from '@/pages/commons/AppPage.vue';
import UserShortPreview from '@/pages/commons/user/UserShortPreview.vue';
import ModalWorkingOverlay from '@/pages/commons/modals/ModalWorkingOverlay.vue';
import ModalPage from '@/pages/utility/ModalPage.vue';
import { useStepValidation } from '@/pages/campaigns/create/step-validation-module';
import { useAuth, useToastModule, useUsers } from '@/modules';
import { AppPageConfig, AppPageProps, Perspective, User } from '@/data/types';
import PhotoGallery from '../../commons/media/PhotoGallery.vue';

export default defineComponent({
  components: {
    UserShortPreview,
    AppPage,
    Button,
    Checkbox,
    InputText,
    PhotoGallery,
    ModalPage,
    ModalWorkingOverlay,
  },

  props: AppPageProps,

  setup(props) {
    const router = useRouter();
    const toasts = useToastModule();
    const { user, login } = useAuth();
    const perspective = props.viewPerspective || 'creator';

    const config = ref<AppPageConfig>({
      title: `${perspective} Info`,
      ...props,
    });

    // terms & conditions
    const agreed = ref(false);
    const modalPageRef = ref();
    const openModal = (page: string): void => {
      if (modalPageRef.value) {
        if (page === 'terms') {
          modalPageRef.value.open({
            title: 'Terms & Conditions',
            component: markRaw(Terms),
            payload: page,
          });
        } else if (page === 'privacy') {
          modalPageRef.value.open({
            title: 'Privacy Policy',
            component: markRaw(Privacy),
            payload: page,
          });
        }
      }
    };

    // common
    const name = ref('');
    const photo = ref('');

    // brand
    const slogan = ref('');

    // creator

    // validation
    const fields: Record<string, Ref> = { name, agreed };
    if (perspective === 'brand') {
      // eslint-disable-next-line vue/no-ref-as-operand
      fields.slogan = slogan;
    } else {
      name.value = user?.value?.name || '';
    }
    const validation = useStepValidation(fields);
    const controller = useUsers();

    const register = () => {
      if (validation.validate()) {
        const payload: any = {
          name: name.value,
        };
        if (perspective === 'brand') {
          payload.photo = photo.value;
          payload.slogan = slogan.value;
        }
        controller.manager.verify(perspective as Perspective, { [perspective]: payload })
          .then((success?: User | undefined) => {
            if (success) {
              localStorage.setItem('show-welcome', 'true');
              login(success);
              router.push({ name: 'dashboard' });
            }
          });
      }
    };

    return {
      config,
      user,
      loading: controller.manager.verifying,
      register,
      // common
      name,
      photo,
      // brand
      slogan,
      // creator
      // validation
      validation,
      // terms & conditions
      agreed,
      modalPageRef,
      openModal,
    };
  },
});
</script>

<style scoped lang="scss">
.verify-page {
  height: 100vh;
  border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  position: relative;

  ::v-deep(.header) {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }
  ::v-deep(.content) {
    width: 35%;
    flex-grow: 0;
  }

  h1 {
    display: inline-block;
  }

  .agreement {
    font-weight: 600;
    font-size: 12rem;

    a {
      color: $giRed;
      text-decoration: underline;
      font-weight: 700;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
