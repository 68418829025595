
import {
  defineComponent,
  PropType,
  ref,
  watch,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import { AppCampaignSubpageConfig, CampaignData } from '../../data/types';
import AppPage from './AppPage.vue';

export default defineComponent({
  name: 'AppCampaignSubpage',

  // eslint-disable-next-line vue/no-unused-components
  components: { AppPage, Dropdown, MultiSelect },

  props: {
    config: {
      type: Object as PropType<AppCampaignSubpageConfig>,
      required: true,
    },
    campaignFilterOptions: Array as PropType<Array<CampaignData>>,
    loading: Boolean,
  },

  emits: ['filterByCampaignID', 'filterByStatus'],

  setup(props, { emit }) {
    const selectedCampaignID = ref(null);

    watch(selectedCampaignID, () => {
      emit('filterByCampaignID', selectedCampaignID.value);
    });

    return {
      selectedCampaignID,
    };
  },
});
