
import { defineComponent, PropType } from 'vue';
import { ContentItem, Perspective } from '@/data/types';
import { formatDate } from '@/utils/globals/date-utils';
import StatusChip from '@/pages/commons/status/StatusChip.vue';
import ContentItemsListItem from '@/pages/content/ContentItemsListItem.vue';
import BrandShortPreview from '@/pages/commons/brand/BrandShortPreview.vue';
import LicenseRequestActions from '@/pages/content/license-requests/LicenseRequestActions.vue';

export default defineComponent({
  components: {
    LicenseRequestActions,
    BrandShortPreview,
    StatusChip,
    ContentItemsListItem,
  },

  props: {
    item: {
      type: Object as PropType<ContentItem>,
      required: true,
    },
    viewPerspective: String as PropType<Perspective>,
  },

  setup() {
    const getRequestLastOffer = (request: any) => request.offers[request.offers.length - 1];

    return {
      getRequestLastOffer,
      formatDate,
    };
  },
});
