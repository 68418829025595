<template>
  <div class="invitations-list-item">
<!--    [Campaign]:-->
    <h5 class="campaign-name">{{ campaign.name }}</h5>
    <div class="box">
      <div class="headers p-grid p-nogutter">
        <div class="p-col-5">
          <h6 v-if="viewPerspective === 'creator'">Campaign</h6>
          <h6 v-if="viewPerspective === 'brand'">Creator</h6>
          <h6 v-if="viewPerspective === 'admin'">Campaign / Creator / Brand</h6>
        </div>
        <div class="p-col-3">
          <h6>Status</h6>
        </div>
      </div>

      <div class="invitations">
        <div v-for="invitation in campaign.invitations" :key="invitation.id" class="invitation-item p-grid p-nogutter">
          <div class="p-col-5">
            <CampaignShortPreview :campaign="campaign" v-if="viewPerspective === 'creator' || viewPerspective === 'admin'" />
            <UserShortPreview :user="invitation.creator" v-if="viewPerspective === 'brand' || viewPerspective === 'admin'" />
            <BrandShortPreview :brand="campaign.brand" v-if="viewPerspective === 'admin'" />
          </div>
          <div class="p-col-3 p-d-flex p-align-center">
            <StatusChip :status="invitation.status" />
          </div>
          <div class="p-col-4 p-d-flex p-justify-end">
            <Button
              v-if="viewPerspective === 'brand' && invitation.status === 'pending'"
              label="Drop Invitation"
              class="p-button-link"
              icon="pi pi-times"
              @click="confirmDeleteInvitation(campaign, invitation)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Button from 'primevue/button';
import { useConfirm } from 'primevue/useconfirm';
import { CampaignData, CampaignInvitationData, Perspective } from '@/data/types';
import UserShortPreview from '@/pages/commons/user/UserShortPreview.vue';
import StatusChip from '@/pages/commons/status/StatusChip.vue';
import CampaignShortPreview from '@/pages/campaigns/parts/CampaignShortPreview.vue';
import BrandShortPreview from '@/pages/commons/brand/BrandShortPreview.vue';

export default defineComponent({
  // eslint-disable-next-line vue/no-unused-components
  components: {
    BrandShortPreview,
    Button,
    StatusChip,
    UserShortPreview,
    CampaignShortPreview,
  },

  props: {
    campaign: {
      type: Object as PropType<CampaignData>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
  },

  emits: ['delete'],

  setup(props, { emit }) {
    const confirm = useConfirm();

    const confirmDeleteInvitation = (campaign: CampaignData, invitation: CampaignInvitationData) => {
      confirm.require({
        message: 'Are you sure you want to drop this invitation?',
        header: 'Drop?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          emit('delete', { campaign, invitation });
        },
      });
    };

    return {
      confirmDeleteInvitation,
    };
  },
});
</script>

<style lang="scss" scoped>
.invitations-list-item {
  .campaign-name {
    margin-top: 40rem;
    margin-bottom: 8rem;
  }
  .box {
    border: 1px solid #C8CCCC;
    border-radius: 10px;

    .headers {
      padding: 30rem 40rem 0 40rem;
    }

    .invitations {
      .invitation-item {
        padding: 20rem 40rem;

        border-top: 1px solid rgba(0, 0, 0, 0.05);
        //&:not(:first-child) {
        //  border-top: 1px solid rgba(0, 0, 0, 0.05);
        //}
      }
    }
  }
}
</style>
