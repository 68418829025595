
import { defineComponent, ref } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import ModalWorkingOverlay from './ModalWorkingOverlay.vue';

export default defineComponent({
  components: {
    ModalWorkingOverlay,
    Button,
    Dialog,
  },

  props: {
    show: Boolean,
    working: Boolean,
    title: {
      type: String,
      default: 'Confirm?',
    },
    description: String,
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    confirmButtonText: {
      type: String,
      default: 'Accept',
    },
  },

  emits: ['update:show', 'confirm'],
  setup(props, { emit }) {
    const payload = ref<any>(null);

    const confirm = () => {
      emit('confirm', payload.value);
    };

    const open = (data: any) => {
      payload.value = data;
      emit('update:show', true);
    };

    return {
      confirm,
      open,
    };
  },
});
