import { watch } from 'vue';
import { useAPI, useToastModule } from '@/modules';
import { CampaignData } from '@/data/types';

export const useCampaignManager = () => {
  const toast = useToastModule();

  // create campaign
  const {
    loading: creationInProgress,
    error: creationError,
    data: creationResult,
    execute: sendCreateRequest,
  } = useAPI<CampaignData>('/campaigns/drafts', false);

  const create = (details: {
    name: string;
    goal: string;
    startDate: string;
    endDate: string;
  }) => sendCreateRequest({
    method: 'POST',
    data: details,
  });

  watch(creationError, toast.handleRequestError);
  // < create campaign

  // load single campaign
  const {
    loading: loadingSingle,
    error: loadingSingleError,
    data: singleCampaign,
    execute: loadSingleCampaign,
  } = useAPI<CampaignData>('', false);
  const loadSingle = (campaignID: string) => loadSingleCampaign({ url: `/campaigns/${campaignID}` });
  // < load single

  // update
  const {
    loading: updatingInProgress,
    error: updatingError,
    data: updatingResult,
    execute: sendUpdateRequest,
  } = useAPI<CampaignData>('', false);

  const update = (campaignID: string, campaignBody: Record<string, any>) => sendUpdateRequest({
    url: `/campaigns/${campaignID}`,
    method: 'PUT',
    data: { action: 'update', ...campaignBody },
  });
  watch(updatingResult, () => {
    if (updatingResult.value) {
      console.log('-- updated single campaign: ', singleCampaign.value);
      singleCampaign.value = updatingResult.value;
    }
  });
  // < update

  // publish campaign
  const {
    loading: publishing,
    error: publishingError,
    data: publishingResult,
    execute: sendPublishRequest,
  } = useAPI<CampaignData>('', false);

  const publish = (campaignID: string) => sendPublishRequest({
    url: `/campaigns/${campaignID}`,
    method: 'PUT',
    data: { action: 'publish' },
  });
  watch(publishingResult, toast.handleRequestSuccess('Campaign published successfully!'));
  watch(publishingError, toast.handleRequestError);
  // < publish campaign

  // discard campaign
  const {
    loading: discarding,
    error: discardingError,
    data: discardingResult,
    execute: sendDiscardRequest,
  } = useAPI<boolean>('', false);

  const discard = (campaignID: string) => sendDiscardRequest({
    url: `/campaigns/${campaignID}`,
    method: 'PUT',
    data: { action: 'discard' },
  });
  watch(discardingResult, toast.handleRequestSuccess('Campaign discarded successfully!'));
  watch(discardingError, toast.handleRequestError);
  // < discard campaign

  // complete campaign
  const {
    loading: completing,
    error: completingError,
    data: completingResult,
    execute: sendCompleteRequest,
  } = useAPI<CampaignData>('', false);

  const complete = (campaignID: string) => sendCompleteRequest({
    url: `/campaigns/${campaignID}`,
    method: 'PUT',
    data: { action: 'complete' },
  });
  watch(completingResult, toast.handleRequestSuccess('Campaign completed successfully!'));
  watch(completingError, toast.handleRequestError);
  // < complete campaign

  return {
    // creating
    create,
    creationInProgress,
    creationError,
    creationResult,

    // loading single
    loadSingle,
    loadingSingle,
    loadingSingleError,
    singleCampaign,

    // update campaign
    update,
    updatingInProgress,
    updatingError,
    updatingResult,

    // publish
    publish,
    publishing,
    publishingError,
    publishingResult,

    // discard
    discard,
    discarding,
    discardingError,
    discardingResult,

    // complete
    complete,
    completing,
    completingError,
    completingResult,
  };
};
