
import { defineComponent } from 'vue';
import { AppPageConfig, AppPageProps } from '@/data/types';
import { usePage, useCreators } from '@/modules';
import AppPage from '../commons/AppPage.vue';
import CreatorsList from './list/CreatorsList.vue';

export default defineComponent({
  name: 'Creators',

  components: { AppPage, CreatorsList },

  props: {
    limit: {
      type: Number,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    // new
    ...AppPageProps,
  },

  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Creators',
      viewPerspective: props.viewPerspective,
      search: {
        enabled: props.showSearch,
        defaultType: 'creators',
      },
      ...props,
    });

    const controller = useCreators(config.value.viewPerspective);
    const loadPageData = () => controller.list.load({ limit: props.limit });

    loadPageData();

    return {
      config,
      loading: controller.list.loading,
      creators: controller.list.creators,
      performSearch: controller.list.search('name', props.limit || 0),
    };
  },
});
