<template>
  <div class="create-brand app-page">
    <div class="header">
      <h1>Create Brand</h1>
    </div>

    <Card>
      <template #content>
        <h5>Enter brand details</h5>

        <h6>Name:</h6>
        <InputText type="text" v-model="name" />

        <h6>Description</h6>
        <Textarea v-model="description" rows="5" cols="50" />
      </template>

      <template #footer>
        <Button :icon="createButtonIcons" label="Create" @click="create" :disabled="loading" />
        <Button icon="pi pi-times" label="Cancel" class="p-button-secondary"
                style="margin-left: .5em"
                @click="$router.push({ name: 'brands' })"
        />
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, nextTick } from 'vue';
import Button from 'primevue/button';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import { useAPI } from '@/modules/api-module';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import { BrandData } from '@/data/types';

export default defineComponent({
  name: 'CreateBrand',

  components: { Card, InputText, Button, Textarea },

  setup() {
    const router = useRouter();
    const toast = useToast();

    const { loading, execute } = useAPI<BrandData>('/brands', false);
    const name = ref('');
    const description = ref('');

    const createButtonIcons = computed(() => {
      let result = 'pi ';
      if (loading.value) {
        result += 'pi-spin pi-spinner';
      } else {
        result += 'pi-check';
      }

      return result;
    });

    const create = () => {
      execute({
        params: {
          name: name.value,
          description: description.value,
        },
      })
        .then((brand) => {
          toast.add({
            severity: 'success',
            summary: 'Success',
            detail: `Brand "${brand!.name}" successfully created`,
            life: 3000,
          });
          nextTick(() => {
            router.push({ name: 'brand', params: { brandID: brand!.id } });
          });
        });
    };

    return {
      create,
      loading,
      name,
      description,
      createButtonIcons,
    };
  },
});
</script>

<style scoped lang="scss">

</style>
