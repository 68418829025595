
import { defineComponent, PropType } from 'vue';
import ProgressSpinner from 'primevue/progressspinner';
import PageSearchBar from '@/pages/commons/search/PageSearchBar.vue';
import { AppPageConfig } from '../../data/types';

export default defineComponent({
  name: 'AppPage',

  components: { PageSearchBar, ProgressSpinner },

  props: {
    config: Object as PropType<AppPageConfig>,
    title: String,
    loading: Boolean,
    padded: Boolean,
  },

  emits: ['search'],
});
