import { computed, ref } from 'vue';
import { UserType } from '@/data/types';
import { SocialConnectItem } from '../social-def';

export const useAuthForm = () => {
  const connecting = ref('');
  const userIsCreator = ref(false);
  const userType = computed(() => (userIsCreator.value ? UserType.Creator : UserType.BrandOwner));
  const allSocialConnections: Array<SocialConnectItem> = [
    {
      type: 'google',
      forUser: UserType.BrandOwner,
    },
    {
      type: 'twitter',
      forUser: UserType.BrandOwner,
      disabled: true,
    },
    {
      type: 'youtube',
      forUser: UserType.Creator,
    },
    {
      type: 'facebook',
      forUser: UserType.BrandOwner,
      disabled: true,
    },
    {
      type: 'instagram',
      forUser: UserType.Creator,
      disabled: true,
    },
    {
      type: 'twitch',
      forUser: UserType.Creator,
      disabled: true,
    },
  ];
  // const availableSocialConnections = computed(
  //   () => allSocialConnections.filter((i) => i.forUser === (userIsCreator.value ? UserType.Creator : UserType.BrandOwner)),
  // );
  const availableSocialConnections = computed(
    () => allSocialConnections,
  );

  return {
    connecting,
    userIsCreator,
    userType,
    availableSocialConnections,
    allSocialConnections,
  };
};
