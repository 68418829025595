
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import { useToast } from 'primevue/usetoast';
import { useAPI, useAuth, useCampaigns } from '@/modules';
import { APIError } from '@/utils/globals/error-utils';
import { formatDate } from '@/utils/globals/date-utils';
import {
  AppPageConfig,
  AppPageProps,
  BrandData,
  CampaignApplicationData,
  CampaignInvitationData,
  CampaignStatus,
} from '@/data/types';
import AppPage from '@/pages/commons/AppPage.vue';
import { parseCountry } from '@/data/static/countries';
import { parseLanguage } from '@/data/static/languages';
import CampaignPhoto from './CampaignPhoto.vue';
import CampaignStatusChip from './CampaignStatusChip.vue';

export default defineComponent({
  name: 'CreateCampaign',

  components: {
    AppPage,
    CampaignPhoto,
    CampaignStatusChip,
    Button,
    Dialog,
    InputNumber,
    Checkbox,
  },

  data: () => ({
    CampaignStatus,
  }),

  props: {
    campaignID: {
      type: String,
    },
    ...AppPageProps,
  },

  setup(props) {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const { user } = useAuth();

    if (!route.params.campaignID) {
      console.warn('campaign id is missing', route.params.campaignID);
      router.push({ name: 'campaigns' });
      return null;
    }

    const { campaignID } = route.params;

    const config = ref<AppPageConfig>({
      title: 'Loading campaign',
      ...props,
    });

    const controller = useCampaigns(props.viewPerspective);

    const campaignInvitation = ref<CampaignInvitationData | undefined>(undefined);
    const campaignApplication = ref<CampaignApplicationData | undefined>(undefined);
    const campaignIsFixedPrice = ref(true);

    const refreshData = () => controller.manager.loadSingle(route.params.campaignID as string);

    watch(controller.manager.loadingSingleError, () => {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: controller.manager.loadingSingleError?.value?.message,
        life: 3000,
      });
      router.push({ name: 'campaigns' });
    });

    // TODO: make backend send the entire campaign once any update is made? we won't need reloading this way...
    watch(controller.manager.singleCampaign, () => {
      config.value.title = controller.manager.singleCampaign.value?.name || '';
      const invitations: Array<CampaignInvitationData> = controller.manager.singleCampaign.value?.invitations || [];
      campaignInvitation.value = invitations.find((inv) => inv.creator.id === user?.value?.id);

      const applications: Array<CampaignApplicationData> = controller.manager.singleCampaign.value?.applications || [];
      campaignApplication.value = applications.find((app) => app.creator.id === user?.value?.id);

      campaignIsFixedPrice.value = !!controller.manager.singleCampaign.value?.budget;
    });

    const campaignBrandID = computed(() => (controller.manager.singleCampaign.value?.brand as BrandData).id);
    const isMyCampaign = computed(() => user?.value?.isBrandOwner() && (user?.value?.brand?.id === campaignBrandID.value));

    // applying
    const {
      loading: applyingInProgress,
      data: applicationResponse,
      error: applicationError,
      execute: sendApplyRequest,
    } = useAPI(`/campaigns/${route.params.campaignID}`, false);
    const canApplyForCampaign = computed(() => user?.value?.isCreator() && !campaignApplication.value);
    const showCampaignApplyModal = ref(false);
    const applyButtonIcon = computed(() => (applyingInProgress.value ? 'pi pi-spin pi-spinner' : ''));
    const applicationOfferPrice = ref();
    const applicationFinal = ref(false);
    const applyButtonDisabled = computed(() => {
      if (applyingInProgress.value) {
        return true;
      }
      if (!campaignIsFixedPrice.value) {
        return !applicationOfferPrice.value;
      }
      return false;
    });

    const sendApply = () => {
      sendApplyRequest({
        url: `/campaigns/applications/${campaignID}/${user?.value?.id}`,
        method: 'POST',
        data: {
          final: campaignIsFixedPrice.value ? true : applicationFinal.value,
          price: campaignIsFixedPrice.value ? controller.manager.singleCampaign.value?.budget : (applicationOfferPrice.value || 0),
        },
      })
        .then(() => {
          refreshData();
        });
    };

    watch(applicationResponse, () => {
      if (applicationResponse.value) {
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: `Your application for ${controller.manager.singleCampaign.value?.name} was successful!`,
          life: 3000,
        });
        showCampaignApplyModal.value = false;
      }
    });

    watch(applicationError, (err?: APIError) => {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    });

    // withdrawing
    const {
      loading: withdrawingInProgress,
      data: withdrawingResponse,
      error: withdrawingError,
      execute: sendWithdrawRequest,
    } = useAPI('', false);
    const withdrawButtonIcon = computed(() => (withdrawingInProgress.value ? 'pi pi-spin pi-spinner' : ''));
    const showCampaignWithdrawModal = ref(false);

    const sendWithdraw = () => {
      sendWithdrawRequest({
        url: `/campaigns/applications/${route.params.campaignID}/${campaignApplication.value?.id}`,
        method: 'DELETE',
      })
        .then(() => {
          refreshData();
        });
    };

    watch(withdrawingResponse, () => {
      if (withdrawingResponse.value) {
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: `Your withdrawal for ${controller.manager.singleCampaign.value?.name} was successful!`,
          life: 3000,
        });
        showCampaignWithdrawModal.value = false;
      }
    });

    watch(withdrawingError, (err?: APIError) => { // FIXME: find a way to combine both
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    });

    // reject invitation
    const {
      loading: rejectingInvitationInProgress,
      data: rejectingInvitationResponse,
      error: rejectingInvitationError,
      execute: sendRejectInvitationRequest,
    } = useAPI('', false);
    const rejectInvitationButtonIcon = computed(() => (rejectingInvitationInProgress.value ? 'pi pi-spin pi-spinner' : ''));
    const showCampaignRejectInviteModal = ref(false);

    const sendRejectInvitation = () => {
      sendRejectInvitationRequest({
        url: `/campaigns/invitations/${route.params.campaignID}/${campaignInvitation.value?.id}`,
        method: 'PUT',
        data: {
          status: 'rejected', // TODO: enum
        },
      })
        .then(() => {
          refreshData();
        });
    };

    watch(rejectingInvitationResponse, () => {
      if (rejectingInvitationResponse.value) {
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: `You rejected the invitation for ${controller.manager.singleCampaign.value?.name}!`,
          life: 3000,
        });
        showCampaignRejectInviteModal.value = false;
      }
    });

    watch(rejectingInvitationError, (err?: APIError) => { // FIXME: find a way to combine both
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    });

    // publish campaign
    const publishCampaign = () => {
      controller.manager
        .publish(campaignID as string)
        .then((success: any) => {
          if (success) {
            refreshData();
          }
        });
    };
    // < publish campaign

    // discard campaign
    const discardCampaign = () => {
      controller.manager
        .discard(campaignID as string)
        .then((success: boolean | undefined) => {
          if (success) {
            router.push({ name: 'campaigns' });
          }
        });
    };
    // < discard campaign

    // complete campaign
    // const showingFinishCampaignModal = ref(false);
    // const handleFinishCampaignModalAccept = () => {
    //   page.manager
    //     .complete(props.campaignID)
    //     .then((success: any) => {
    //       if (success) {
    //         loadPageData();
    //         showingFinishCampaignModal.value = false;
    //       }
    //     });
    // };
    // < complete campaign

    refreshData();

    return {
      config,
      user,
      campaign: controller.manager.singleCampaign,
      loading: controller.manager.loadingSingle,
      isMyCampaign,
      campaignInvitation,
      campaignIsFixedPrice,
      formatDate,
      parseCountry,
      parseLanguage,

      // apply
      canApplyForCampaign,
      showCampaignApplyModal,
      applyButtonIcon,
      sendApply,
      applyingInProgress,
      applicationOfferPrice,
      applicationFinal,
      applyButtonDisabled,
      campaignApplication,

      // withdraw
      showCampaignWithdrawModal,
      withdrawingInProgress,
      sendWithdraw,
      withdrawButtonIcon,

      // reject invitation
      rejectingInvitationInProgress,
      rejectInvitationButtonIcon,
      sendRejectInvitation,
      showCampaignRejectInviteModal,

      // publish campaign
      publishing: controller.manager.publishing,
      publishCampaign,

      // discard campaign
      discarding: controller.manager.discarding,
      discardCampaign,
    };
  },
});
