<template>
  <div class="invitations-list">
    <InvitationsListItem
      v-for="campaign in campaigns"
      :key="campaign.id"
      :viewPerspective="viewPerspective"
      :campaign="campaign"
      @delete="deleteInvitation"
    />

    <EmptyList
      v-if="!campaigns.length"
      class="p-mt-6"
      title="No Invitations"
      text="Have you applied a campaign filter?"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { CampaignData, CampaignInvitationData, Perspective } from '@/data/types';
import EmptyList from '@/pages/commons/list/EmptyList.vue';
import InvitationsListItem from './InvitationsListItem.vue';

export default defineComponent({
  components: { EmptyList, InvitationsListItem },

  props: {
    campaigns: {
      type: Array as PropType<Array<CampaignData>>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
  },

  emits: ['delete'],

  setup(props, { emit }) {
    const deleteInvitation = ({ campaign, invitation }: { campaign: CampaignData; invitation: CampaignInvitationData }) => {
      emit('delete', { campaign, invitation });
    };

    return {
      deleteInvitation,
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
