
import { computed, defineComponent, ref, watch } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import AppPage from '@/pages/commons/AppPage.vue';
import { useAuth, useCreators, useCampaigns, useToastModule } from '@/modules';
import UserPhoto from '@/pages/commons/user/UserPhoto.vue';
import ContentItemsList from '@/pages/content/ContentItemsList.vue';
import {
  AppPageConfig,
  AppPageProps,
  CampaignData,
  CampaignInvitationData, CampaignStatus,
  EditUserPayload, User,
} from '@/data/types';
import EditCreatorModal from '@/pages/creators/profile/EditCreatorModal.vue';

export default defineComponent({
  components: {
    EditCreatorModal,
    Button,
    Dialog,
    Dropdown,
    ContentItemsList,
    UserPhoto,
    AppPage,
  },

  props: {
    creatorID: {
      type: String,
    },
    ...AppPageProps,
  },

  setup(props) {
    const { user } = useAuth();
    const toast = useToastModule();

    const creatorID = computed(() => props.creatorID || '');

    const config = ref<AppPageConfig>({
      title: 'Loading creator',
      ...props,
    });

    const controller = useCreators(config.value.viewPerspective);
    const loadCreator = () => controller.manager.load(creatorID.value);

    loadCreator();
    watch(controller.manager.creator, () => {
      if (controller.manager.creator.value?.name) {
        config.value.title = controller.manager.creator.value?.name;
      }
    });

    // edit
    // const isMyBrand = computed(() => user?.value?.isBrandOwner() && (user?.value?.brand?.id === brandID.value));
    const showEditModal = ref(false);
    const updateCreator = (payload: EditUserPayload) => {
      controller.manager
        .update(creatorID.value, payload)
        .then((success?: User) => {
          if (success) {
            showEditModal.value = false;
          }
        });
    };
    // < edit

    // invite to a campaign
    interface CampaignDropdownOption {
      id: string;
      name: string;
      optionDisabled: boolean;
    }
    const campaigns = useCampaigns(config.value.viewPerspective);
    const showCampaignInvitationModal = ref(false);
    const selectedCampaignID = ref<string>('');
    const campaignInviteDropdownOptions = ref<Array<CampaignDropdownOption>>();
    const updateCampaignsList = () => {
      campaigns.list
        .loadCampaigns(true)
        .then((data?: Array<CampaignData>) => {
          if (!data) {
            return;
          }
          campaignInviteDropdownOptions.value = data
            .filter((campaign) => campaign.status !== CampaignStatus.Draft)
            .map((campaign) => { // TODO: watcher?
              const result: CampaignDropdownOption = {
                id: campaign.id,
                name: campaign.name,
                optionDisabled: false,
              };
              const invitation: CampaignInvitationData | undefined = (campaign.invitations || [])
                .find((i: CampaignInvitationData) => i.creator.id === creatorID.value);

              if (invitation) {
                result.name = `${result.name} (already invited)`;
                result.optionDisabled = true;
              }
              return result;
            });
        });
    };
    const sendInvite = () => {
      if (selectedCampaignID.value && creatorID.value) {
        campaigns.invitations.invite(selectedCampaignID.value, creatorID.value as string);
      }
    };
    watch(campaigns.invitations.inviteSuccess, () => {
      if (campaigns.invitations.inviteSuccess.value) {
        toast.showSuccess(`User ${controller.manager.creator.value?.name} invited successfully!`);
        showCampaignInvitationModal.value = false;
        updateCampaignsList();
      }
    });
    watch(campaigns.invitations.inviteError, toast.handleRequestError);

    if (user?.value?.isBrandOwner()) {
      updateCampaignsList();
    }
    // < invite to a campaign

    return {
      config,
      controller,
      user,
      loading: controller.manager.loading,
      creator: controller.manager.creator,
      // campaign invitation
      showCampaignInvitationModal,
      selectedCampaignID,
      inviting: campaigns.invitations.inviting,
      campaignInviteDropdownOptions,
      sendInvite,
      // isMyBrand: controller.manager.brand.value?.isOwnedByMe(),
      // edit
      showEditModal,
      updateCreator,
    };
  },
});
