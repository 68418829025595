
import Chip from 'primevue/chip';

import { defineComponent, PropType } from 'vue';
import { Campaign } from '../../../data/types';

// FIXME: use StatusChip instead of this one!

export default defineComponent({
  components: { Chip },
  props: {
    campaign: Object as PropType<Campaign>,
  },
});
