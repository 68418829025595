import { watch } from 'vue';
import { useAPI, useAuth } from '@/modules';
import { AppPagePerspective, Brand, BrandData, User } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useBrandsManager = (perspective: AppPagePerspective) => {
  const toast = useToastModule();
  const { user } = useAuth();

  // load single item
  const {
    loading: loadingBrand,
    error: loadingSingleError,
    execute: loadSingleBrand,
    data: brand,
  } = useAPI<Brand>('', false);

  const loadBrand = (brandID: string) => loadSingleBrand({
    url: `/brands/${brandID}`,
    params: {
      perspective,
    },
    parseResponse: (raw: BrandData) => new Brand(raw, user?.value as User),
  });
  watch(loadingSingleError, toast.handleRequestError);
  // < load single

  // update
  const {
    loading: updatingInProgress,
    error: updatingError,
    data: updatingResult,
    execute: sendUpdateRequest,
  } = useAPI<Brand>('', false);

  const update = (brandID: string, brandPayload: Record<string, unknown>) => sendUpdateRequest({
    url: `/brands/${brandID}`,
    parseResponse: (raw: BrandData) => new Brand(raw, user?.value as User),
    method: 'PUT',
    data: brandPayload,
  });
  watch(updatingResult, () => {
    if (updatingResult.value) {
      brand.value = updatingResult.value;
      toast.showSuccess('Brand updated successfully!');
    }
  });
  watch(updatingError, toast.handleRequestError);
  // < update

  return {
    // // creating
    // create,
    // creationInProgress,
    // creationError,
    // creationResult,
    // loading single brand
    brand,
    loadBrand,
    loadingBrand,
    // update brand
    update,
    updatingInProgress,
    updatingError,
    updatingResult,
  };
};
