
import {
  defineComponent,
  ref,
  watch,
  computed,
} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import RadioButton from 'primevue/radiobutton';
import InputNumber from 'primevue/inputnumber';
import ProgressSpinner from 'primevue/progressspinner';
import { LicenseRequestRequest } from '@/data/types';
import Info from '@/components/Info.vue';

export default defineComponent({
  components: {
    Button,
    Dialog,
    Dropdown,
    Calendar,
    Checkbox,
    InputNumber,
    RadioButton,
    ProgressSpinner,
    Info,
  },

  props: {
    show: Boolean,
    working: Boolean,
  },

  emits: ['request'],

  setup(props, { emit }) {
    watch(() => props.show, () => {
      // selectedOption.value = ''; // FIXME: clear modal after closing
    });

    const acceptedAgreement = ref(false);

    // options

    // 1. date & duration
    const startDate = ref('');
    const durationPeriod = ref<number | undefined>(undefined);
    const durationType = ref('');
    const durationTypes = [
      { name: 'Day(s)', value: 'day' },
      { name: 'Week(s)', value: 'week' },
      { name: 'Month(s)', value: 'month' },
      { name: 'Year(s)', value: 'year' },
    ];
    const perpetualLicense = ref(false);

    // 2. format
    const format = ref('');

    // 3. platforms
    const platforms = ref('');
    const platformsOptions = [
      { name: 'Social Media', value: 'social-media' },
      { name: 'Linear TV', value: 'linear-tv' },
      { name: 'Social Media + Linear TV', value: 'social-media+linear-tv' },
    ];

    // 4. offer
    const finalOffer = ref(false);
    const offerPrice = ref();

    // working
    const sendButtonIcon = computed(() => (props.working ? 'pi pi-spin pi-spinner' : ''));
    const sendRequest = () => {
      emit('request', {
        startDate: startDate.value,
        duration: {
          period: durationPeriod.value,
          type: durationType.value,
          perpetual: perpetualLicense.value,
        },
        format: format.value,
        platforms: platforms.value,
        price: offerPrice.value,
        final: finalOffer.value,
      } as LicenseRequestRequest);
    };
    const sendButtonDisabled = computed(() => {
      if (props.working || !acceptedAgreement.value) {
        return true;
      }

      if (!startDate.value || !format.value || !platforms.value || !offerPrice.value) {
        return true;
      }

      if (perpetualLicense.value) {
        return false;
      }

      if (!durationPeriod.value || !durationType.value) {
        return true;
      }

      return false;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fake = () => {
      startDate.value = new Date().toString();
      durationPeriod.value = 3;
      durationType.value = 'week';
      perpetualLicense.value = false;
      format.value = 'portrait';
      platforms.value = 'social-media+linear-tv';
      offerPrice.value = 124651;
      finalOffer.value = false;
      acceptedAgreement.value = true;
    };
    // fake();

    return {
      sendRequest,
      sendButtonIcon,
      sendButtonDisabled,
      acceptedAgreement,

      // options

      // 1. date & duration
      startDate,
      durationPeriod,
      durationType,
      durationTypes,
      perpetualLicense,

      // 2. format
      format,

      // 3. platforms
      platforms,
      platformsOptions,

      // 4. offer
      finalOffer,
      offerPrice,
    };
  },
});
