<template>
  <div class="deliverables-list">
    <DeliverablesSet
      v-for="(deliverable, index) in deliverables"
      :key="deliverable.id"
      :viewPerspective="viewPerspective"
      :setIndex="index"
      :deliverable="deliverable"
      :campaign="campaign"
      :contract="contract"
      v-bind="$attrs"
      class="deliverable-set"
    />
    <EmptyList
      v-if="!deliverables.length && showEmptyList"
      class="p-mt-6"
      title="No Deliverables"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { CampaignContractData, CampaignData, CampaignDeliverableData, Perspective } from '@/data/types';
import EmptyList from '@/pages/commons/list/EmptyList.vue';
import DeliverablesSet from '@/pages/campaigns/contracts/deliverables/DeliverablesSet.vue';

export default defineComponent({
  components: { DeliverablesSet, EmptyList },

  props: {
    // TODO: reuse
    campaign: Object as PropType<Array<CampaignData>>,
    contract: Object as PropType<Array<CampaignContractData>>,
    deliverables: {
      type: Array as PropType<Array<CampaignDeliverableData>>,
      default: [],
    },
    viewPerspective: String as PropType<Perspective>,
    showEmptyList: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.deliverables-list {
  .deliverable-set {
    margin-bottom: 42rem;
  }
}
</style>
