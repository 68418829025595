import { useAPI, useAuth } from '@/modules';
import { AppPagePerspective, Perspective, ContentItem, ContentItemData } from '@/data/types';

const { user } = useAuth();

export const useContentItemsList = (perspective: AppPagePerspective) => {
  const { loading, error, data: items, execute } = useAPI<Array<ContentItem>>('/content', false);

  // FIXME: fix all the limit things!
  const loadContentItems = (loadOnlyMine = false, limit = 0) => {
    const creatorID = user?.value?.id;
    const brandID = user?.value?.brand?.id;
    const targetID = perspective === Perspective.BRAND ? brandID : creatorID;

    if (perspective === Perspective.BRAND && !brandID) {
      throw new Error('Could not load content items for a brand, because brandID is missing!');
    }

    const params = { perspective, targetID };

    if (!loadOnlyMine) {
      delete params.targetID;
    }

    return execute({
      params,
      parseResponse: (response: Array<ContentItemData>) => {
        const r = limit ? response.slice(0, limit) : response;
        return r.map((item) => new ContentItem(item));
      },
    });
  };

  return {
    loadContentItems,
    loading,
    error,
    items,
  };
};
