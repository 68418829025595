import { AppPagePerspective, Perspective } from '@/data/types/page-types';
import { useCreatorManager } from './creator-manager-module';
import { useCreatorsList } from './creators-list-module';

export const useCreators = (perspective: AppPagePerspective = Perspective.CREATOR) => {
  const manager = useCreatorManager(perspective);
  const list = useCreatorsList();

  return {
    manager,
    list,
  };
};
