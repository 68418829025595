<template>
  <div class="page-search-bar">
    <div class="search-type">
      <h6>Type</h6>

      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-sitemap"></i>
        </span>
        <Dropdown
          v-model="searchType"
          :options="searchTypesList"
          optionLabel="label"
          optionValue="value"
          placeholder="Select search type"
        />
      </div>
    </div>

    <div class="search-input">
      <h6>Search</h6>

      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <InputText type="text" v-model="searchTerm" placeholder="Search term" @keyup.enter="startSearch" />
      </span>
    </div>

    <div class="search-filters">
    </div>

    <div class="search-button">
      <Button label="Search" @click="startSearch" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import { useContext } from '@/modules';
import { Perspective } from '@/data/types';

export default defineComponent({
  name: 'PageSearchBar',

  components: { Button, Dropdown, InputText },

  props: {
    defaultSearchType: {
      type: String,
      default: 'brands',
    },
  },

  emits: ['search'],

  setup(props, { emit }) {
    const router = useRouter();
    const { perspective } = useContext();

    const searchTypesOptions = [];
    if (perspective.value === Perspective.BRAND) {
      searchTypesOptions.push({ label: 'Content Item', value: 'content-items' });
      searchTypesOptions.push({ label: 'Creators', value: 'creators' });
    } else {
      searchTypesOptions.push({ label: 'Brands', value: 'brands' });
      searchTypesOptions.push({ label: 'Campaigns', value: 'campaigns' });
    }
    const searchTypesList = ref(searchTypesOptions);

    const searchTerm = ref('');
    const searchType = ref(props.defaultSearchType);
    const startSearch = () => {
      emit('search', {
        term: searchTerm.value,
        type: searchType.value,
      });
    };

    watch(searchType, () => {
      setTimeout(() => {
        router.push({ name: searchType.value });
      }, 10);
    });

    return {
      searchTypesList,
      searchTerm,
      searchType,
      startSearch,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-search-bar {
  display: flex;
  position: relative;
  margin-bottom: 60rem;

  h6 {
    margin-bottom: 10rem;
  }

  .search {
    &-type {
      margin-right: 14rem;
    }

    &-input {
      width: 50%;

      span, input {
        width: 100%;
      }
    }

    &-button {
      margin-top: 26rem;
      margin-left: auto;
      //box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);

      .p-button {
        // TODO: move globally
        background-color: $giRed;
        border-color: $giRed;
        border-radius: 8rem;
        color: #fff;
        @include font-montserrat();
        text-transform: uppercase;
        font-size: 14rem;
        font-weight: 800;
        line-height: 17rem;

        padding-left: 60rem;
        padding-right: 60rem;

        &:focus {
          box-shadow: 0 0 0 4rem #D06C74;
        }
      }
    }
  }
}
</style>
