<template>
  <div class="campaign-creation-step">
    <h4>Cover Image</h4>
    <p>
      Give your campaign a picture
    </p>

    <div class="p-mt-6">
      <PhotoGallery class="gallery" type="campaign" v-model:photo="photo" />
    </div>

    <div class="next p-d-flex p-justify-between p-mt-6">
      <Button
        label="Previous step"
        class="p-button-text p-button-secondary gi-button p-text-uppercase p-mr-6"
        icon="pi pi-angle-left"
        @click="$router.push({ name: 'edit-campaign--budget-rights', params: { campaignID }})"
      />
      <Button
        label="Save and continue"
        :loading="controller.campaign.manager.updatingInProgress.value"
        class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
        icon="pi pi-angle-double-right"
        iconPos="right"
        @click="controller.next"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import Button from 'primevue/button';
import { CampaignData } from '@/data/types';
import { CampaignStep, getStep, useCampaignStep } from '@/pages/campaigns/create/modules/campaign-step-module';
import PhotoGallery from '@/pages/commons/media/PhotoGallery.vue';

export default defineComponent({
  name: 'CampaignBriefingStep',

  components: {
    PhotoGallery,
    Button,
  },

  props: {
    campaignID: String,
    campaign: Object as PropType<CampaignData>,
    edit: Boolean, // TODO: enum
  },

  setup(props) {
    const campaign = computed(() => props.campaign);

    const photo = ref(campaign?.value?.photo || '');

    const step = getStep('edit-campaign--image', props.campaignID) as CampaignStep;
    const controller = useCampaignStep(
      step,
      {},
      props.campaignID,
    );
    controller.setSerializer(() => ({
      photo: photo.value,
    }));

    return {
      photo,
      // step control
      step,
      controller,
      ...controller.validation,
    };
  },
});
</script>

<style lang="scss" scoped>
.do-list-item {
  padding-left: 10rem;
  margin-bottom: 10rem;
  display: flex;
  align-items: center;
  //background-color: $giDarkGrey;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .text {
    padding: 10rem 0;
  }

  .button {
    //align-self: flex-start;
  }

  &:last-child {
    margin-bottom: 20rem;
  }
}

.gallery {
  ::v-deep(.current-photo .default) {
    font-size: 85rem;
  }
}
</style>
