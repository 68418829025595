
import { computed, defineComponent, PropType, ref } from 'vue';
import { useRouter } from 'vue-router';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import { CampaignData } from '@/data/types';
import { useCampaignStep, getStep, CampaignStep } from './modules/campaign-step-module';

export default defineComponent({
  components: {
    Button,
    InputText,
    Calendar,
  },

  props: {
    campaignID: String,
    campaign: Object as PropType<CampaignData>,
    edit: Boolean, // TODO: enum
  },

  setup(props) {
    const router = useRouter();
    const campaign = computed(() => props.campaign);

    const name = ref(campaign?.value?.name || '');
    const goal = ref(campaign?.value?.goal || '');
    const startDate = ref(campaign?.value?.dates.startDate ? new Date(campaign?.value?.dates.startDate) : '');
    const endDate = ref(campaign?.value?.dates.endDate ? new Date(campaign?.value?.dates.endDate) : '');

    // controller
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const createCampaign = () => controller.campaign.manager.create({
      name: name.value,
      goal: goal.value,
      startDate: startDate.value,
      endDate: endDate.value,
    })
      .then((createdCampaign?: undefined | CampaignData) => {
        if (createdCampaign) {
          router.push({
            name: 'edit-campaign--creator',
            params: { campaignID: createdCampaign.id },
          });
        }
      });

    const step = getStep(props.edit ? 'edit-campaign--details' : 'create-campaign', props.edit ? props.campaignID : '') as CampaignStep;
    const controller = useCampaignStep(
      step,
      {
        name,
        goal,
        startDate,
        endDate,
      },
      props.edit ? props.campaignID : '',
    );
    controller.setSerializer(() => ({
      name: name.value,
      goal: goal.value,
      dates: {
        startDate: startDate.value,
        endDate: endDate.value,
      },
    }));
    if (!props.edit) {
      controller.setNextStepHandler(createCampaign);
    }

    return {
      name,
      goal,
      startDate,
      endDate,
      // step control
      step,
      controller,
      ...controller.validation,
    };
  },
});
