<template>
  <div>
    <Dialog
      :visible="show"
      class="p-col-12 p-md-6 p-xl-4 gi-dialog"
      :modal="true"
      :style="{ width: '40vw' }"
      :dismissableMask="!working"
      :closable="!working"
      @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <div class="w-100 p-text-center">
          <h1>Edit Brand Info</h1>
        </div>
      </template>

      <div class="container">
        <ModalWorkingOverlay v-if="working" />

        <div class="p-grid p-d-flex p-flex-column p-p-4">
          <PhotoGallery class="gallery" type="campaign" v-model:photo="photo" />
        </div>

        <div class="p-grid p-d-flex p-flex-column p-p-4">
          <h6>Brand Slogan</h6>
          <InputText type="text" v-model="slogan" placeholder="Enter brand slogan" />
        </div>

        <div class="p-grid p-d-flex p-flex-column p-p-4">
          <h6>Brand Description</h6>
          <Textarea v-model="description" rows="5" cols="30" placeholder="Enter brand description" />
        </div>
      </div>

      <template #footer>
        <div class="p-d-flex p-justify-between p-mt-4">
          <Button
            label="Cancel"
            class="p-button-link p-ml-6"
            :disabled="working"
            @click="$emit('update:show', false)"
          />

          <Button
            label="Save"
            class="p-button-text p-button-danger gi-button p-text-uppercase p-mr-6"
            :loading="working"
            :disabled="working"
            @click="saveBrand"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import { Brand } from '@/data/types';
import ModalWorkingOverlay from '../../commons/modals/ModalWorkingOverlay.vue';
import PhotoGallery from '../../commons/media/PhotoGallery.vue';

export default defineComponent({
  components: {
    ModalWorkingOverlay,
    Button,
    Dialog,
    PhotoGallery,
    InputText,
    Textarea,
  },

  props: {
    show: Boolean,
    working: Boolean,
    brand: Object as PropType<Brand>,
  },

  emits: ['save', 'update:show'],

  setup(props, { emit }) {
    const photo = ref('');
    const slogan = ref('');
    const description = ref('');

    watch(props, () => {
      if (props.brand) {
        photo.value = props.brand.photo;
        slogan.value = props.brand.slogan;
        description.value = props.brand.description;
      }
    });

    // working
    const saveBrand = () => {
      emit('save', {
        photo: photo.value,
        slogan: slogan.value,
        description: description.value,
      });
    };

    const open = () => emit('update:show', true);

    return {
      saveBrand,
      open,
      photo,
      slogan,
      description,
    };
  },
});
</script>

<style scoped lang="scss">
.container {
  position: relative;

  .working-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;

    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: .5;
    }

    .spinner {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
