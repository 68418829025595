
import { computed, defineComponent, PropType, ref } from 'vue';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import MultiSelect from 'primevue/multiselect';
import { CampaignData } from '@/data/types';
import Info from '@/components/Info.vue';
import { CampaignStep, getStep, useCampaignStep } from '@/pages/campaigns/create/modules/campaign-step-module';
import { countries, languages } from '../../../data/static';

export default defineComponent({
  name: 'CampaignCreatorStep',

  components: {
    Info,
    Button,
    Dropdown,
    Checkbox,
    InputNumber,
    MultiSelect,
  },

  props: {
    campaignID: String,
    campaign: Object as PropType<CampaignData>,
    edit: Boolean, // TODO: enum
  },

  setup(props) {
    const campaign = computed<CampaignData | undefined>(() => props.campaign);

    const gender = ref(campaign?.value?.creatorRequirements.gender || '');
    const minAge = ref(campaign?.value?.creatorRequirements.audience.minAge || undefined);
    const maxAge = ref(campaign?.value?.creatorRequirements.audience.maxAge || undefined);
    const country = ref(campaign?.value?.creatorRequirements.country || undefined);
    const language = ref(campaign?.value?.creatorRequirements.language || undefined);
    const platforms = ref([]);

    const genderOptions = [
      { name: 'Any', value: 'any' },
      { name: 'Male', value: 'male' },
      { name: 'Female', value: 'female' },
    ];
    const countryOptions = ref(countries);
    const languageOptions = ref(languages);

    const step = getStep('edit-campaign--creator', props.campaignID) as CampaignStep;
    const controller = useCampaignStep(
      step,
      {
        gender,
        // minAge,
        // maxAge,
        country,
        language,
        // platforms,
      },
      props.campaignID,
    );
    controller.setSerializer(() => ({
      creatorRequirements: {
        gender: gender.value,
        audience: {
          minAge: minAge.value,
          maxAge: maxAge.value,
        },
        country: country.value,
        language: language.value,
      },
    }));

    return {
      gender,
      genderOptions,
      minAge,
      maxAge,
      country,
      countryOptions,
      language,
      languageOptions,
      platforms,
      // step control
      step,
      controller,
      ...controller.validation,
    };
  },
});
