<template>
  <div class="deliverables-set">
    <div v-for="(linkSet, index) in deliverable.links" :key="linkSet.id" class="deliverable-item">
      <div class="row-header p-grid p-nogutter">
        <div class="p-col-1">
          <h5 v-if="index === 0" class="item-name">ITEM {{ index + 1 }}</h5>
        </div>

        <div class="p-col-3 p-d-flex p-align-center p-pr-2">
          <span class="material-icons p-mr-2">link</span>
          <a :href="`//${linkSet.link}`" target="_blank" class="p-text-truncate">{{ linkSet.link }}</a>
        </div>

        <div class="p-col-2">
          {{ formatDate(linkSet.createdAt) }}
        </div>

        <div class="p-col-2">
          <StatusChip :status="linkSet.status" :text="statusText(linkSet)" />
        </div>

        <div class="p-col-4 p-text-right">
          <Button
            v-if="viewPerspective === 'creator' && linkSet.status === 'pending' && contract.status === 'active'"
            class="p-button-link"
            @click="$emit('resubmit', { contract, deliverable, linkSet })"
          >
            <span class="material-icons p-button-icon p-button-icon-left">update</span>
            <span class="p-button-label">Submit update</span>
          </Button>
          <!-- // creator: submit update -->

          <Button
            v-if="
              viewPerspective === 'creator'
              && linkSet.status === 'rejected'
              && index === deliverable.links.length -1
              && contract.status === 'active'
            "
            class="p-button-link"
            @click="$emit('resubmit', { contract, deliverable, linkSet })"
          >
            <span class="material-icons p-button-icon p-button-icon-left">update</span>
            <span class="p-button-label">Submit change update</span>
          </Button>
          <!-- // creator: submit request change update -->

          <Button
            v-if="viewPerspective === 'brand' && linkSet.status === 'pending' && contract.status === 'active'"
            class="p-button-link p-button-danger"
            @click="$emit('accept', { contract, deliverable, linkSet })"
          >
            <span class="material-icons p-button-icon p-button-icon-left">done</span>
            <span class="p-button-label">Accept</span>
          </Button>
          <!-- // brand: accept deliverable -->

          <Button
            v-if="viewPerspective === 'brand' && linkSet.status === 'pending' && contract.status === 'active'"
            class="p-button-link"
            @click="$emit('reject', { contract, deliverable, linkSet })"
          >
            <span class="material-icons p-button-icon p-button-icon-left">published_with_changes</span>
            <span class="p-button-label">Request Change</span>
          </Button>
          <!-- // brand: request change -->
        </div>
      </div>

      <div class="row-comments" v-if="linkSet.comments.length">
        <div v-for="comment in linkSet.comments" :key="comment.id" class="comment-item">
          <div class="p-d-flex p-justify-between p-align-center">
            <UserShortPreview :user="contract.creator" v-if="comment.from === 'creator'" />
            <BrandShortPreview :brand="campaign.brand" v-if="comment.from === 'brand'" />

            {{ formatDate(comment.createdAt, 'long') }}
          </div>
          <div class="text p-mt-4 text-pre-line">{{ comment.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Button from 'primevue/button';
import { formatDate } from '@/utils/globals/date-utils';
import {
  CampaignContractData,
  CampaignData,
  CampaignDeliverableData,
  CampaignDeliverableLink,
  Perspective,
} from '@/data/types';
import UserShortPreview from '@/pages/commons/user/UserShortPreview.vue';
import StatusChip from '@/pages/commons/status/StatusChip.vue';
import BrandShortPreview from '@/pages/commons/brand/BrandShortPreview.vue';

export default defineComponent({
  components: {
    BrandShortPreview,
    Button,
    StatusChip,
    UserShortPreview,

  },

  props: {
    setIndex: Number,
    campaign: Object as PropType<Array<CampaignData>>,
    contract: Object as PropType<Array<CampaignContractData>>,
    deliverable: Object as PropType<CampaignDeliverableData>,
    viewPerspective: String as PropType<Perspective>,
  },

  emits: ['resubmit', 'accept', 'reject'],

  setup(props, { emit }) {
    const statusText = (linkSet: CampaignDeliverableLink) => {
      if (props.viewPerspective === 'creator' && linkSet.status === 'rejected') {
        return 'change requested';
      }
      return linkSet.status;
    };

    return {
      formatDate,
      statusText,
    };
  },
});
</script>

<style lang="scss" scoped>
.deliverables-set {
  border: 1px solid #C8CCCC;
  border-radius: 10px;
  overflow: hidden;

  $rowPaddingSides: 30rem;

  .deliverable-item {

    .row-header {
      height: 56rem;
      background: #F7F7F7;
      padding: 8rem $rowPaddingSides;

      display: flex;
      align-items: center;

      .item-name {
        margin: 0;
      }
    }

    .row-comments {
      padding: 32rem $rowPaddingSides;

      .comment-item {
        .text {
          color: #8B8F8F;
        }

        &:not(:first-child) {
          margin-top: 30rem;
          padding-top: 20rem;
          border-top: 1px solid #f1f1f1;
        }
      }
    }
  }
}
</style>
