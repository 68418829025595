<template>
  <div class="campaign-photo">
    <img v-if="campaign.photo" :src="campaign.photo" alt="" referrerpolicy="no-referrer" class="real" />
    <img v-else src="/images/campaign-default.svg" alt="" referrerpolicy="no-referrer" class="default" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Campaign, CampaignData } from '../../../data/types';

export default defineComponent({
  name: 'CampaignPhoto',

  props: {
    campaign: {
      type: Object as PropType<Campaign | CampaignData>,
    },
  },
});
</script>

<style lang="scss" scoped>
.campaign-photo {
  width: 236rem;
  height: 186rem;
  background: #EBEBEB;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  .real {
    width: 100%;
    height: 100%;
  }

  .default {
    width: 50%;
    height: 50%;
  }
}
</style>
