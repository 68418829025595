
import { defineComponent, PropType } from 'vue';
import { User } from '@/data/types';
import CreatorListItem from '../CreatorListItem.vue';
import EmptyList from '../../commons/list/EmptyList.vue';

export default defineComponent({
  name: 'CampaignsList',

  // eslint-disable-next-line vue/no-unused-components
  components: { CreatorListItem, EmptyList },

  props: {
    creators: {
      type: Array as PropType<Array<User>>,
      default: [],
    },
    select: Function,
  },
});
