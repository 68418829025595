<template>
  <div class="app" :class="currentPageName">
    <transition name="fade">
      <div v-if="authenticating" class="loading">
        <ProgressSpinner class="spinner" />
      </div>
    </transition>
    <template v-if="!authenticating">
      <router-view />
    </template>

    <ConfirmDialog />
    <Toast position="top-center" />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import ProgressSpinner from 'primevue/progressspinner';
import { EventBus } from '@/modules';

export default defineComponent({
  components: { ProgressSpinner, Toast, ConfirmDialog },

  setup() {
    const router = useRouter();

    onMounted(() => {
      console.log('-- gi version: ', process.env.VUE_APP_VERSION);

      EventBus.on('not-authenticated-error', () => {
        router.push({ name: 'logout' });
      });
    });

    const currentPageName = computed(() => router.currentRoute.value.name);

    return {
      authenticating: false,
      currentPageName,
    };
  },
});
</script>

<style scoped lang="scss">
.app {
  .loading {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .spinner {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
