<template>
  <div class="dashboard">
<!--    <div @click="openModal">open modal</div>-->
    <ModalPage ref="modalPage" />
    <div v-if="viewPerspective === Perspective.BRAND">
      <Creators :limit="8" :showSearch="false" v-bind="$props" />
      <ContentItems :limit="4" :showSearch="false" v-bind="$props" />
    </div>

    <div v-if="viewPerspective === Perspective.CREATOR">
      <Campaigns :limit="4" v-bind="$props" />
      <Brands :limit="4" :showSearch="false" v-bind="$props" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, markRaw } from 'vue';
import { AppPageProps, Perspective } from '@/data/types';
import About from './static/About.vue';
import ModalPage from './utility/ModalPage.vue';
import Creators from './creators/Creators.vue';
import ContentItems from './content/ContentItems.vue';
import Campaigns from './campaigns/Campaigns.vue';
import Brands from './brands/Brands.vue';

export default defineComponent({
  name: 'Home',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    Brands,
    Campaigns,
    ContentItems,
    Creators,
    ModalPage,
  },

  props: AppPageProps,

  setup() {
    const modalPage = ref();
    const openModal = () => {
      if (modalPage.value) {
        modalPage.value.open({
          title: 'About',
          component: markRaw(About),
        });
      }
    };
    return {
      About,
      modalPage,
      openModal,
      Perspective,
    };
  },
});
</script>

<style scoped lang="scss">
.dashboard {
  width: 100%;
}
</style>
