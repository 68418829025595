import { ref, Ref, watch } from 'vue';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import HTTPService from '@/services/http/HTTPService';
import { APIError } from '@/utils/globals/error-utils';
import { EventBus } from '@/modules';

export interface RequestConfig<T = any> extends AxiosRequestConfig {
  parseResponse?: (data: any) => T;
}

export interface UseAPIReturn<T> {
  execute: (localConfig?: RequestConfig) => Promise<T | undefined>;
  response: Ref<AxiosResponse<T> | undefined>;
  data: Ref<T | undefined>;
  loading: Ref<boolean>;
  finished: Ref<boolean>;
  error: Ref<APIError | undefined>;
}

export function useAPI<T = any>(url: string): UseAPIReturn<T>;
export function useAPI<T = any>(url: string, config: RequestConfig<T>): UseAPIReturn<T>;
export function useAPI<T = any>(url: string, start: boolean): UseAPIReturn<T>;
export function useAPI<T = any>(url: string, config: RequestConfig<T>, start: boolean): UseAPIReturn<T>;

export function useAPI<T = any>(url: string, ...args: Array<any>): UseAPIReturn<T> {
  let config: RequestConfig<T> = {};
  let start = true;
  const instance: AxiosInstance = HTTPService.get();

  if (args.length > 0) {
    /**
     * Unable to use `instanceof` here becuase of (https://github.com/axios/axios/issues/737)
     * so instead we are checking if there is a `requset` on the object to see if it is an
     * axios instance
     */
    if (typeof args[0] === 'boolean') {
      start = args[0];
    } else {
      config = args[0];
    }
  }
  if (args.length > 1) {
    start = args[1];
  }

  const response = ref<any>(null) as Ref<AxiosResponse<T> | undefined>;
  const data = ref<any>(undefined) as Ref<T | undefined>;
  const loading = ref(false);
  const finished = ref(false);
  const error = ref<APIError | undefined>();

  const execute = (localConfig: RequestConfig = {}) => {
    config = { ...config, ...localConfig };
    loading.value = true;
    data.value = undefined;

    return instance(config.url || url, config)
      .then((r: AxiosResponse<T>) => {
        response.value = r;
        data.value = config.parseResponse ? config.parseResponse(r.data) : r.data;
        return data.value as T;
      })
      .catch((err) => {
        error.value = new APIError(err);
        return undefined;
      })
      .finally(() => {
        loading.value = false;
        finished.value = true;
      });
  };

  if (start) {
    execute();
  }

  // global auth error handling, pointing the user to login
  watch(error, () => {
    // if 401 Unauthorised, force user to login
    if (error.value && error.value.status === 401) {
      EventBus.emit('not-authenticated-error');
    }
  });

  return {
    execute,
    response,
    loading,
    finished,
    data,
    error,
  };
}
