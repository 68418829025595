import { Ref } from 'vue';
import { useRouter } from 'vue-router';
import { Perspective } from '@/data/types';
import { useCampaigns } from '@/modules';
import { useStepValidation } from '@/pages/campaigns/create/step-validation-module';

export interface CampaignStep {
  campaignID?: string;
  name: string;
  label: string;
  to?: string;
}

export const getSteps = (campaignID = ''): Array<CampaignStep> => {
  // urls required for marking as selected
  const result = [
    {
      name: 'edit-campaign--creator',
      label: 'Creator Details',
      to: `/app/campaigns/${campaignID}/edit/creator`,
      campaignID,
    },
    {
      name: 'edit-campaign--briefing',
      label: 'Briefing',
      to: `/app/campaigns/${campaignID}/edit/briefing`,
      campaignID,
    },
    {
      name: 'edit-campaign--budget-rights',
      label: 'Budget and Usage Rights',
      to: `/app/campaigns/${campaignID}/edit/budget-rights`,
      campaignID,
    },
    {
      name: 'edit-campaign--cover-image',
      label: 'Cover Image',
      to: `/app/campaigns/${campaignID}/edit/cover-image`,
      campaignID,
    },
  ];
  if (campaignID) {
    result.unshift({
      name: 'edit-campaign--details',
      label: 'Campaign Details',
      to: `/app/campaigns/${campaignID}/edit/details`,
      campaignID,
    });
  } else {
    result.unshift({
      name: 'create-campaign',
      label: 'Campaign Details',
      to: '/app/campaigns/create/details',
      campaignID: '', // FIXME: what the hell?
    });
  }
  return result;
};

export const getStep = (name: string, editCampaignID?: string): CampaignStep | undefined => getSteps(editCampaignID)
  .find((s) => s.name === name);

const getNextStep = (current: CampaignStep): CampaignStep | undefined => {
  if (!current) {
    return undefined;
  }
  const steps = getSteps(current.campaignID); // FIXME: put everything in the use module -> this method doesn't know if it's edit or not!
  const currentIndex = steps.findIndex((s) => s.name === current.name);
  if (currentIndex > -1 && currentIndex < steps.length - 1) {
    return steps[currentIndex + 1];
  }
  return undefined;
};

export function useCampaignStep(step: CampaignStep, inputs: Record<string, Ref>): any;
export function useCampaignStep(step: CampaignStep, inputs: Record<string, Ref>, editCampaignID?: string): any;
export function useCampaignStep(step: CampaignStep, inputs: Record<string, Ref>, nextStep?: () => void): any;
export function useCampaignStep(step: CampaignStep, inputs: Record<string, Ref>, editCampaignID?: string, nextStep?: () => void): any;

export function useCampaignStep(step: CampaignStep, inputs: Record<string, Ref>, ...args: Array<any>): any {
  const router = useRouter();

  let editCampaignID = '';

  const campaign = useCampaigns(Perspective.BRAND);
  const validation = useStepValidation(inputs);

  // serialization
  let serializeStep = (): Record<string, any> => Object.entries(inputs)
    .reduce((result: any, [name, input]) => {
      result[name] = input.value; // eslint-disable-line no-param-reassign
      return result;
    }, {});

  const setSerializer = (callback: () => Record<string, any>) => serializeStep = callback; // eslint-disable-line no-return-assign

  // next step
  const updateCampaign = () => {
    campaign.manager
      .update(editCampaignID, serializeStep())
      .then((success) => {
        const nextStep = getNextStep(step);
        if (success) {
          if (nextStep) {
            router.push({
              name: nextStep.name,
              params: { campaignID: editCampaignID },
            });
          } else {
            router.push({
              name: 'campaign',
              params: { campaignID: editCampaignID },
            });
          }
        }
      });
  };
  let nextStepHandler = updateCampaign;
  const setNextStepHandler = (callback: () => Promise<any>) => nextStepHandler = callback; // eslint-disable-line no-return-assign

  if (args.length > 0) {
    if (typeof args[0] === 'string') {
      editCampaignID = args[0];
    } else {
      nextStepHandler = args[0];
    }
  }
  if (args.length > 1) {
    nextStepHandler = args[1];
  }

  const next = () => {
    if (validation.validate()) {
      nextStepHandler && nextStepHandler();
    }
  };

  return {
    step,
    campaign,
    next,
    validation,
    // serialization
    setSerializer,
    setNextStepHandler,
  };
}
