<template>
  <div class="login-form">
    <SocialConnect
      action="login"
      v-model:connecting="connecting"
      :items="allSocialConnections"
      :userType="userType"
      @connect="handleUserConnect"
    />

    <EmailConnect :connecting="connecting" @signin="handleEmailSignIn" />

    <div class="link-register">
      New to GameInfluencer.io?
      <br>
      <router-link :to="{ name: 'register' }">Register</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { User } from '@/data/types';
import { useAuth, useUsers } from '@/modules';
import { useAuthForm } from './modules/auth-form-module';

import { SocialConnectResponse } from './social-def';

import SocialConnect from './components/SocialConnect.vue';
import EmailConnect from './components/EmailConnect.vue';

export default defineComponent({
  name: 'LoginForm',

  components: { SocialConnect, EmailConnect },

  setup() {
    const { loadUser } = useAuth();
    const users = useUsers();
    const router = useRouter();
    const { connecting, userIsCreator, userType, allSocialConnections } = useAuthForm();

    const loadUserData = (token: string) => {
      loadUser(token)
        .then((success?: User | undefined) => {
          console.log('-- login loading my profile response: ', success);
          if (success) {
            router.push({ name: 'dashboard' });
          } else {
            // TODO: handle somehow?
          }
        });
    };

    const handleUserConnect = (response: SocialConnectResponse) => {
      loadUserData(response.tokens.access.token);
    };

    const handleEmailSignIn = ({ email, password }: { email: string; password: string }) => {
      console.log('-- data: ', email, password);
      if (email && password) {
        connecting.value = 'email';
        users.manager
          .signin(email, password)
          .then((response?: { tokens: any }) => {
            console.log('logged in: ', response?.tokens);
            if (response?.tokens) {
              loadUserData(response?.tokens.access.token);
            } else {
              connecting.value = '';
            }
          });
      }
    };

    return {
      connecting,
      userIsCreator,
      userType,
      allSocialConnections,
      handleUserConnect,
      handleEmailSignIn,
    };
  },
});
</script>

<style lang="scss">
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .link-register {
    margin-top: 50rem;
    text-align: center;
  }
}
</style>
