
import { PropType } from 'vue';
import { ContentItem } from '@/data/types';
import { formatDate } from '@/utils/globals/date-utils';

export default {
  name: 'ContentItemsListItem',

  components: {},

  props: {
    item: {
      type: Object as PropType<ContentItem>,
      required: true,
    },
  },
  data: () => ({}),

  methods: {
    getImageUrl(index: number) {
      return `/images/content/${index}.jpg`;
    },
    formatDate,
  },
};
