import axios, { AxiosInstance } from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

export default class HTTPService {
  private static instance: AxiosInstance;

  public static get(): AxiosInstance {
    if (!this.instance) {
      this.instance = axios.create({
        baseURL: API_URL,
      });
    }

    return this.instance;
  }

  public static setAuthorizationHeader(token: string): void {
    const instance = HTTPService.get();
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}
